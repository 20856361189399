import React from 'react';

import { FASectionTitle, FAAnchor } from 'FA_STORYBOOK';

const CLASSES = {
    BASE: 'NotFound'
};

function NotFound({ isRebrand }) {
    return (
        <div className={CLASSES.BASE}>
            <FASectionTitle>404</FASectionTitle>
            This is not the webpage you are looking for.
            <FAAnchor isRebrand={isRebrand} href="/">Go back to Fieldaware</FAAnchor>
        </div>
    );
}

export default NotFound;
