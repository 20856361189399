import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import chunk from 'lodash.chunk';

import { FAModal } from 'FA_STORYBOOK';
import { parseImportDataFromState, wait } from '../../utils';

import 'antd/lib/progress/style/index.css';
import 'antd/lib/statistic/style/index.css';

const CONFIG = {
    // input arrays larger than this amount will be divided into chunks no larger than LARGE_IMPORT_CHUNKING_SIZE
    LARGE_IMPORT_THRESHOLD: 1000,
    // the max size of chunks in large imports
    LARGE_IMPORT_CHUNKING_SIZE: 250,
    // sleep for this long between calls to the parser
    WAIT_INTERVAL: 100
};

function ParsingModal({
    csvColumnHeaders,
    csvLines,
    entity,
    fileName,
    isParsing,
    keyMap,
    schema,
    url,
    token,
    isWebapp,
    timezone,
    // Functions
    onParsingComplete
}) {
    const [chunks, setChunks] = useState([]);
    const [parsedCount, setParsedCount] = useState(0);

    // Imports of 250 lines or smaller will be parsed in one go, and the modal will not display
    const shouldChunk = csvLines.length > CONFIG.LARGE_IMPORT_CHUNKING_SIZE;

    function parseAll() {
        if (!isParsing || shouldChunk) return;

        async function parseImportData() {
            const importData = await parseImportDataFromState(csvLines, 0, {
                keyMap,
                schema,
                entity,
                csvColumnHeaders,
                url,
                token,
                isWebapp,
                timezone
            });
            onParsingComplete(importData);
        }
        parseImportData();
    }

    /**
     * Imports between 250 and 1000 lines will be divided into 4 chunks
     * Imports over 1000 lines will be divided into chunks of no larger than 250 lines
     */
    function beginParsing() {
        const inputSize = csvLines.length;
        const chunkingSize =
            inputSize >= CONFIG.LARGE_IMPORT_THRESHOLD ? CONFIG.LARGE_IMPORT_CHUNKING_SIZE : Math.ceil(inputSize / 4);
        const chunkedCsvLines = chunk(csvLines, chunkingSize);
        setChunks(chunkedCsvLines);
    }

    /**
     * Parse a chunk, add it to processed data array, and process the next chunk
     *
     * TODO: store importData in state and update with useEffect
     */
    function parseChunks() {
        const importData = [];
        async function parseNextChunk(chunkIndex) {
            if (!chunks.length) return;
            if (chunkIndex >= chunks.length) return onParsingComplete(importData);
            // The total set of importData requires a positional index to be passed, so we need to account for the chunking with an offset
            const offsetIndex = chunkIndex * chunks[0].length;
            const importDataForChunk = await parseImportDataFromState(chunks[chunkIndex], offsetIndex, {
                keyMap,
                schema,
                entity,
                csvColumnHeaders,
                url,
                token,
                isWebapp,
                timezone
            });
            importData.push(...importDataForChunk);
            await wait(CONFIG.WAIT_INTERVAL);
            const nextChunkIndex = chunkIndex + 1;
            setParsedCount(nextChunkIndex);
            parseNextChunk(nextChunkIndex);
        }
        parseNextChunk(0);
    }

    // begin processing the chunks when the array is updated from empty
    useEffect(parseChunks, [chunks.length]);

    useEffect(parseAll, [isParsing]);

    return (
        <FAModal
            visible={shouldChunk && isParsing}
            title={
                <span>
                    Parsing & Validating <i>{fileName}</i> [{csvLines.length} records]
                </span>
            }
            centered={false}
            footer={null}
            closable={false}
            onOpen={() => setTimeout(() => beginParsing(), 100)}
            onCancel={() => {}}
        >
            <Progress percent={chunks.length ? Math.round((parsedCount / chunks.length) * 100) : 0} />
        </FAModal>
    );
}

ParsingModal.propTypes = {
    csvColumnHeaders: PropTypes.arrayOf(PropTypes.string),
    csvLines: PropTypes.arrayOf(PropTypes.array),
    entity: PropTypes.string,
    fileName: PropTypes.string,
    isParsing: PropTypes.bool,
    keyMap: PropTypes.object,
    schema: PropTypes.object,
    // Functions
    onParsingComplete: PropTypes.func.isRequired
};

export default ParsingModal;
