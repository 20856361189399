import * as formatters from '.';
import { CONTACT_DATA_KEYS as DATA_KEYS } from '../schemas/dataKeys';

export const FORMATTERS = {
    [DATA_KEYS.UUID]: formatters.trimString,
    [`${DATA_KEYS.CUSTOMER}.${DATA_KEYS.UUID}`]: formatters.trimString,
    [DATA_KEYS.NAME]: formatters.trimString,
    [DATA_KEYS.SURNAME]: formatters.trimString,
    [DATA_KEYS.EMAIL_ADDRESS]: formatters.trimString,
    [DATA_KEYS.PHONE_NUMBER]: formatters.trimString
};

export function getFormatters() {
    return FORMATTERS;
}

export const REQUIRED_FIELDS_FOR_UUID_REQUEST = [`${DATA_KEYS.CUSTOMER}.${DATA_KEYS.UUID}`, DATA_KEYS.NAME];
export const OPTIONAL_FIELDS_FOR_UUID_REQUEST = [DATA_KEYS.SURNAME];
