import { CSV_MODAL_TAB_KEYS } from '../utils/constants';
import { IMPORT_STATUS } from '../pages/ImportRunner/constants';

export const initValidationMetrics = {
    // only show metrics in the UI when we have validated the CSV file after the columns are mapped
    isParsed: false,
    // the number of validation errors in total
    totalErrors: 0,
    // the number of CSV lines with errors e.g. we should be able to tell the user if there are 5 errors all on 1 line, or 1 error on each of 5 lines
    errorLines: 0,
    // the number of CSV lines with no errors
    validLines: 0,
    // the number of expected creates (valid CSV lines without uuid)
    createRequests: 0,
    // the number of expected updates (valid CSV lines with uuid)
    updateRequests: 0
};

export const initImportMetrics = {
    targetRequests: 0,
    totalRequests: 0,
    successRequests: 0,
    errorRequests: 0
};

export default {
    /**
     * Authentication State
     *      @param url and @param token are received in the authentication form input
     *      @param email and @param business are received by the whoami request
     */
    url: '',
    token: '',
    email: '',
    business: '',
    /**
     * Entity State
     *      @param entity is the identifier of the entity being imported
     *      @param entityLabel is the readable name of the entity being imported
     *      @param schema is the schema for the entity. This is created at runtime by merged Custom Field definitions fetched from the API with locally defined schemas per-entity
     */
    entity: '',
    entityLabel: '',
    schema: null,
    /**
     * File State
     *      @param fileName is the name of the uploaded CSV
     *      @param csvColumnHeaders is an array of the string values of the CSV column headers
     *      @param csvLines is an array of each line in the CSV as an array of strings
     *      @param csvFilePreview is an array of each line in the CSV as a key/value object
     */
    fileName: '',
    csvColumnHeaders: [],
    csvLines: [],
    csvFilePreview: [],
    /**
     * Mapping State
     *      @param userDefinedKeyMap contains schema key values mapped to column names by the user. This mapping supercedes any automatic mapping when generating the @param keyMap for the current session
     *      @param keyMap is the master mapping of schema key values to column names for the session. It is generated at the Configuration step by merging the @param userDefinedKeyMap with automatic mapping. This map is updated during runtime as the user remaps or unmaps columns to schema keys
     *      @param mappedKeys is an array of the keys in the @param keyMap
     */
    userDefinedKeyMap: {},
    keyMap: {},
    mappedKeys: [],
    validationMetrics: {
        ...initValidationMetrics
    },
    /**
     * Import Runner State
     *      @param importDone is a flag for when the import runner has finished (TODO: deprecate in favour of DONE in importStatus)
     *      @param importStatus is the running status of the import @see IMPORT_STATUS
     *      @param importData holds the requestPayload, error response, or success response for each line in the CSV. It is parsed from @see csvLines when the parseFile action is taken in the Configuration step
     *      @param iterationCounter stores the current index of the runner when making requests to the API
     */
    // TODO: determine value of importDone from importStatus, and remove from App state
    importDone: false,
    showImportResult: false,
    importStatus: IMPORT_STATUS.PREPARING,
    importData: [],
    iterationCounter: 0,
    hasNetworkErrors: false,
    importMetrics: {
        ...initImportMetrics
    },
    importTimer: {
        start: 0,
        end: 0
    },
    /**
     * When the import status is RETRYING, this keeps a count of the amount of requests to make vs how many have resolved
     */
    retryMetrics: {
        errorsToRetry: 0,
        resolvedErrors: 0
    },
    // Modal UI State
    schemaModalIsVisible: false,
    csvModalIsVisible: false,
    csvModalActiveTabKey: CSV_MODAL_TAB_KEYS.RAW_CSV,
    // Previous Imports
    importHistory: [],
    // Number of records to import in new session after interrupted run
    interruptCount: 0,

    // isWebapp indicates if the import tool is served by a webapp instance
    // we will assume this is the case to avoid showing a 404 error while the API url is loaded
    isWebapp: true,
    isWebappLoading: true,

    // isEnterprise takes the value of the corresponding toggle for the organisation of the user
    isEnterprise: false,

    // isRebrand takes the value of the corresponding toggle for the organisation of the user.
    // Controls the colours shown on the import tool.
    isRebrand: false,

    // isUuidLookupEnabled takes the value of the corresponding toggle for the organisation of the user
    isUuidLookupEnabled: false
};
