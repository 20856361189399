import * as check from 'check-types';
import * as validator from 'validator';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import {
    COMMON_DATA_KEYS,
    LOCATION_DATA_KEYS,
    CUSTOMER_DATA_KEYS,
    ACTIVITY_DATA_KEYS,
    HISTORICAL_JOB_DATA_KEYS
} from '../schemas/dataKeys';
import { VALIDATOR_KEYS, getLabelAndDescription as _getLabelAndDescription } from './labels';
import { VALID_LOCALES, TIME_WINDOW_MINIMUM_DURATION_IN_MILLISECONDS } from '../constants';

export const getLabelAndDescription = _getLabelAndDescription;

export function getExactEnumCondition(options) {
    // adding the empty string to allow for not set
    options.push('');
    return {
        condition: value => check.in(value, options),
        errorMessage: `is not one of [${options.filter(value => value).join(', ')}]`
    };
}

function getCaseInsensitiveEnumCondition(options) {
    // adding the empty string to allow for not set
    options.push('');
    return {
        condition: value =>
            check.string(value) &&
            check.in(
                value.toLowerCase(),
                options.map(option => option.toLowerCase())
            ),
        errorMessage: `is not one of [${options.filter(value => value).join(', ')}]`
    };
}

const MARKUP_VALUES = {
    fixed: { label: 'Fixed' },
    percentage: { label: 'Percentage' }
};

// Unfortunately, the UUID validator in the lib is too strict, and requires dashes in the value
function validateUuid(value) {
    return check.match(value, /^[a-fA-F0-9]{32}$/g);
}

const emailAddressCondition = {
    condition: value => !value || validator.isEmail(value),
    errorMessage: 'is not a valid email address'
};

const adaptFloatAndCheckCondition = {
    condition: value => {
        if (!['string', 'number'].includes(typeof value)) return false;
        const valueAsNumber = Number(value);
        return check.number(valueAsNumber);
    },
    errorMessage: 'is not a float number'
};

const isStringCondition = {
    condition: value => check.string(value),
    errorMessage: 'is not a valid string'
};

function getStringOfMaxLengthConditions(maxLength) {
    return [
        {
            ...isStringCondition,
            stopChecking: true
        },
        {
            condition: value => !check.string(value) || validator.isLength(value, { max: maxLength }),
            errorMessage: `is too long (max length is ${maxLength} chars)`
        }
    ];
}

const mandatoryStringOnCreationCondition = {
    condition: (value, object) =>
        COMMON_DATA_KEYS.UUID in object && object[COMMON_DATA_KEYS.UUID]
            ? check.string(value)
            : check.nonEmptyString(value),
    errorMessage: 'is not set when it is required in a create operation'
};

const optionalUuidCondition = {
    condition: value => value === null || value === '' || validateUuid(value),
    errorMessage: 'is not a valid UUID'
};

// maxValue should be positive, we will use the negative as well
function getCoordinateCondition(maxValue, coordinateType) {
    return {
        condition: value => {
            if (!['string', 'number'].includes(typeof value)) return false;
            const valueAsNumber = Number(value);
            return check.number(valueAsNumber) && -maxValue <= valueAsNumber && valueAsNumber <= maxValue;
        },
        errorMessage: `is not a valid ${coordinateType}`
    };
}

function getMandatoryStringOfMaxLengthConditions(maxLength) {
    return [...getStringOfMaxLengthConditions(maxLength), mandatoryStringOnCreationCondition];
}

export const strongPasswordConditions = [
    {
        condition: value => check.nonEmptyString(value) && value.length >= 8,
        errorMessage: 'password has to be 8 chars long',
        displayValue: false
    },
    {
        condition: value => check.nonEmptyString(value) && check.not.equal(value, value.toUpperCase()),
        errorMessage: 'password has to contain a lowercase letter',
        displayValue: false
    },
    {
        condition: value => check.nonEmptyString(value) && check.not.equal(value, value.toLowerCase()),
        errorMessage: 'password has to contain an uppercase letter',
        displayValue: false
    },
    {
        condition: value => check.nonEmptyString(value) && check.match(value, /\d/),
        errorMessage: 'password has to contain a number',
        displayValue: false
    },
    {
        condition: value =>
            // eslint-disable-next-line
            check.nonEmptyString(value) && check.match(value, /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/),
        errorMessage: 'password has to contain a special character',
        displayValue: false
    }
];

const dateCondition = {
    condition: value => check.date(new Date(value)),
    errorMessage: 'is not a valid date'
};

const timeCondition = {
    condition: value => check.match(value, /^([01]\d|2[0-3]):([0-5]\d)(:[0-5]\d)?$/),
    errorMessage: `is not a valid time hh:mm`
};

function isTimeWindowDurationOver15Minutes(startAfterDate, deadlineDate) {
    return (
        startAfterDate < deadlineDate && deadlineDate - startAfterDate >= TIME_WINDOW_MINIMUM_DURATION_IN_MILLISECONDS
    );
}

function getOptionalDatetimeCondition(
    errorMessage = 'is not a valid date in ISO format (YYYY-MM-DDTHH:mm:ss)',
    stopChecking = true
) {
    return {
        condition: value => (check.string(value) && !value) || (check.nonEmptyString(value) && isValidISODate(value)),
        errorMessage,
        stopChecking
    };
}

function getOptionalCondition(errorMessage) {
    return {
        // this passes with an empty string, which we will receive in optional values
        condition: value => check.string(value),
        errorMessage,
        optional: true
    };
}

function getUseLatLngCondition([locationNameKey, locationLatitudeKey, locationLongitudeKey]) {
    return {
        condition: (value, object) => {
            if (['no', ''].includes(value)) return true;

            // now apply the rule for mandatory values on creation on latitude and longitude
            const requiredAttributes = [locationNameKey, locationLatitudeKey, locationLongitudeKey];
            for (let i = 0; i < requiredAttributes.length; i++) {
                const requiredAttribute = requiredAttributes[i];
                const isAttributeInObject = requiredAttribute in object;
                // the checks on the type and validity of the values is done on the other attributes
                if (
                    !isAttributeInObject ||
                    object[requiredAttribute] === null ||
                    object[requiredAttribute] === undefined ||
                    (check.string(object[requiredAttribute]) && object[requiredAttribute].trim() === '')
                )
                    return false;
            }
            return true;
        },
        errorMessage: `When ${LOCATION_DATA_KEYS.USE_LAT_LNG} is enabled on creation, "${locationNameKey}", "${locationLatitudeKey}" and "${locationLongitudeKey}" are required`,
        displayValue: false
    };
}

let isValidISODate = () => {};

export function setupDatetimeValidators(timezone) {
    isValidISODate = value => {
        const parsedValue = moment.tz(value, timezone);
        return parsedValue.isValid();
    };
}

const validatorDefinitions = {
    [VALIDATOR_KEYS.STRING]: isStringCondition,
    [VALIDATOR_KEYS.OPTIONAL_UUID]: optionalUuidCondition,
    [VALIDATOR_KEYS.MANDATORY_UUID_ON_CREATION]: [
        {
            ...mandatoryStringOnCreationCondition,
            stopChecking: true
        },
        optionalUuidCondition
    ],
    [VALIDATOR_KEYS.ADAPT_FLOAT_AND_CHECK]: adaptFloatAndCheckCondition,
    [VALIDATOR_KEYS.POSITIVE_NUMBER]: [
        {
            ...adaptFloatAndCheckCondition,
            stopChecking: true
        },
        {
            condition: value => value >= 0,
            errorMessage: 'is not a positive number'
        }
    ],
    [VALIDATOR_KEYS.VALID_DATE]: dateCondition,
    [VALIDATOR_KEYS.OPTIONAL_DATE]: [getOptionalCondition('is not a valid date'), dateCondition],
    [VALIDATOR_KEYS.VALID_TIME]: timeCondition,
    [VALIDATOR_KEYS.OPTIONAL_TIME]: [getOptionalCondition('is not a valid time'), timeCondition],
    [VALIDATOR_KEYS.YES_NO]: getCaseInsensitiveEnumCondition(['yes', 'no', 'true', 'false']),
    [VALIDATOR_KEYS.MARKUP_ENUM]: getExactEnumCondition(Object.keys(MARKUP_VALUES)),
    [VALIDATOR_KEYS.EMAIL_ADDRESS]: emailAddressCondition,
    [VALIDATOR_KEYS.MANDATORY_EMAIL_ADDRESS_ON_CREATION]: [mandatoryStringOnCreationCondition, emailAddressCondition],

    // string10 and mandatoryStringOnCreation10 are only used for the purpose of testing (for now)
    [VALIDATOR_KEYS.MANDATORY_STRING_ON_CREATION_10]: getMandatoryStringOfMaxLengthConditions(10),
    [VALIDATOR_KEYS.MANDATORY_STRING_ON_CREATION_100]: getStringOfMaxLengthConditions(100),
    [VALIDATOR_KEYS.MANDATORY_STRING_ON_CREATION_128]: getMandatoryStringOfMaxLengthConditions(128),
    [VALIDATOR_KEYS.MANDATORY_STRING_ON_CREATION_256]: getMandatoryStringOfMaxLengthConditions(256),

    [VALIDATOR_KEYS.STRING_10]: getStringOfMaxLengthConditions(10),
    [VALIDATOR_KEYS.STRING_64]: getStringOfMaxLengthConditions(64),
    [VALIDATOR_KEYS.STRING_100]: getStringOfMaxLengthConditions(100),
    [VALIDATOR_KEYS.STRING_128]: getStringOfMaxLengthConditions(128),
    [VALIDATOR_KEYS.STRING_256]: getStringOfMaxLengthConditions(256),
    [VALIDATOR_KEYS.STRING_1024]: getStringOfMaxLengthConditions(1024),
    [VALIDATOR_KEYS.STRING_4096]: getStringOfMaxLengthConditions(4096),

    // location specific validators
    [VALIDATOR_KEYS.LOCATION_TYPE]: getCaseInsensitiveEnumCondition([
        'headquarters',
        'branch',
        'residential',
        'billing'
    ]),
    [VALIDATOR_KEYS.LATITUDE]: [getCoordinateCondition(90, 'latitude')],
    [VALIDATOR_KEYS.LONGITUDE]: [getCoordinateCondition(180, 'longitude')],
    [VALIDATOR_KEYS.USE_LAT_LNG]: [
        {
            ...getCaseInsensitiveEnumCondition(['yes', 'no']),
            stopChecking: true
        },
        getUseLatLngCondition([LOCATION_DATA_KEYS.NAME, LOCATION_DATA_KEYS.LATITUDE, LOCATION_DATA_KEYS.LONGITUDE])
    ],
    [VALIDATOR_KEYS.USE_LAT_LNG_CUSTOMER]: [
        {
            ...getCaseInsensitiveEnumCondition(['yes', 'no']),
            stopChecking: true
        },
        getUseLatLngCondition([
            CUSTOMER_DATA_KEYS.LOCATION_NAME,
            CUSTOMER_DATA_KEYS.LOCATION_LATITUDE,
            CUSTOMER_DATA_KEYS.LOCATION_LONGITUDE
        ])
    ],
    [VALIDATOR_KEYS.MANDATORY_STRING_ON_CREATION_IF_NOT_USE_LAT_LNG_128]: [
        ...getStringOfMaxLengthConditions(128),
        {
            condition: (value, object) => {
                const useLatLngValue = check.string(object[LOCATION_DATA_KEYS.USE_LAT_LNG])
                    ? object[LOCATION_DATA_KEYS.USE_LAT_LNG].trim()
                    : Boolean(object[LOCATION_DATA_KEYS.USE_LAT_LNG]);

                if (useLatLngValue === 'yes' || useLatLngValue === true) return true;

                // now apply the rule for mandatory values on creation
                const isCreateOperation = !(COMMON_DATA_KEYS.UUID in object);
                // adding Boolean(value.trim()) to catch string with only spaces
                return !isCreateOperation || (check.nonEmptyString(value) && Boolean(value.trim()));
            },
            errorMessage: `is required on creation (${LOCATION_DATA_KEYS.STREET_NAME} and ${LOCATION_DATA_KEYS.CITY} are required if ${LOCATION_DATA_KEYS.USE_LAT_LNG} is not used)`
        }
    ],

    // user specific validators: password, timezone, platform, locale, role
    [VALIDATOR_KEYS.MANDATORY_PASSWORD_ON_CREATION]: [mandatoryStringOnCreationCondition, ...strongPasswordConditions],
    [VALIDATOR_KEYS.TIMEZONE]: {
        condition: value => {
            if (!value || typeof value !== 'string') return false;
            return check.in(value, momentTimezone.tz.names());
        },
        // using a link to the list of valid timezones, as including them in the message would be too long
        errorMessage: `is not a valid timezone. See the list supported timezones at https://api.fieldaware.net/doc/reference/users.html?highlight=timezone#available-timezones`
    },
    [VALIDATOR_KEYS.PLATFORM]: getCaseInsensitiveEnumCondition(['ios', 'android', 'online']),
    [VALIDATOR_KEYS.ROLE]: getCaseInsensitiveEnumCondition([
        'org admin',
        'admin',
        'manager',
        'dispatcher',
        'employee',
        'contractor'
    ]),
    [VALIDATOR_KEYS.LOCALE]: getCaseInsensitiveEnumCondition(VALID_LOCALES),

    // job specific validation: ISO date
    [VALIDATOR_KEYS.ISO_DATE]: [
        {
            ...isStringCondition,
            stopChecking: true
        },
        {
            ...mandatoryStringOnCreationCondition,
            stopChecking: true
        },
        {
            condition: value => isValidISODate(value),
            errorMessage: 'is not a valid date in ISO format (YYYY-MM-DDTHH:mm:ss)'
        }
    ],

    [VALIDATOR_KEYS.OPTIONAL_ISO_DATE]: [getOptionalDatetimeCondition()],

    // quote specific validator
    [VALIDATOR_KEYS.IS_FIELD_QUOTE]: [
        {
            ...getCaseInsensitiveEnumCondition(['yes', 'no']),
            stopChecking: true
        },
        {
            condition: (value, object) => {
                const isCreateOperation = !(COMMON_DATA_KEYS.UUID in object);
                if (!isCreateOperation) return true;

                const requiredAttributes = [`${ACTIVITY_DATA_KEYS.LOCATION}.${COMMON_DATA_KEYS.UUID}`];
                if (value === 'yes') requiredAttributes.push(ACTIVITY_DATA_KEYS.SCHEDULED_ON);

                for (let i = 0; i < requiredAttributes.length; i++) {
                    const requiredAttribute = requiredAttributes[i];
                    const isAttributeInObject = requiredAttribute in object;

                    // the checks on the type and validity of the values is done on the other attributes
                    const attributeValue = object[requiredAttribute];

                    // now apply the rule for mandatory values on creation
                    if (
                        !isAttributeInObject ||
                        attributeValue === null ||
                        attributeValue === undefined ||
                        (check.string(attributeValue) && attributeValue.trim() === '')
                    )
                        return false;
                }
                return true;
            },
            errorMessage: `When ${ACTIVITY_DATA_KEYS.IS_FIELD_QUOTE} is enabled on creation, "${ACTIVITY_DATA_KEYS.LOCATION}.uuid" and "${ACTIVITY_DATA_KEYS.SCHEDULED_ON}" are required. If disabled, only "${ACTIVITY_DATA_KEYS.LOCATION}.uuid" is required, and "${ACTIVITY_DATA_KEYS.SCHEDULED_ON}" will be ignored`,
            displayValue: false
        }
    ],

    // historic job validator: started datetime is a valid Date before completed datetime
    [VALIDATOR_KEYS.ISO_DATE_BEFORE]: [
        {
            ...isStringCondition,
            stopChecking: true
        },
        {
            ...mandatoryStringOnCreationCondition,
            stopChecking: true
        },
        {
            condition: value => isValidISODate(value),
            errorMessage: 'is not a valid date in ISO format (YYYY-MM-DDTHH:mm:ss)',
            stopChecking: true
        },
        {
            condition: (value, object) => {
                const startedParsedDate = new Date(value);
                if (
                    !(HISTORICAL_JOB_DATA_KEYS.COMPLETED_DATETIME in object) ||
                    !isValidISODate(object[HISTORICAL_JOB_DATA_KEYS.COMPLETED_DATETIME])
                ) {
                    return true;
                }

                const completedParsedDate = new Date(object[HISTORICAL_JOB_DATA_KEYS.COMPLETED_DATETIME]);
                return startedParsedDate < completedParsedDate;
            },
            errorMessage: 'started date and time should happen before the completed date and time'
        }
    ],

    // job time window validator: start after datetime is a valid Date before deadline datetime
    // note that the minimum duration of a valid time window is 15 minutes
    [VALIDATOR_KEYS.ISO_DATE_START_AFTER]: [
        getOptionalDatetimeCondition(),
        {
            condition: (value, object) => {
                if (!value) return true;

                const startAfterParsedDate = new Date(value);
                const deadlineKey = `${ACTIVITY_DATA_KEYS.TIME_WINDOW}.${ACTIVITY_DATA_KEYS.DEADLINE}`;
                if (!(deadlineKey in object) || !isValidISODate(object[deadlineKey])) {
                    return true;
                }

                const deadlineParsedDate = new Date(object[deadlineKey]);
                return isTimeWindowDurationOver15Minutes(startAfterParsedDate, deadlineParsedDate);
            },
            errorMessage:
                'start after date and time should happen before the deadline date and time, and the duration of the time window should be at least 15 minutes'
        }
    ],

    [VALIDATOR_KEYS.OPTIONAL_UUID_ON_CREATION]: [
        {
            ...optionalUuidCondition,
            stopChecking: true
        },
        {
            condition: (value, object) => {
                if (!value) return true;

                const isCreateOperation = !(COMMON_DATA_KEYS.UUID in object) || !object[COMMON_DATA_KEYS.UUID];
                return isCreateOperation;
            },
            errorMessage: 'UUID value can only be applied during creation operation'
        }
    ]
};

/**
 * Defines the basic validator mechanism. It takes a list (or a single) objects defining a
 * function that will validate a input value (or an input value and the full object from
 * where it comes from), an error message. Each of these functions should return a boolean value.
 *
 * Conditions can have an extra attribute `stopChecking` if after a particular validation
 * condition we should stop evaluating the rest of the defined rules.
 *
 * @param {Fn[] | Fn} conditions: array of functions to be applied to check for validity
 * @return {String[]} - an array of error messages
 */
export function setupValidator(conditions, name) {
    conditions = Array.isArray(conditions) ? conditions : [conditions];
    const validator = function(attributeValue, object) {
        const errors = [];
        for (let i = 0; i < conditions.length; i++) {
            const { condition, errorMessage, stopChecking = false, displayValue = true, optional = false } = conditions[
                i
            ];

            if (!condition(attributeValue, object)) {
                errors.push(displayValue ? `"${attributeValue}" ${errorMessage}` : errorMessage);
                if (stopChecking) break;
            } else if (optional) {
                break;
            }
        }
        return errors;
    };

    // HACK: set a name on the validator function returned, to show it on the schema
    Object.defineProperty(validator, 'name', { value: name, configurable: true });
    return validator;
}

/**
 * Builds the validators object that will provide the different validator functions
 * for the different types supported.
 *
 * Relies on the checks defined on validatorDefinitions, and applies `setupValidator`
 * on each of these.
 */
const validators = Object.keys(validatorDefinitions).reduce((validatorAcc, validatorKey) => {
    const validatorConditions = validatorDefinitions[validatorKey];
    validatorAcc[validatorKey] = setupValidator(validatorConditions, validatorKey);
    return validatorAcc;
}, {});

const customFieldTypeValidators = {
    CheckBox: () => validators[VALIDATOR_KEYS.YES_NO],
    Text: () => validators[VALIDATOR_KEYS.STRING],
    Number: () => validators[VALIDATOR_KEYS.ADAPT_FLOAT_AND_CHECK],
    Date: () => validators[VALIDATOR_KEYS.OPTIONAL_DATE],
    Time: () => validators[VALIDATOR_KEYS.OPTIONAL_TIME],
    Dropdown: options => setupValidator(getExactEnumCondition(options), 'dropdown')
};

validators.getCustomFieldValidator = function(customFieldType, customFieldOptions) {
    const typeGetter = customFieldTypeValidators[customFieldType];
    return Boolean(typeGetter) && typeGetter(customFieldOptions);
};

validators.getTaxValidator = function(taxAttribute, secondTaxAttribute, validTaxValues) {
    return setupValidator(
        [
            getExactEnumCondition(validTaxValues),
            {
                condition: (value, object) => {
                    if (!value) return true;

                    // the checks on the type and validity of the values is done on the other attributes
                    const isOtherTaxAttributeInObject = secondTaxAttribute in object;
                    return isOtherTaxAttributeInObject && object[secondTaxAttribute];
                },
                errorMessage: `When ${taxAttribute} is set, "${secondTaxAttribute}" is also required`,
                displayValue: false
            }
        ],
        taxAttribute
    );
};

export default validators;
