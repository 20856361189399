import { IMPORT_STATUS } from '../pages/ImportRunner/constants';

/**
 * If the Import is running or paused, show a confirm before navigating away from the page.
 *
 * The API for this only supports a boolean value, the actual confirm dialog is determined by the browser and
 * cannot be customised.
 *
 * Setting the event value to undefined removes the handler so no confirm is shown. In other words, we don't care about
 * closing the app if the import is not running or paused.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
 * @param {String} importStatus of the values of @see IMPORT_STATUS
 */
export function bindUnloadPrompt(importStatus) {
    const safeExitStatus = [IMPORT_STATUS.PREPARING, IMPORT_STATUS.STOPPED, IMPORT_STATUS.COMPLETE];
    if (safeExitStatus.includes(importStatus)) {
        window.onbeforeunload = undefined;
    } else {
        window.onbeforeunload = () => true;
    }
}
