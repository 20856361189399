import validators from '../validators';
import { MANDATORY_STATUS } from '../constants';
import { COMMON_DATA_KEYS, ACTIVITY_DATA_KEYS as DATA_KEYS } from './dataKeys';

/**
 * Defines the schema structure and validator functions for jobs types.
 */
export const SCHEMA = {
    [COMMON_DATA_KEYS.UUID]: validators.optionalUuid,
    [DATA_KEYS.NAME]: validators.string100
};

export const DEFAULT_HEADER_MAPPING = {
    [COMMON_DATA_KEYS.UUID]: 'UUID',
    [COMMON_DATA_KEYS.NAME]: 'Name'
};

export const MINIMUM_ATTRS_TO_MAP = {
    [MANDATORY_STATUS.ON_CREATION]: [DATA_KEYS.NAME],
    [MANDATORY_STATUS.ON_UPDATE]: [DATA_KEYS.UUID]
};
