import React from 'react';

import { FASpinner } from 'FA_STORYBOOK';

const CLASSES = {
    BASE: 'Loading'
};

function Loading() {
    return (
        <div className={CLASSES.BASE}>
            <FASpinner isGrey size="large" />
        </div>
    );
}

export default Loading;
