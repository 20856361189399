import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { FAIcon, FAPageTitle, FAUploadDragger } from 'FA_STORYBOOK';

import './UploadSchema.sass';

function UploadSchema({
    // Data
    entityLabel,
    entity,
    schema,
    isRebrand,
    // Functions
    onFileRead,
    resetFileState
}) {
    const history = useHistory();
    const memoizedOnFileRead = useCallback(
        (fileName, readerResult) => {
            onFileRead(fileName, readerResult);
        },
        [onFileRead]
    );

    // reset the file state when the component mounts, which allows the browser back button to work as expected
    useEffect(resetFileState, []);

    return (
        <FAUploadDragger
            isRebrand={isRebrand}
            disabled={!schema}
            style={{
                padding: '4em'
            }}
            accept=".csv"
            customRequest={({ file, onSuccess, onError }) => {
                const reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onload = () => {
                    onSuccess();
                    memoizedOnFileRead(file.name, reader.result);
                    history.push(`/import/${entity}/configuration`);
                };

                reader.onerror = () => onError();
            }}
        >
            <h1>
                <FAIcon type="upload" />
            </h1>
            <FAPageTitle hasHorizontalRule={false}>{entityLabel}</FAPageTitle>
            <p className="ant-upload-text">
                {schema ? (
                    <span>Click or drag .csv file to this area to upload a {entityLabel}</span>
                ) : (
                    <span>Select an Entity before uploading CSV</span>
                )}
            </p>
        </FAUploadDragger>
    );
}

UploadSchema.propTypes = {
    entity: PropTypes.string,
    entityLabel: PropTypes.string,
    schema: PropTypes.object,
    onFileRead: PropTypes.func.isRequired,
    resetFileState: PropTypes.func.isRequired,
    isRebrand: PropTypes.bool
};

export default UploadSchema;
