import validators from '../validators';
import { MANDATORY_STATUS } from '../constants';
import {
    COMMON_DATA_KEYS,
    ACTIVITY_DATA_KEYS as DATA_KEYS,
    ASSET_DATA_KEYS as ASSET_KEYS,
    LOCATION_DATA_KEYS as LOCATION_KEYS,
    USER_DATA_KEYS as USER_KEYS,
    HISTORICAL_JOB_DATA_KEYS
} from './dataKeys';

/**
 * Defines the schema structure and validator functions for jobs.
 *
 * Nested definitions are flattened for ease of lookup @see schemas/getFlatSchemaFromSchemaDefinition
 */
export const SCHEMA = {
    [DATA_KEYS.DESCRIPTION]: validators.string4096,
    [DATA_KEYS.SCHEDULED_ON]: validators.isoDate,
    [DATA_KEYS.ESTIMATED_DURATION]: validators.positiveNumber,
    [DATA_KEYS.LOCATION]: {
        uuid: validators.mandatoryUuidOnCreation
    },
    [DATA_KEYS.CONTACT]: {
        uuid: validators.optionalUuid
    },
    [DATA_KEYS.ASSET]: {
        uuid: validators.optionalUuid
    },
    [DATA_KEYS.JOB_LEAD]: {
        uuid: validators.mandatoryUuidOnCreation
    },
    [HISTORICAL_JOB_DATA_KEYS.STARTED_DATETIME]: validators.isoDateBefore,
    [HISTORICAL_JOB_DATA_KEYS.COMPLETED_DATETIME]: validators.isoDate,
    [DATA_KEYS.TASK_GROUP]: {
        uuid: validators.optionalUuid
    },
    [DATA_KEYS.JOB_TYPE]: {
        uuid: validators.optionalUuid
    }
};

export const DEFAULT_HEADER_MAPPING = {
    [DATA_KEYS.DESCRIPTION]: 'Description',
    [DATA_KEYS.SCHEDULED_ON]: 'Scheduled On',
    [DATA_KEYS.ESTIMATED_DURATION]: 'Estimated Duration',
    [`${DATA_KEYS.LOCATION}.${COMMON_DATA_KEYS.UUID}`]: 'Location UUID',
    [`${DATA_KEYS.CONTACT}.${COMMON_DATA_KEYS.UUID}`]: 'Contact UUID',
    [`${DATA_KEYS.ASSET}.${COMMON_DATA_KEYS.UUID}`]: 'Asset UUID',
    [`${DATA_KEYS.JOB_LEAD}.${COMMON_DATA_KEYS.UUID}`]: 'Job Lead UUID',
    [`${DATA_KEYS.JOB_TYPE}.${COMMON_DATA_KEYS.UUID}`]: 'Job Type UUID',
    [HISTORICAL_JOB_DATA_KEYS.STARTED_DATETIME]: 'Started Datetime',
    [HISTORICAL_JOB_DATA_KEYS.COMPLETED_DATETIME]: 'Completed Datetime',
    [`${DATA_KEYS.TASK_GROUP}.${COMMON_DATA_KEYS.UUID}`]: 'Task Group UUID'
};

// There is no attributes ON_UPDATE because the only action available for historical jobs on import is creation.
// Simple updates might be possible via regular job import
export const MINIMUM_ATTRS_TO_MAP = {
    [MANDATORY_STATUS.ON_CREATION]: [
        DATA_KEYS.SCHEDULED_ON,
        `${DATA_KEYS.LOCATION}.${COMMON_DATA_KEYS.UUID}`,
        `${DATA_KEYS.JOB_LEAD}.${COMMON_DATA_KEYS.UUID}`,
        HISTORICAL_JOB_DATA_KEYS.STARTED_DATETIME,
        HISTORICAL_JOB_DATA_KEYS.COMPLETED_DATETIME
    ],
    [MANDATORY_STATUS.ON_UPDATE]: [],
    [MANDATORY_STATUS.ALTERNATIVE_ID]: [
        `${DATA_KEYS.LOCATION}.${LOCATION_KEYS.NAME}`,
        `${DATA_KEYS.ASSET}.${ASSET_KEYS.NAME}`,
        `${DATA_KEYS.USER}.${USER_KEYS.NAME}`
    ]
};
