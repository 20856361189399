import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { AutoSizer, Column, Table } from 'react-virtualized';
import classNames from 'classnames';

import { FAButton, FATooltip } from 'FA_STORYBOOK';
import { MODIFIERS } from '../../utils/constants';

import 'antd/lib/button/style/index.css';
import 'react-virtualized/styles.css';
import './CsvFilePreview.sass';

const ButtonGroup = Button.Group;

const CLASSES = {
    BASE: 'fa-csv-file-preview',
    TABLE_CONTAINER: 'fa-csv-file-preview--table-container',
    MODIFIERS: {
        IS_FIXED_WIDTH: 'is-fixed-width'
    }
};

const COLUMN_WIDTH_MODES = {
    AUTO: 'Auto-Fit',
    SMALL: 'Small',
    MEDIUM: 'Medium',
    LARGE: 'Large'
};
const modeButtonsKeys = Object.keys(COLUMN_WIDTH_MODES);

const FIXED_WIDTHS = {
    [COLUMN_WIDTH_MODES.AUTO]: 0,
    [COLUMN_WIDTH_MODES.SMALL]: 100,
    [COLUMN_WIDTH_MODES.MEDIUM]: 200,
    [COLUMN_WIDTH_MODES.LARGE]: 300
};

function getTableColumnsFromHeaders(csvColumnHeaders) {
    return csvColumnHeaders.map(key => ({
        key,
        dataIndex: key,
        title: key
    }));
}

function CsvFilePreview({ csvColumnHeaders, csvFilePreview, extra, isRebrand }) {
    const [columnWidthMode, setColumnWidthMode] = useState(COLUMN_WIDTH_MODES.AUTO);
    const columns = getTableColumnsFromHeaders(csvColumnHeaders);

    const isAutoWidth = columnWidthMode === COLUMN_WIDTH_MODES.AUTO;

    return (
        <div className={CLASSES.BASE}>
            <header className={classNames({[MODIFIERS.IS_REBRAND]: isRebrand})}>
                <section>{extra}</section>
                <section>
                    Column Width:{' '}
                    <ButtonGroup>
                        {modeButtonsKeys.map(key => {
                            const value = COLUMN_WIDTH_MODES[key];
                            return (
                                <FAButton
                                    isRebrand={isRebrand}
                                    key={value}
                                    type={value === columnWidthMode ? 'primary' : 'default'}
                                    onClick={() => setColumnWidthMode(value)}
                                >
                                    {value}
                                </FAButton>
                            );
                        })}
                    </ButtonGroup>
                </section>
            </header>
            <main
                className={classNames(CLASSES.TABLE_CONTAINER, {
                    [CLASSES.MODIFIERS.IS_FIXED_WIDTH]: !isAutoWidth
                })}
            >
                <AutoSizer>
                    {({ height, width: autoWidth }) => {
                        const width = isAutoWidth ? autoWidth : columns.length * FIXED_WIDTHS[columnWidthMode];
                        return (
                            <Table
                                width={width}
                                height={height}
                                headerHeight={40}
                                rowHeight={40}
                                rowCount={csvFilePreview.length}
                                rowGetter={({ index }) => csvFilePreview[index]}
                            >
                                {columns.map(column => (
                                    <Column
                                        key={column.key}
                                        dataKey={column.key}
                                        label={column.title}
                                        width={width / columns.length}
                                        headerRenderer={({ label }) => (
                                            <FATooltip title={label}>
                                                <span>{label}</span>
                                            </FATooltip>
                                        )}
                                    />
                                ))}
                            </Table>
                        );
                    }}
                </AutoSizer>
            </main>
        </div>
    );
}

CsvFilePreview.propTypes = {
    csvColumnHeaders: PropTypes.arrayOf(PropTypes.string),
    csvFilePreview: PropTypes.arrayOf(PropTypes.object),
    extra: PropTypes.node,
    isRebrand: PropTypes.bool
};

export default CsvFilePreview;
