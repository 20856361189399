import * as formatters from '.';
import { CUSTOMER_DATA_KEYS as DATA_KEYS } from '../schemas/dataKeys';

export const FORMATTERS = {
    [DATA_KEYS.UUID]: formatters.trimString,
    [DATA_KEYS.CUSTOMER_ID]: formatters.toNumber,
    [DATA_KEYS.NAME]: formatters.trimString,
    [DATA_KEYS.PHONE_NUMBER]: formatters.trimString,
    [DATA_KEYS.EMAIL_ADDRESS]: formatters.trimString,
    [DATA_KEYS.FAX]: formatters.trimString,
    [DATA_KEYS.WEBSITE]: formatters.trimString,
    [DATA_KEYS.NOTES]: formatters.trimString,
    [DATA_KEYS.ACCOUNT_REF]: formatters.trimString,
    [DATA_KEYS.DISCOUNT]: formatters.toNumber,
    [DATA_KEYS.GL_ACCOUNT]: formatters.trimString,
    [DATA_KEYS.TAXABLE]: formatters.yesNoToBoolean,
    [DATA_KEYS.ON_HOLD]: formatters.yesNoToBoolean,
    [DATA_KEYS.LOCATION_NAME]: formatters.trimString,
    [DATA_KEYS.LOCATION_USE_LAT_LNG]: formatters.yesNoToBoolean,
    [DATA_KEYS.LOCATION_STREET_NAME]: formatters.trimString,
    [DATA_KEYS.LOCATION_CITY]: formatters.trimString,
    [DATA_KEYS.LOCATION_STATE]: formatters.trimString,
    [DATA_KEYS.LOCATION_ZIP_CODE]: formatters.trimString,
    [DATA_KEYS.LOCATION_COUNTRY]: formatters.trimString,
    [DATA_KEYS.LOCATION_LATITUDE]: formatters.toNumber,
    [DATA_KEYS.LOCATION_LONGITUDE]: formatters.toNumber,
    [DATA_KEYS.LOCATION_TYPE]: formatters.trimString,
    [DATA_KEYS.LOCATION_TAX]: formatters.trimString,
    [DATA_KEYS.LOCATION_IS_BILLING]: formatters.yesNoToBoolean
};

export function getFormatters() {
    return FORMATTERS;
}

export const REQUIRED_FIELDS_FOR_UUID_REQUEST = [DATA_KEYS.NAME];
export const OPTIONAL_FIELDS_FOR_UUID_REQUEST = [DATA_KEYS.CUSTOMER_ID, DATA_KEYS.GL_ACCOUNT];
