import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import JSONPretty from 'react-json-pretty';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { Alert, Divider, Progress, Tag } from 'antd';

import { FAButton, FADataTable, FAModal, FASectionTitle, FASpinner, NonBreakingSpace } from 'FA_STORYBOOK';
import withTooltip from 'FA_STORYBOOK_HOCS/withTooltip';
import ImportReport from '../../components/ImportReport';
import StrongList from '../../components/StrongList';
import { isHttpCreated, isHttpSuccess, pluralise } from '../../utils';
import { exportErrorsToCsv } from '../../utils/csvExporter';
import { ALERT_STATUS, entities as ENTITIES, IMPORT_STATUS, MANDATORY_STATUS } from '../../constants';
import { PROGRESS_STATUS, COLOURS, MODIFIERS, CSV_MODAL_TAB_KEYS } from '../../utils/constants';
import { showConfirmModal } from '../../utils/modalInterface';
import { hasMinimumMappedKeys, getMinimumAttributes } from '../../schemas';

import 'antd/lib/alert/style/index.css';
import 'antd/lib/divider/style/index.css';
import 'antd/lib/progress/style/index.css';
import 'antd/lib/tag/style/index.css';
import './ImportRunner.sass';
import { COMMON_DATA_KEYS } from '../../schemas/dataKeys';

const TagWithTooltip = withTooltip(Tag);
const FAButtonWithTooltip = withTooltip(FAButton);

const CLASSES = {
    BASE: 'fa-import-runner',
    STATUS: 'fa-import-runner--status',
    CONTROLS: 'fa-import-runner--controls',
    BUTTONS: 'fa-import-runner--buttons',
    ERROR_MESSAGE: 'fa-import-runner--error-message',
    ERROR_DETAIL: 'fa-import-runner--error-detail'
};

const responseTableColumns = [
    {
        title: '#',
        key: 'responseIndex',
        dataIndex: 'responseIndex',
        className: 'fa-import-runner--responseIndex',
        render(zeroBasedIndex) {
            return zeroBasedIndex + 1;
        }
    },
    {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        className: 'fa-import-runner--status',
        render(httpStatus) {
            const { colour, message } = getHttpStatusTagColourAndTooltip(httpStatus);
            return (
                <TagWithTooltip color={colour} message={message}>
                    {httpStatus}
                </TagWithTooltip>
            );
        }
    },
    {
        title: (
            <div>
                Response{' '}
                <small>
                    <i>Click each row to expand</i>
                </small>
            </div>
        ),
        className: 'ImportRunner--id',
        key: 'id',
        dataIndex: 'id',
        render(value, { errorMessage, status }) {
            return value ? (
                <span>
                    {isHttpCreated(status) ? 'Created' : 'Updated'} <code>{value}</code>
                </span>
            ) : (
                <span className={CLASSES.ERROR_MESSAGE}>{errorMessage}</span>
            );
        }
    }
];

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function getProgressColourForStatus(status) {
    return (
        {
            [IMPORT_STATUS.COMPLETE]: COLOURS.SUCCESS,
            [IMPORT_STATUS.INTERRUPTED]: COLOURS.NONE,
            [IMPORT_STATUS.STOPPED]: COLOURS.STOPPED,
            [IMPORT_STATUS.PAUSING]: COLOURS.WARNING,
            [IMPORT_STATUS.PAUSED]: COLOURS.WARNING,
            [IMPORT_STATUS.RUNNING]: COLOURS.PROGRESS,
            [IMPORT_STATUS.RETRYING]: COLOURS.NONE,
            [IMPORT_STATUS.PREPARING]: COLOURS.NONE
        }[status] || ''
    );
}

function getHttpStatusTagColourAndTooltip(httpStatus) {
    if (isHttpCreated(httpStatus)) {
        return {
            colour: 'green',
            message: `HTTP ${httpStatus}: Record Created`
        };
    }
    if (isHttpSuccess(httpStatus)) {
        return {
            colour: 'blue',
            message: `HTTP ${httpStatus}: Record Updated`
        };
    }
    return {
        colour: 'red',
        message: `Request failed with HTTP Status: ${httpStatus}`
    };
}

function computeResponses(importData, recoveryOffset) {
    return importData.reduce((responsesToRender, { response }) => {
        if (!response) return responsesToRender;
        responsesToRender.push({
            ...response,
            responseIndex: responsesToRender.length + recoveryOffset
        });

        return responsesToRender;
    }, []);
}

function getStartButtonText(importStatus, isPauseResume) {
    if (importStatus === IMPORT_STATUS.RUNNING) return 'Running...';
    if (importStatus === IMPORT_STATUS.RETRYING) return 'Retrying...';

    return isPauseResume ? 'Resume Run' : 'Start Run';
}

function ImportRunner({
    // State
    entity,
    entityLabel,
    fileName,
    importStatus,
    schema,
    csvColumnHeaders,
    importData,
    importDone,
    mappedKeys,
    csvLines,
    validationMetrics,
    importMetrics,
    interruptCount,
    retryMetrics,
    showImportResult,
    isRebrand,
    isEnterprise,
    isUuidLookupEnabled,
    // Functions
    onStart,
    onPause,
    onStop,
    onResume,
    resetState,
    setShowImportResult,
    setCsvModalIsVisible
}) {
    const [hideSuccessRows, setHideSuccessRows] = useState(false);
    const [preRecoveryMetrics, setPreRecoveryMetrics] = useState(importMetrics);
    const [isStopping, setIsStopping] = useState(false);

    const isInterruptRecovery = Boolean(useQuery().get('resume'));
    const isPauseResume = [IMPORT_STATUS.INTERRUPTED, IMPORT_STATUS.PAUSED].includes(importStatus);

    function cachePreRecoveryMetrics() {
        setPreRecoveryMetrics({ ...importMetrics });
    }
    useEffect(cachePreRecoveryMetrics, [isInterruptRecovery]);

    const recoveryOffset = interruptCount ? preRecoveryMetrics.totalRequests : 0;
    const allResponses = useMemo(() => computeResponses(importData, recoveryOffset), [importData, recoveryOffset]);

    const requiredFieldsMapped = hasMinimumMappedKeys(entity, mappedKeys);
    const startButtonIsDisabled =
        !requiredFieldsMapped ||
        importMetrics.targetRequests === 0 ||
        [
            IMPORT_STATUS.RUNNING,
            IMPORT_STATUS.RETRYING,
            IMPORT_STATUS.STOPPED,
            IMPORT_STATUS.COMPLETE,
            IMPORT_STATUS.PAUSING
        ].includes(importStatus);
    const importPercent = importDone
        ? 100
        : Math.round((importMetrics.totalRequests * 100) / importMetrics.targetRequests);
    const importStarted = importMetrics.totalRequests > 0;

    const listFormatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
    const minimumRequiredKeys = getMinimumAttributes(entity, isEnterprise);

    // job related entities don't allow for updates, so there is no need to warn for duplicate creation
    const isJobRelatedImport = [ENTITIES.JOBS, ENTITIES.JOB_HISTORY, ENTITIES.QUOTES].includes(entity);
    const warnForDuplicates =
        !isJobRelatedImport && (!mappedKeys.includes(COMMON_DATA_KEYS.UUID) || validationMetrics.createRequests > 0);

    const history = useHistory();

    return (
        <div className={CLASSES.BASE}>
            {!(schema && csvColumnHeaders.length) && <Redirect to="/import" />}
            {!requiredFieldsMapped && (
                <Alert
                    showIcon
                    type="error"
                    message="Unable to Import CSV File because some required mappings are missing"
                    description={
                        <span>
                            One of the following attributes must be mapped to run the import:
                            <ul>
                                {minimumRequiredKeys[MANDATORY_STATUS.ON_CREATION].length ? (
                                    <li>
                                        To create new entries,{' '}
                                        <StrongList
                                            listParts={listFormatter.formatToParts(
                                                minimumRequiredKeys[MANDATORY_STATUS.ON_CREATION]
                                            )}
                                        />{' '}
                                        {minimumRequiredKeys[MANDATORY_STATUS.ON_CREATION].length > 1 ? 'are' : 'is'}{' '}
                                        required.
                                    </li>
                                ) : (
                                    ''
                                )}
                                {minimumRequiredKeys[MANDATORY_STATUS.ON_UPDATE].length ? (
                                    <li>
                                        For updates,{' '}
                                        <StrongList
                                            listParts={listFormatter.formatToParts(
                                                minimumRequiredKeys[MANDATORY_STATUS.ON_UPDATE]
                                            )}
                                        />{' '}
                                        {minimumRequiredKeys[MANDATORY_STATUS.ON_UPDATE].length > 1 ? 'are' : 'is'}{' '}
                                        required.
                                    </li>
                                ) : (
                                    ''
                                )}
                            </ul>
                            <span className={CLASSES.BUTTONS}>
                                <FAButtonWithTooltip
                                    isRebrand={isRebrand}
                                    onClick={() => setCsvModalIsVisible(true, CSV_MODAL_TAB_KEYS.VALIDATED_CSV)}
                                    message={
                                        'You can try to fetch the missing attributes from the validated CSV viewer'
                                    }
                                >
                                    Fetch missing data
                                </FAButtonWithTooltip>
                                <NonBreakingSpace />
                                <FAButtonWithTooltip
                                    isRebrand={isRebrand}
                                    onClick={() => history.push(`/import/${entity}/configuration`)}
                                    message={
                                        'You can re-arrange the mapping between the CSV fields and expected attributes'
                                    }
                                >
                                    Rearrange mappings
                                </FAButtonWithTooltip>
                            </span>
                        </span>
                    }
                />
            )}
            {isUuidLookupEnabled && requiredFieldsMapped && warnForDuplicates && (
                <Alert
                    showIcon
                    type="warning"
                    message="
                        Are you sure you want to proceed? If these records already exist in Fieldaware, then duplicates may be created.
                        If you do not intend to create new records and would prefer to update existing records, select 'Inspect' above and
                        Fieldaware can attempt to fetch the missing UUID values.
                    "
                ></Alert>
            )}
            <header>
                <section className={CLASSES.STATUS}>
                    <FASectionTitle>Import Status:</FASectionTitle>
                    <span>
                        <Tag color={getProgressColourForStatus(importStatus)}>{importStatus}</Tag>
                    </span>
                </section>
                <section className={CLASSES.CONTROLS}>
                    <FAButtonWithTooltip
                        onClick={() =>
                            isPauseResume
                                ? onResume()
                                : onStart(isInterruptRecovery ? preRecoveryMetrics.totalRequests : 0)
                        }
                        type="primary"
                        disabled={startButtonIsDisabled}
                        icon="play-circle"
                        style={{ margin: '0 5px' }}
                        loading={importStatus === IMPORT_STATUS.RETRYING}
                        isRebrand={isRebrand}
                        message={
                            !requiredFieldsMapped
                                ? 'Missing required fields, try to fetch missing fields on Map CSV Columns Views'
                                : 'Go ahead!'
                        }
                    >
                        {getStartButtonText(importStatus, isPauseResume)}
                    </FAButtonWithTooltip>
                    <FAButton
                        loading={importStatus === IMPORT_STATUS.PAUSING}
                        onClick={() => onPause()}
                        disabled={importStatus !== IMPORT_STATUS.RUNNING}
                        icon="pause-circle"
                        style={{ margin: '0 5px' }}
                        isRebrand={isRebrand}
                    >
                        {importStatus === IMPORT_STATUS.PAUSING ? 'Pausing...' : 'Pause Run'}
                    </FAButton>
                    <FAButton
                        type="dark"
                        onClick={() => {
                            setIsStopping(true);
                            const wasRunning = importStatus === IMPORT_STATUS.RUNNING;
                            // Pause while confirming stop
                            if (![IMPORT_STATUS.PAUSED, IMPORT_STATUS.INTERRUPTED].includes(importStatus)) onPause();
                            // Show confirm modal
                            showConfirmModal({
                                width: 500,
                                closable: true,
                                title: 'Stop Import?',
                                content: (
                                    <div>
                                        <p>
                                            This action will stop the current import. You will not be able to resume the
                                            import run.
                                        </p>
                                    </div>
                                ),
                                onOk: () => {
                                    onStop();
                                    setIsStopping(false);
                                },
                                // continue running import if it was running prior to the confirm modal
                                onCancel: () => {
                                    if (wasRunning) onStart();
                                    setIsStopping(false);
                                }
                            });
                        }}
                        disabled={[
                            IMPORT_STATUS.RETRYING,
                            IMPORT_STATUS.STOPPED,
                            IMPORT_STATUS.COMPLETE,
                            IMPORT_STATUS.PREPARING,
                            IMPORT_STATUS.PAUSING
                        ].includes(importStatus)}
                        icon="stop"
                        style={{ margin: '0 5px' }}
                        isRebrand={isRebrand}
                    >
                        Stop Run
                    </FAButton>
                </section>
            </header>
            {importStarted && (
                <Progress
                    percent={importPercent}
                    status={importStatus === IMPORT_STATUS.RUNNING ? PROGRESS_STATUS.ACTIVE : PROGRESS_STATUS.NORMAL}
                    strokeColor={getProgressColourForStatus(importStatus)}
                />
            )}
            {isInterruptRecovery && (
                <Alert
                    showIcon
                    message="Interrupted Import Recovered"
                    description={
                        <div>
                            <p>
                                {preRecoveryMetrics.totalRequests}/{preRecoveryMetrics.targetRequests} API requests were
                                made prior to interrupt.
                            </p>
                            <p>
                                The response payloads for the records imported before the interrupt are no longer
                                available.
                            </p>
                            {importStatus === IMPORT_STATUS.INTERRUPTED && (
                                <p>
                                    Use the <strong>Resume Run</strong> button to continue importing the remaining{' '}
                                    {interruptCount} records.
                                </p>
                            )}
                        </div>
                    }
                    type={importStatus === IMPORT_STATUS.INTERRUPTED ? ALERT_STATUS.INFO : ALERT_STATUS.SUCCESS}
                />
            )}
            {importStatus === IMPORT_STATUS.RETRYING && (
                <Alert
                    showIcon
                    message={`Retrying ${retryMetrics.errorsToRetry} Network Errors`}
                    description={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <aside style={{ marginRight: '0.5em' }}>
                                <FASpinner isGrey />
                            </aside>
                            <main>
                                {retryMetrics.resolvedErrors}/{retryMetrics.errorsToRetry} API requests were successful
                                during retry.
                            </main>
                        </div>
                    }
                    type={ALERT_STATUS.INFO}
                />
            )}
            <section>
                <ImportReport
                    importDone={importDone}
                    importStatus={importStatus}
                    csvLines={csvLines}
                    validationMetrics={validationMetrics}
                    importMetrics={importMetrics}
                    showImportResult={showImportResult}
                    onErrorsCsvExport={() => exportErrorsToCsv(fileName, csvColumnHeaders, importData)}
                    resetState={resetState}
                    setShowImportResult={setShowImportResult}
                    isRebrand={isRebrand}
                >
                    <FAButton
                        disabled={
                            !importMetrics.errorRequests ||
                            [IMPORT_STATUS.RUNNING, IMPORT_STATUS.PAUSING].includes(importStatus)
                        }
                        type={hideSuccessRows ? 'primary' : 'default'}
                        onClick={() => setHideSuccessRows(!hideSuccessRows)}
                        isRebrand={isRebrand}
                    >
                        {hideSuccessRows ? 'Show' : 'Hide'} success rows
                    </FAButton>
                    {hideSuccessRows && (
                        <i>
                            {' '}
                            {importMetrics.successRequests} {pluralise('row', importMetrics.successRequests)} hidden
                        </i>
                    )}
                    {importStarted && <Divider />}
                    {Boolean(importMetrics.totalRequests) && (
                        <FADataTable
                            hasHDText
                            rowKey="uuid"
                            style={{
                                width: '100%'
                            }}
                            rowClassName={isRebrand ? MODIFIERS.IS_REBRAND : ''}
                            dataSource={
                                hideSuccessRows
                                    ? allResponses.filter(({ status }) => !isHttpSuccess(status))
                                    : allResponses
                            }
                            columns={responseTableColumns}
                            expandRowByClick
                            expandIconAsCell={false}
                            expandedRowRender={record => {
                                const isSuccess = isHttpSuccess(record.status);
                                return isSuccess ? (
                                    <ExpandedSuccessRow entityLabel={entityLabel} record={record} />
                                ) : (
                                    <div className={CLASSES.ERROR_DETAIL}>
                                        <FASectionTitle>Import failed with Error: {record.status}</FASectionTitle>
                                        <p>{record.errorMessage}</p>
                                        <Divider />
                                        <FASectionTitle>Request URL</FASectionTitle>
                                        <p>
                                            {record.method} <code>{record.url}</code>
                                        </p>
                                        <Divider />
                                        <FASectionTitle>Request Payload</FASectionTitle>
                                        <JSONPretty data={record.data} />
                                    </div>
                                );
                            }}
                            bordered={false}
                            isCompact={false}
                            pagination={{
                                position: 'both',
                                pageSize: 100,
                                simple: true
                            }}
                        />
                    )}
                </ImportReport>
            </section>
            <FAModal
                centered={false}
                closable={false}
                visible={importStatus === IMPORT_STATUS.PAUSING && !isStopping}
                title="Pausing..."
                footer={null}
                onCancel={() => {}}
            >
                <main style={{ display: 'flex' }}>
                    <section style={{ marginRight: '0.5em' }}>
                        <FASpinner isRebrand={isRebrand} />
                    </section>
                    <section>
                        <i>Waiting for API Requests to resolve</i>
                    </section>
                </main>
            </FAModal>
        </div>
    );
}

function ExpandedSuccessRow({ entityLabel, record }) {
    return (
        <div>
            <FASectionTitle>Import succeeded with Status: {record.status}</FASectionTitle>
            <p>
                {isHttpCreated(record.status) ? 'Created New' : 'Updated'} {entityLabel} with uuid=
                <code>{record.id}</code>
            </p>
            <Divider />
            <FASectionTitle>Request URL</FASectionTitle>
            <p>
                {record.method} <code>{record.url}</code>
            </p>
            <Divider />
            <FASectionTitle>API Response Payload</FASectionTitle>
            <JSONPretty data={record.data} />
        </div>
    );
}

ImportRunner.propTypes = {
    fileName: PropTypes.string,
    importStatus: PropTypes.string,
    schema: PropTypes.object,
    csvColumnHeaders: PropTypes.arrayOf(PropTypes.string),
    importData: PropTypes.arrayOf(PropTypes.object),
    importDone: PropTypes.bool,
    csvLines: PropTypes.arrayOf(PropTypes.array),
    validationMetrics: PropTypes.object,
    importMetrics: PropTypes.object,
    isRebrand: PropTypes.bool,
    isEnterprise: PropTypes.bool,
    isUuidLookupEnabled: PropTypes.bool,
    // Functions
    onStart: PropTypes.func.isRequired,
    onPause: PropTypes.func.isRequired,
    onResume: PropTypes.func.isRequired,
    onStop: PropTypes.func.isRequired,
    resetState: PropTypes.func.isRequired,
    setShowImportResult: PropTypes.func.isRequired,
    setCsvModalIsVisible: PropTypes.func.isRequired
};

export default ImportRunner;
