import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

import withTooltip from 'FA_STORYBOOK_HOCS/withTooltip';
import { COMMON_DATA_KEYS } from '../../../../schemas/dataKeys';
import { PREVIEW_ITEM_KEYS } from '../../../../utils/constants';

import 'antd/lib/tag/style/index.css';

const TagWithTooltip = withTooltip(Tag);

function PreviewRowValue({ columnKey, previewRowValue }) {
    // Handle generic error cases
    if (!previewRowValue) {
        return <Tag color={'red'}>Mapping error (missing value in preview row)</Tag>;
    }

    // Render a red tag for invalid data
    if (!previewRowValue[PREVIEW_ITEM_KEYS.IS_VALID]) {
        return (
            <TagWithTooltip color={'red'} message={previewRowValue[PREVIEW_ITEM_KEYS.VALIDATION_ERROR_MESSAGE]}>
                {previewRowValue[PREVIEW_ITEM_KEYS.STRING_VALUE] || '--'}
            </TagWithTooltip>
        );
    }

    // Render a blue tag with message for uuid
    if (columnKey === COMMON_DATA_KEYS.UUID && previewRowValue[PREVIEW_ITEM_KEYS.STRING_VALUE]) {
        return (
            <TagWithTooltip color={'blue'} message={'Update expected if existing item'}>
                {previewRowValue[PREVIEW_ITEM_KEYS.STRING_VALUE]}
            </TagWithTooltip>
        );
    }

    // Render the string value
    return previewRowValue[PREVIEW_ITEM_KEYS.STRING_VALUE];
}

PreviewRowValue.propTypes = {
    columnKey: PropTypes.string,
    previewRowValue: PropTypes.object
};

export default PreviewRowValue;
