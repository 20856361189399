import validators from '../validators';
import { MANDATORY_STATUS } from '../constants';
import { LOCATION_DATA_KEYS as DATA_KEYS, CUSTOMER_DATA_KEYS } from './dataKeys';

/**
 * Defines the schema structure and validator functions for the task entity.
 *
 * Nested definitions are flattened for ease of lookup @see schemas/getFlatSchemaFromSchemaDefinition
 */

// there are two combinations that we need to validate:
// if use_lat_lng is true, only name, lat, lng are required
// if use_lat_lng is false, street_name, city are required
export const SCHEMA = {
    [DATA_KEYS.UUID]: validators.optionalUuid,
    [DATA_KEYS.CUSTOMER]: {
        uuid: validators.mandatoryUuidOnCreation
    },
    [DATA_KEYS.NAME]: validators.string128,
    [DATA_KEYS.USE_LAT_LNG]: validators.useLatLng,
    [DATA_KEYS.STREET_NAME]: validators.mandatoryStringOnCreationIfNotUseLatLng128,
    [DATA_KEYS.CITY]: validators.mandatoryStringOnCreationIfNotUseLatLng128,
    [DATA_KEYS.STATE]: validators.string128,
    [DATA_KEYS.ZIP_CODE]: validators.string128,
    [DATA_KEYS.COUNTRY]: validators.string128,
    [DATA_KEYS.LATITUDE]: validators.latitude,
    [DATA_KEYS.LONGITUDE]: validators.longitude,
    [DATA_KEYS.TYPE]: validators.locationType,
    [DATA_KEYS.TAX]: {
        // Note: the values here will be replaced with validator functions when the getTaxesValidators is called
        name: true,
        group: true
    },
    [DATA_KEYS.IS_BILLING]: validators.yesNo
};

export const DEFAULT_HEADER_MAPPING = {
    [`${DATA_KEYS.CUSTOMER}.uuid`]: 'Customer UUID',
    [DATA_KEYS.UUID]: 'Location UUID',
    [DATA_KEYS.USE_LAT_LNG]: 'Use Lat/Long',
    [DATA_KEYS.NAME]: 'Location Name',
    [DATA_KEYS.STREET_NAME]: 'Street Name',
    [DATA_KEYS.CITY]: 'City',
    [DATA_KEYS.STATE]: 'State',
    [DATA_KEYS.ZIP_CODE]: 'Zip Code',
    [DATA_KEYS.COUNTRY]: 'Country',
    [DATA_KEYS.LATITUDE]: 'Latitude',
    [DATA_KEYS.LONGITUDE]: 'Longitude',
    [`${DATA_KEYS.TAX}.name`]: 'Tax Name',
    [`${DATA_KEYS.TAX}.group`]: 'Tax Group',
    [DATA_KEYS.IS_BILLING]: 'Is Billing',
    [DATA_KEYS.TYPE]: 'Type'
};

export const NON_SCHEMA_ATTRIBUTES = {
    [`${DATA_KEYS.CUSTOMER}.${CUSTOMER_DATA_KEYS.CUSTOMER_ID}`]: validators.positiveNumber,
    [`${DATA_KEYS.CUSTOMER}.${CUSTOMER_DATA_KEYS.NAME}`]: validators.string128
};

export const MINIMUM_ATTRS_TO_MAP = {
    [MANDATORY_STATUS.ON_CREATION]: [`${DATA_KEYS.CUSTOMER}.${DATA_KEYS.UUID}`, DATA_KEYS.STREET_NAME, DATA_KEYS.CITY],
    [MANDATORY_STATUS.ON_UPDATE]: [DATA_KEYS.UUID],
    [MANDATORY_STATUS.ALTERNATIVE_ID]: [
        `${DATA_KEYS.CUSTOMER}.${CUSTOMER_DATA_KEYS.CUSTOMER_ID}`,
        `${DATA_KEYS.CUSTOMER}.${CUSTOMER_DATA_KEYS.NAME}`,
        DATA_KEYS.NAME
    ]
};
