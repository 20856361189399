import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

import { FAButton, FADataTable, FASpinner } from 'FA_STORYBOOK';
import PreviewRowValue from './components/PreviewRowValue';
import LookupButton from './components/LookupButton';
import withTooltip from 'FA_STORYBOOK_HOCS/withTooltip';
import { anyAttributeIsNotValid, formatRowForPreview, getKeyIsCustomField } from '../../utils';
import { COMMON_DATA_KEYS } from '../../schemas/dataKeys';

import { COLOURS, ROW_INDEX_KEY } from '../../utils/constants';
import { exportParsedDataToCsv } from '../../utils/csvExporter';
import { addNonSchemaAttributes } from '../../schemas';

import 'antd/lib/tag/style/index.css';
import './CsvValidationPreview.sass';

const TagWithTooltip = withTooltip(Tag);

const CLASSES = {
    BASE: 'fa-csv-validation-preview'
};

function getPreviewColumns(keyMap, schema) {
    const columns = Object.keys(keyMap)
        // we don't want to display columns for keys that are not mapped (i.e mapped to empty strings)
        .filter(key => keyMap[key])
        // uuid should be the first column, leave the other columns in order as they come
        .sort(key => (key === COMMON_DATA_KEYS.UUID ? -1 : 0))
        .map(columnKey => ({
            key: columnKey,
            dataIndex: columnKey,
            csvHeader: keyMap[columnKey],
            title() {
                const isCustomField = getKeyIsCustomField(columnKey);
                const customFieldName = schema[columnKey] && schema[columnKey].name;
                const message = isCustomField ? `${customFieldName} (Custom Field)` : columnKey;
                return (
                    <span>
                        <span>{keyMap[columnKey]}</span>
                        <br />
                        <span style={{ color: COLOURS.SUCCESS }}>{' ↓ '}</span>
                        <br />
                        <TagWithTooltip message={`FieldAware Schema Key: ${message}`}>{columnKey}</TagWithTooltip>
                    </span>
                );
            },
            render(previewRowValue) {
                return <PreviewRowValue columnKey={columnKey} previewRowValue={previewRowValue} />;
            }
        }));

    return [...columns];
}

function CsvValidationPreview({
    entity,
    csvColumnHeaders,
    importData,
    keyMap,
    mappedKeys,
    schema,
    validationMetrics,
    hasDataToImport,
    url,
    token,
    isWebapp,
    isRebrand,
    isUuidLookupEnabled,
    onParsingComplete,
    onUuidFetched
}) {
    const [displayCsvExportButton, setDisplayCsvExportButton] = useState(false);
    const [allPreviewRows, setAllPreviewRows] = useState([]);
    const [errorPreviewRows, setErrorPreviewRows] = useState([]);
    const [hideSuccessRows, setHideSuccessRows] = useState(false);
    const [columns, setColumns] = useState([]);

    function parsePreviewRows() {
        async function parse() {
            const extendedSchema = addNonSchemaAttributes(entity, schema, isUuidLookupEnabled);
            const initialPreviewRows = importData.map(({ index, csvLine }) =>
                formatRowForPreview({ index, csvLine, csvColumnHeaders, keyMap, schema: extendedSchema })
            );
            setColumns(getPreviewColumns(keyMap, extendedSchema));
            setAllPreviewRows(initialPreviewRows);
            setDisplayCsvExportButton(isUuidLookupEnabled);
        }
        parse();
    }

    function onHideSuccessRowsClick(nextHideSuccessRows) {
        setHideSuccessRows(nextHideSuccessRows);
        // Only do the parsing of the error rows to a separate list once
        if (nextHideSuccessRows && !errorPreviewRows.length) {
            setErrorPreviewRows(allPreviewRows.filter(row => anyAttributeIsNotValid(row)));
        }
    }

    useEffect(parsePreviewRows, [keyMap]);

    return (
        <div className={CLASSES.BASE}>
            <header>
                {isUuidLookupEnabled && (
                    <LookupButton
                        entity={entity}
                        columns={columns}
                        hasDataToImport={hasDataToImport}
                        importData={importData}
                        url={url}
                        isWebapp={isWebapp}
                        isRebrand={isRebrand}
                        token={token}
                        mappedKeys={mappedKeys}
                        allPreviewRows={allPreviewRows}
                        setAllPreviewRows={setAllPreviewRows}
                        setColumns={setColumns}
                        onParsingComplete={onParsingComplete}
                        onUuidFetched={onUuidFetched}
                    />
                )}
                <FAButton
                    isRebrand={isRebrand}
                    disabled={!validationMetrics.errorLines}
                    type={hideSuccessRows ? 'primary' : 'default'}
                    onClick={() => onHideSuccessRowsClick(!hideSuccessRows)}
                >
                    Show validation error lines only{' '}
                    {validationMetrics.errorLines ? `(${validationMetrics.errorLines})` : ''}
                </FAButton>
                {displayCsvExportButton && (
                    <>
                        {' '}
                        <FAButton
                            disabled={!allPreviewRows}
                            isRebrand={isRebrand}
                            type={hideSuccessRows ? 'primary' : 'default'}
                            onClick={() =>
                                exportParsedDataToCsv(
                                    entity,
                                    columns.reduce((keyToHeaderMapping, { key, csvHeader }) => {
                                        keyToHeaderMapping[key] = csvHeader;
                                        return keyToHeaderMapping;
                                    }, {}),
                                    allPreviewRows
                                )
                            }
                        >
                            Download parsed data as CSV
                        </FAButton>
                    </>
                )}
            </header>
            <main>
                {allPreviewRows.length ? (
                    <FADataTable
                        rowKey={ROW_INDEX_KEY}
                        columns={columns}
                        dataSource={hideSuccessRows ? errorPreviewRows : allPreviewRows}
                        pagination={{
                            position: 'top',
                            pageSize: 100,
                            simple: true
                        }}
                        locale={{
                            emptyText: 'The CSV has no data'
                        }}
                    />
                ) : (
                    <FASpinner size="large" isGrey />
                )}
            </main>
        </div>
    );
}

CsvValidationPreview.propTypes = {
    entity: PropTypes.string.isRequired,
    csvColumnHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
    importData: PropTypes.arrayOf(PropTypes.object).isRequired,
    keyMap: PropTypes.object.isRequired,
    // mappedKeys is a Set, but the best we can do is map it to an array
    mappedKeys: PropTypes.array.isRequired,
    schema: PropTypes.object,
    validationMetrics: PropTypes.object.isRequired,
    isRebrand: PropTypes.bool,
    onParsingComplete: PropTypes.func.isRequired,
    onUuidFetched: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    isWebapp: PropTypes.bool,
    isUuidLookupEnabled: PropTypes.bool
};

CsvValidationPreview.defaultProps = {
    isUuidLookupEnabled: false
};

export default CsvValidationPreview;
