import { MANDATORY_STATUS, VALID_LOCALES } from '../constants';

export const VALIDATOR_KEYS = {
    NUMBER: 'number',
    STRING: 'string',
    UUID: 'uuid',
    OPTIONAL_UUID: 'optionalUuid',
    OPTIONAL_UUID_ON_CREATION: 'optionalUuidOnCreation',
    MANDATORY_UUID_ON_CREATION: 'mandatoryUuidOnCreation',
    ADAPT_FLOAT_AND_CHECK: 'adaptFloatAndCheck',
    POSITIVE_NUMBER: 'positiveNumber',
    VALID_DATE: 'validDate',
    OPTIONAL_DATE: 'optionalDate',
    VALID_TIME: 'validTimeString',
    OPTIONAL_TIME: 'optionalTime',
    YES_NO: 'yesNo',
    MARKUP_ENUM: 'markupEnum',
    EMAIL_ADDRESS: 'emailAddress',
    MANDATORY_EMAIL_ADDRESS_ON_CREATION: 'mandatoryEmailAddressOnCreation',
    MANDATORY_STRING_ON_CREATION_10: 'mandatoryStringOnCreation10',
    MANDATORY_STRING_ON_CREATION_100: 'mandatoryStringOnCreation100',
    MANDATORY_STRING_ON_CREATION_128: 'mandatoryStringOnCreation128',
    MANDATORY_STRING_ON_CREATION_256: 'mandatoryStringOnCreation256',
    STRING_10: 'string10',
    STRING_64: 'string64',
    STRING_100: 'string100',
    STRING_128: 'string128',
    STRING_256: 'string256',
    STRING_1024: 'string1024',
    STRING_4096: 'string4096',
    DROPDOWN: 'dropdown',
    LOCATION_TYPE: 'locationType',
    LATITUDE: 'latitude',
    LONGITUDE: 'longitude',
    USE_LAT_LNG: 'useLatLng',
    USE_LAT_LNG_CUSTOMER: 'useLatLngCustomer',
    MANDATORY_STRING_ON_CREATION_IF_NOT_USE_LAT_LNG_128: 'mandatoryStringOnCreationIfNotUseLatLng128',
    MANDATORY_PASSWORD_ON_CREATION: 'mandatoryPasswordOnCreation',
    TIMEZONE: 'timezone',
    ROLE: 'role',
    PLATFORM: 'platform',
    LOCALE: 'locale',
    ISO_DATE: 'isoDate',
    OPTIONAL_ISO_DATE: 'optionalIsoDate',
    TAX_NAME: 'taxName',
    TAX_GROUP: 'taxGroup',
    IS_FIELD_QUOTE: 'isFieldQuote',
    ISO_DATE_BEFORE: 'isoDateBefore',
    ISO_DATE_START_AFTER: 'isoDateStartAfter'
};

export const VALIDATOR_DISPLAY_NAME_AND_DESCRIPTION = {
    [VALIDATOR_KEYS.OPTIONAL_UUID]: {
        displayValue: 'optional UUID',
        description: `Unique identifier, a 32 character string like "50b2462898c547cfae494824435eb391"`,
        descriptionOnUpdate:
            'This attribute is mandatory for update operations. If included, the operation performed will be an update, while a new record will be created if this attribute is not set, or not matched.'
    },
    [VALIDATOR_KEYS.NUMBER]: {
        displayValue: 'number',
        description: 'Numeric value'
    },
    [VALIDATOR_KEYS.MANDATORY_UUID_ON_CREATION]: {
        displayValue: 'mandatoryUuidOnCreation',
        description: `Unique identifier, a 32 character string like "50b2462898c547cfae494824435eb391".`,
        descriptionOnCreate:
            'This attribute is mandatory for creation operations. On update operations, there is no issue if this attribute is not populated.'
    },
    [VALIDATOR_KEYS.ADAPT_FLOAT_AND_CHECK]: {
        displayValue: 'float',
        description: `Float number`
    },
    [VALIDATOR_KEYS.POSITIVE_NUMBER]: {
        displayValue: 'positiveNumber',
        description: `A number greater than or equal to 0`
    },
    [VALIDATOR_KEYS.VALID_DATE]: {
        displayValue: 'date',
        description: `A date value, expressed on the format YYYY-MM-DD.`
    },
    [VALIDATOR_KEYS.OPTIONAL_DATE]: {
        displayValue: 'optional date',
        description: `An optional date value, expressed on the format YYYY-MM-DD.`
    },
    [VALIDATOR_KEYS.VALID_TIME]: {
        displayValue: 'time',
        description: `A time value, expressed on the format hh:mm or hh:mm:ss.`
    },
    [VALIDATOR_KEYS.OPTIONAL_TIME]: {
        displayValue: 'optional date',
        description: `An optional time value, expressed on the format hh:mm or hh:mm:ss.`
    },
    [VALIDATOR_KEYS.YES_NO]: {
        displayValue: 'yesNo',
        description: `The value for this attribute represents a boolean (true, false), corresponding to the words "yes", "no", "true", "false". This attribute is not case sensitive.`
    },
    [VALIDATOR_KEYS.MARKUP_ENUM]: {
        displayValue: 'markupEnum',
        description: `If set, the value used for this attribute should be one of [fixed, percentage].`
    },
    [VALIDATOR_KEYS.EMAIL_ADDRESS]: {
        displayValue: 'emailAddress',
        description: `This attribute requires a valid email address.`
    },
    [VALIDATOR_KEYS.MANDATORY_EMAIL_ADDRESS_ON_CREATION]: {
        displayValue: 'mandatoryEmailAddressOnCreation',
        description: `On creation operations, this field is mandatory, and the value should be a valid email address.
On update operations, there is no issue if this attribute is not populated.`
    },
    [VALIDATOR_KEYS.MANDATORY_STRING_ON_CREATION_128]: {
        displayValue: 'mandatoryStringOnCreation(128)',
        description: `String value which has to be set on creation operations.
The maximum length of the value is 128 characters.`
    },
    [VALIDATOR_KEYS.MANDATORY_STRING_ON_CREATION_256]: {
        displayValue: 'mandatoryStringOnCreation(256)',
        description: `String value which has to be set on creation operations.
The maximum length of the value is 256 characters.`
    },
    [VALIDATOR_KEYS.STRING]: {
        displayValue: 'string',
        description: `Optional string value`
    },
    [VALIDATOR_KEYS.STRING_64]: {
        displayValue: 'string(64)',
        description: `Optional string value, with a maximum length of 64 characters`
    },
    [VALIDATOR_KEYS.STRING_128]: {
        displayValue: 'string(128)',
        description: `Optional string value, with a maximum length of 128 characters`
    },
    [VALIDATOR_KEYS.STRING_256]: {
        displayValue: 'string(256)',
        description: `Optional string value, with a maximum length of 256 characters`
    },
    [VALIDATOR_KEYS.STRING_1024]: {
        displayValue: 'string(1024)',
        description: `Optional string value, with a maximum length of 1024 characters`
    },
    [VALIDATOR_KEYS.STRING_4096]: {
        displayValue: 'string(4096)',
        description: `Optional string value, with a maximum length of 4096 characters`
    },
    [VALIDATOR_KEYS.LOCATION_TYPE]: {
        displayValue: 'location type',
        description: `Type of location. Valid values are ['headquarters', 'branch', 'residential', 'billing']`
    },
    [VALIDATOR_KEYS.LATITUDE]: {
        displayValue: 'latitude',
        description: `This attribute corresponds with the latitude from a location coordinate.
It's a number that ranges between -90 (south) and 90 (north).`
    },
    [VALIDATOR_KEYS.LONGITUDE]: {
        displayValue: 'longitude',
        description: `This attribute corresponds with the longitude from a location coordinate.
It's a number that ranges between -180 (west) and 180 (east).`
    },
    [VALIDATOR_KEYS.USE_LAT_LNG]: {
        displayValue: 'useLatLng',
        description: `Indicates if the location is defined based on its coordinates.
This is a boolean attribute that can take the values "yes", "no".
If useLatLng is true, the name, latitude and longitude attributes are required
on creation operations. If useLatLng is false, the street address and city
attributes are required on creation operations.`
    },
    [VALIDATOR_KEYS.USE_LAT_LNG_CUSTOMER]: {
        displayValue: 'useLatLng',
        description: `Indicates if the location for the customer is defined based on its coordinates.
This is a boolean attribute that can take the values "yes", "no".
If useLatLng is true, the location name, latitude and longitude attributes are required on creation operations. If useLatLng is false, the street address and city attributes are required on creation operations.`
    },
    [VALIDATOR_KEYS.MANDATORY_STRING_ON_CREATION_IF_NOT_USE_LAT_LNG_128]: {
        displayValue: 'mandatoryStringOnCreationIfNotUseLatLng(128)',
        description:
            'If useLatLng is false, this attribute, a string of at most 128 characters, is required on creation operations.'
    },
    [VALIDATOR_KEYS.MANDATORY_PASSWORD_ON_CREATION]: {
        displayValue: 'mandatory password on creation',
        description: `On user creation, password is a mandatory value. We only accept strong passwords, according to the following rules:
    1. It has to contain an uppercase letter.
    2. It has to contain a lowercase letter.
    3. It has to contain an special character.
    4. It has to contain a number.
    5. It has to be at least 8 characters long.`
    },
    [VALIDATOR_KEYS.TIMEZONE]: {
        displayValue: 'timezone',
        description: `A string defining the timezone where a user is based on.
See the list supported timezones at https://api.fieldaware.net/doc/reference/users.html?highlight=timezone#available-timezones`
    },
    [VALIDATOR_KEYS.PLATFORM]: {
        displayValue: 'platform',
        description: `Indicates the device family used by the user. The supported values for this attribute are [ios, android, online]`
    },
    [VALIDATOR_KEYS.ROLE]: {
        displayValue: 'role',
        description: `Role or group the user belongs to. Only valid values are ['org admin', 'admin', 'manager', 'dispatcher', 'employee', 'contractor'].
            Remember that "org admin" can only be used if the organisation is enterprise enabled.`
    },
    [VALIDATOR_KEYS.ISO_DATE]: {
        displayValue: 'ISO Date',
        description: `Time and date value expressed according to the ISO 8601 standard.
The value has to follow the format (YYYY-MM-DDTHH:mm:ss). Times are expected to be in the user's timezone.`
    },
    [VALIDATOR_KEYS.OPTIONAL_ISO_DATE]: {
        displayValue: 'Optional ISO Date',
        description: `Optional time and date value expressed according to the ISO 8601 standard.
The value has to follow the format (YYYY-MM-DDTHH:mm:ss). Times are expected to be in the user's timezone.`
    },
    [VALIDATOR_KEYS.DROPDOWN]: {
        displayValue: 'dropdown',
        description: `String attribute, has to take one of values defined in the custom field settings`
    },
    [VALIDATOR_KEYS.TAX_NAME]: {
        displayValue: 'taxName',
        description: `Tax name, as defined in the company settings taxes section`
    },
    [VALIDATOR_KEYS.TAX_GROUP]: {
        displayValue: 'taxGroup',
        description: `Tax group name, as defined in the company settings taxes section`
    },
    [VALIDATOR_KEYS.IS_FIELD_QUOTE]: {
        displayValue: 'isFieldQuote',
        description: `If a quote is defined as a field quote, it will require a value set for scheduled date and time.
Otherwise, the quote is considered a web quote, and this field is not needed.
The value for this attribute represents a boolean (true, false), corresponding to the words "yes", "no", "true", "false".
This attribute is not case sensitive.`
    },
    [VALIDATOR_KEYS.ISO_DATE_BEFORE]: {
        displayValue: 'ISO Date Before',
        description: `Time and date value expressed according to the ISO 8601 standard.
The value has to follow the format (YYYY-MM-DDTHH:mm:ss). Times are expected to be in the user's timezone.
When importing historical jobs, both a start and end/completion date and time must ge provided as ISO compliant strings.
The start date and time must always happen before the completion date and time.`
    },
    [VALIDATOR_KEYS.ISO_DATE_START_AFTER]: {
        displayValue: 'ISO Date Start Before',
        description: `Time and date value expressed according to the ISO 8601 standard.
The value has to follow the format (YYYY-MM-DDTHH:mm:ss). Times are expected to be in the user's timezone.
When setting a time window in jobs, and both a start after and deadline date and times are given,
the start after date and time must always happen before the deadline date and time, and the overall
duration of the time window must be at least 15 minutes.`
    },
    [VALIDATOR_KEYS.LOCALE]: {
        displayValue: 'locale',
        description: `A valid locale value should be one of ${VALID_LOCALES.join(
            ', '
        )}. These values are not case-sensitive.`
    },
    [VALIDATOR_KEYS.OPTIONAL_UUID_ON_CREATION]: {
        displayValue: 'optionalUuidOnCreation',
        description: 'Optional UUID, can only be used during creation operations'
    }
};

function getAdditionalDescription(mandatoryStatus, descriptionOnCreate, descriptionOnUpdate) {
    const additionalDescription =
        mandatoryStatus === MANDATORY_STATUS.ON_CREATION ? descriptionOnCreate : descriptionOnUpdate;

    return additionalDescription
        ? `

${additionalDescription}`
        : '';
}

export function getLabelAndDescription(key, mandatoryStatus = MANDATORY_STATUS.NEVER) {
    if (key in VALIDATOR_DISPLAY_NAME_AND_DESCRIPTION) {
        const {
            displayValue,
            description,
            descriptionOnCreate,
            descriptionOnUpdate
        } = VALIDATOR_DISPLAY_NAME_AND_DESCRIPTION[key];
        const additionalDescription = getAdditionalDescription(
            mandatoryStatus,
            descriptionOnCreate,
            descriptionOnUpdate
        );
        return {
            displayValue,
            description:
                mandatoryStatus === MANDATORY_STATUS.NEVER ? description : `${description} ${additionalDescription}`
        };
    }

    if (key.includes('tax') && key.includes('name')) {
        return VALIDATOR_DISPLAY_NAME_AND_DESCRIPTION[VALIDATOR_KEYS.TAX_NAME];
    }

    if (key.includes('tax') && key.includes('group')) {
        return VALIDATOR_DISPLAY_NAME_AND_DESCRIPTION[VALIDATOR_KEYS.TAX_GROUP];
    }

    // Custom field related fields include a key + the string "(Custom Field)"
    // To fetch their description, we look for keys with a space in it, then
    // use the string before the space to find the description in VALIDATOR_DISPLAY_NAME_AND_DESCRIPTION
    if (key.includes(' ')) {
        const keyToSpace = key.slice(0, key.indexOf(' '));
        if (keyToSpace in VALIDATOR_DISPLAY_NAME_AND_DESCRIPTION) {
            return {
                displayValue: key,
                description: VALIDATOR_DISPLAY_NAME_AND_DESCRIPTION[keyToSpace].description
            };
        }
    }

    return { displayValue: typeof value === 'string' ? key : JSON.stringify(key), description: '' };
}
