import * as formatters from '.';
import { TASK_DATA_KEYS as DATA_KEYS } from '../schemas/dataKeys';

export const FORMATTERS = {
    [DATA_KEYS.UUID]: formatters.trimString,
    [DATA_KEYS.NAME]: formatters.trimString,
    [DATA_KEYS.DESCRIPTION]: formatters.trimString,
    [DATA_KEYS.UNIT_COST]: formatters.toNumber,
    [DATA_KEYS.UNIT_PRICE]: formatters.toNumber,
    [DATA_KEYS.MARKUP_VALUE]: formatters.toNumber,
    [DATA_KEYS.TAXABLE]: formatters.yesNoToBoolean,
    [DATA_KEYS.GL_ACCOUNT]: formatters.trimString,
    [DATA_KEYS.MANDATORY]: formatters.yesNoToBoolean,
    [DATA_KEYS.ESTIMATED_DURATION]: formatters.toNumber
};

export function getFormatters() {
    return FORMATTERS;
}

export const REQUIRED_FIELDS_FOR_UUID_REQUEST = [DATA_KEYS.NAME];
