import validators from '../validators';
import { MANDATORY_STATUS } from '../constants';
import { COMMON_DATA_KEYS, GROUP_DATA_KEYS as DATA_KEYS } from './dataKeys';

/**
 * Defines the add-ons necessary to support branches in users, jobs
 *
 * Nested definitions are flattened for ease of lookup @see schemas/getFlatSchemaFromSchemaDefinition
 */
export const USER_SCHEMA = {
    [DATA_KEYS.USER_PRIMARY_GROUP]: {
        [COMMON_DATA_KEYS.UUID]: validators.mandatoryUuidOnCreation
    }
};

export const USER_HEADER_MAPPING = {
    [DATA_KEYS.USER_PRIMARY_GROUP]: 'Primary Group UUID'
};

export const USER_MINIMUM_ATTRS_TO_MAP = {
    [MANDATORY_STATUS.ON_CREATION]: [`${DATA_KEYS.USER_PRIMARY_GROUP}.${COMMON_DATA_KEYS.UUID}`]
};

export const ACTIVITY_SCHEMA = {
    [DATA_KEYS.GROUP]: {
        [COMMON_DATA_KEYS.UUID]: validators.mandatoryUuidOnCreation
    }
};

export const ACTIVITY_HEADER_MAPPING = {
    [DATA_KEYS.GROUP]: 'Group UUID'
};

export const ACTIVITY_MINIMUM_ATTRS_TO_MAP = {
    [MANDATORY_STATUS.ON_CREATION]: [`${DATA_KEYS.GROUP}.${COMMON_DATA_KEYS.UUID}`]
};
