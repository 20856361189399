import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { FAAnchor, FAButton, FACollapse, FASectionTitle } from 'FA_STORYBOOK';
import { entities } from '../../constants';
import { MODIFIERS } from '../../utils/constants';

import 'antd/lib/alert/style/index.css';
import './Help.sass';

const { Panel } = FACollapse;

const CLASSES = {
    BASE: 'Help'
};

const HELP_TOPICS = [
    {
        header: 'Authentication',
        showInWebapp: false,
        body: (
            <div>
                <p>
                    To begin using the Import Tool, you must provide <strong>(1) a FieldAware API endpoint url</strong>{' '}
                    and <strong>(2) a valid API token</strong>
                </p>
                <p>
                    To log out of the Import Tool, use the menu on the right hand side of the Navigation Bar at the top
                    of the page.
                </p>
            </div>
        )
    },
    {
        header: 'Entities',
        showInWebapp: true,
        body: (
            <div>
                <p>An entity is a type of record you can import into FieldAware.</p>
                <p>The first step to importing records is to select the entity you are importing.</p>
                <p>
                    The available schema information and the validation functionality of the Import Tool depends on the
                    entity.
                </p>
                <p>The Import Tool currently supports the following entities:</p>
                <ul>
                    {Object.values(entities).map(entity => (
                        <li key={entity}>{entity}</li>
                    ))}
                </ul>
            </div>
        )
    },
    {
        header: 'Import',
        showInWebapp: true,
        body: (
            <div>
                <p>
                    You can <strong>import</strong> records into FieldAware for the supported entities via the Import
                    Tool.
                </p>
                <p>
                    The default workflow is the import workflow. Once you have logged in, you will be taken to the
                    import workflow where you can select which entity you want to import.
                </p>
                <p>
                    You can navigate to the import workflow at any time from the Navigation Bar at the top of the page.
                </p>
            </div>
        )
    },
    {
        header: 'Export',
        showInWebapp: true,
        body: (
            <div>
                <p>
                    You can <strong>export</strong> records from FieldAware for the supported entities via the Import
                    Tool.
                </p>
                <p>You can navigate to the export workflow from the Navigation Bar at the top of the page.</p>
            </div>
        )
    },
    {
        header: 'Templates',
        showInWebapp: true,
        body: (
            <div>
                <p>
                    You can download template CSV files from FieldAware for the supported entities via the Import Tool.
                </p>
                <p>
                    You can find this section on the Navigation Bar at the top of the page. Each template includes the
                    default attributes for each entity, plus the corresponding custom fields (if any).
                </p>
            </div>
        )
    },
    {
        header: 'Uploading a CSV File',
        showInWebapp: true,
        body: (
            <div>
                <p>
                    To upload a CSV file, you can use the file system browser or simply drag a <i>.csv</i> into the
                    upload area.
                </p>
                <Alert
                    showIcon
                    type="warning"
                    message={
                        <p>
                            The CSV file you upload must have a header row with unique column names. Otherwise the
                            columns can't be mapped to data keys on the schema (see <i>Mapping CSV Columns</i> below).
                        </p>
                    }
                />
            </div>
        )
    },
    {
        header: 'Mapping CSV Columns',
        showInWebapp: true,
        body: (
            <div>
                <p>
                    When the CSV file is uploaded, the next step is to map each column name to a data field in the API
                    schema for the selected entity.
                </p>
                <Alert
                    showIcon
                    type="warning"
                    message={
                        <p>
                            The CSV file you upload must have a header row with unique column names. Otherwise the
                            columns can't be mapped to data keys on the schema.
                        </p>
                    }
                />
                <br />
                <p>For illustration purposes, consider the below mock schema:</p>
                <pre>
                    {JSON.stringify(
                        {
                            name: 'string',
                            tel: 'number',
                            archived: false,
                            address: {
                                street: 'string',
                                city: 'string',
                                country: 'string'
                            },
                            device: {
                                make: 'iPhone',
                                model: '6S'
                            }
                        },
                        null,
                        4
                    )}
                </pre>
                <br />
                <FASectionTitle>Flat Schema Keys</FASectionTitle>
                <p>
                    Simple values on the API schema are mapped directly to column names. For example, the data field{' '}
                    <code>name</code> could be mapped to the column "Name" and the <code>tel</code> could be mapped to
                    "Phone Number" or "Mobile".
                </p>
                <FASectionTitle>Nested Schema Keys</FASectionTitle>
                <p>
                    Some values in the API schema have a nested structure. We can map these data keys to column names
                    using dot notation in the mapping.
                </p>
                <p>
                    For example, the data field <code>address.street</code> could be mapped to the CSV column "Street
                    Name", <code>address.city</code> could be mapped to "Town/City", and <code>address.country</code>{' '}
                    could be mapped to "Country".
                </p>
            </div>
        )
    },
    {
        header: 'Auto Mapping & Saved Mappings',
        showInWebapp: true,
        body: (
            <div>
                <FASectionTitle>Auto Mapping</FASectionTitle>
                <p>
                    When you first upload a CSV for an entity, the Import Tool will try to automatically map the CSV
                    column names to the entity schema keys.
                </p>
                <p>
                    For example, <code>name</code> in the schema will map automatically to a column called "Name" if it
                    finds one, and <code>someLongKey</code> will automatically map to the column "Some Long Key" if it
                    exists on the CSV.
                </p>
                <p>
                    These automatic mappings can be easily overwritten or removed using the <i>Unmap</i> or{' '}
                    <i>Unmap All</i> buttons.
                </p>
                <FASectionTitle>Saved Mappings</FASectionTitle>
                <p>
                    User-defined mappings of schema keys to CSV column names are saved for the next import session, so
                    you don't have to keep mapping the same columns repeadedly.
                </p>
                <p>
                    If you wish, you can clear these mappings using the menu on the right hand side of the Navigation
                    Bar at the top of the page.
                </p>
            </div>
        )
    },
    {
        header: 'Custom Fields',
        showInWebapp: true,
        body: (
            <div>
                <p>
                    Although Custom Fields are sent to the API as nested keys under the parent key{' '}
                    <code>customFields</code> (see <i>Mapping CSV Columns</i>), we represent Custom Fields in the Import
                    Tool as a flat data field (i.e. not a nested field).
                </p>
                <p>
                    Custom Fields appear in the mapping table after regular fields, and can be identified by the{' '}
                    <code>CF</code> badge next to the name.
                </p>
            </div>
        )
    },
    {
        header: 'UUIDs',
        showInWebapp: true,
        body: (
            <div>
                <p>
                    Universally Unique Identifiers, or UUIDS, are 128 bit numbers, composed of 16 octets and represented
                    as 32 base-16 characters, that can be used to identify information across a computer system.
                </p>
                <p>
                    UUIDs are generally used for identifying information that needs to be unique within a system or
                    network thereof. Their uniqueness and low probability in being repeated makes them useful for being
                    associative keys in databases and identifiers for physical hardware within an organization.
                </p>
                <p>
                    In the context of the import tool, if a UUID column is present in the CSV file, and it is mapped to
                    the <code>uuid</code> key in the schema, then the import will <strong>update</strong> the record
                    instead of creating a new record.
                </p>
                <p>If the uuid column is not mapped, then new records will be created for all rows in the CSV.</p>
            </div>
        )
    },
    {
        header: 'Validation',
        showInWebapp: true,
        body: (
            <div>
                <p>
                    Each data field in the API schema for the entity has an associated validation function. For example,
                    a data field may require the value to be a number, or a string that represents a boolean such as
                    'Yes', 'No', 'yes', 'no'. Some validators have max length requirements, and some expect exact values
                    in the CSV.
                </p>
                <p>
                    When you map a schema key to a CSV column and parse the CSV file, each value in the column is
                    validated using the validation function.
                </p>
                <p>
                    Any values that fail the validation will appear as errors which you can view in the{' '}
                    <i>CSV Validation Preview</i> modal. CSV rows with validation errors will not be sent to the API
                    during the import run.
                </p>
            </div>
        )
    },
    {
        header: 'Validation Errors and Failed Import Requests',
        showInWebapp: true,
        body: (
            <div>
                <p>
                    During the import run, when the records are being sent to the API, some requests may fail or be
                    rejected by the API. This can happen due to network connectivity issues, or because the payload is
                    not valid for the API schema.
                </p>
                <p>
                    Failed records will appear in the import report summary and table. You can view the request payload
                    data that was sent and, in most cases, the API error that caused the request to fail.
                </p>
                <FASectionTitle>Exporting Errors</FASectionTitle>
                <p>
                    After the import run, you can export the failed records to a new CSV file. All the rows that failed
                    validation, as well as the rows that failed during the import will be present in this file.
                </p>
                <p>
                    The exported error CSV file includes an additional column with the reason for the import failure. In
                    the case of a failure over the network <i>during</i> import, the request error is shown in the
                    column. In the case of a validation failure <i>before</i> import, each data field validation error
                    message is shown. There may be one or several validation errors for the invalid row.
                </p>
            </div>
        )
    },
    {
        header: 'Menus',
        showInWebapp: false,
        body: (
            <div>
                <p>There are two action menus available on the Navigation Bar at the top of the page.</p>
                <FASectionTitle>Left Menu</FASectionTitle>
                <p>
                    The menu on the left provides options for navigating between the different workflows in the Import
                    Tool. From here you can navigate to <strong>Import</strong>, <strong>Export</strong>, or this{' '}
                    <strong>Help</strong> screen.
                </p>
                <FASectionTitle>Right Menu</FASectionTitle>
                <p>
                    The menu on the right provides actions to reset stored data in the Import Tool. From here you can
                    reset the session, log out of the session, or clear previously stored CSV column mappings.
                </p>
            </div>
        )
    }
];

function getAllActiveKeys(arrayOfPanels, hasManyPanelsExpanded) {
    return hasManyPanelsExpanded ? [] : arrayOfPanels.map((_, index) => index);
}

function Help({ hasCredentials, isWebapp, isRebrand }) {
    const history = useHistory();
    const [activeKeys, setActiveKeys] = useState([]);

    const hasManyPanelsExpanded = activeKeys.length > 1;

    return (
        <div
            className={classNames(CLASSES.BASE, {
                [MODIFIERS.IS_REBRAND]: isRebrand
            })}
        >
            {!hasCredentials && (
                <FAButton ghost type="primary" onClick={() => history.push('/auth')}>
                    {'⬅'} Go back to Log In
                </FAButton>
            )}
            <FASectionTitle>About the FieldAware Import Tool</FASectionTitle>
            <p>The FieldAware Import Tool imports records into a FieldAware business via the FieldAware API.</p>
            <p>
                To import records, you need to upload a CSV file to the Import Tool and provide a mapping of CSV columns
                in the file to data keys in the API schema.
            </p>
            <p>
                The Import Tool will guide you through the process of mapping CSV columns to data keys, and you can view
                the API schema and validation criteria without leaving the Import Tool application.
            </p>
            <p>
                You can <strong>create</strong> new records or <strong>update</strong> existing records via the Import
                Tool.
            </p>
            <p>
                Expand each topic below for more information on how to use the FieldAware Import Tool, or{' '}
                <FAButton
                    type="link"
                    size="large"
                    isRebrand={isRebrand}
                    onClick={() => {
                        setActiveKeys(getAllActiveKeys(HELP_TOPICS, hasManyPanelsExpanded));
                    }}
                >
                    click here to {hasManyPanelsExpanded ? 'collapse' : 'expand'} all topics.
                </FAButton>
            </p>
            {!isWebapp && (
                <p>
                    For additional details on how to generate the import files, and a step by step tutorial on how to
                    use the import tool, go to{' '}
                    <FAAnchor
                        isRebrand={isRebrand}
                        href="https://fieldaware.atlassian.net/wiki/spaces/DOCS/pages/3801645321/Import+Export+Tool"
                    >
                        the import tool documentation in Confluence.
                    </FAAnchor>
                </p>
            )}
            <FACollapse activeKey={activeKeys} onChange={key => setActiveKeys(key)}>
                {HELP_TOPICS.filter(topic => (isWebapp && topic.showInWebapp) || !isWebapp).map(
                    ({ header, body }, index) => (
                        <Panel key={index} header={header}>
                            {body}
                        </Panel>
                    )
                )}
            </FACollapse>
        </div>
    );
}

Help.propTypes = {
    hasCredentials: PropTypes.bool,
    isWebapp: PropTypes.bool
};

export default Help;
