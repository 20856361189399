import { entities as ENTITIES } from '../constants';

/**
 * Returns the value for crew for a historical job, which has the following format:
 * [{
 *     uuid: jobLeadUuid,
 *     workedHours: [{
 *         start: startedDatetime,
 *         end: completedDatetime
 *     }],
 *     state: 'completed'
 * }]
 *
 * @param {Object} inputObject - Parsed CSV line
 * @param {Object} inputObject.jobLead - Object specifying the uuid for the job lead in Object form
 * @param {string} inputObject.startedDatetime - ISO date specifying the starting time of the job
 * @param {string} inputObject.completedDatetime - ISO date specifying the completion time of the job
 * @param {string} combinedAttribute - Key of the attribute the combined attribute is assigned to
 *
 * @returns {Object}
 */
export function crewForHistoricalJob(
    { jobLead: { uuid: jobLeadUuid } = {}, startedDatetime, completedDatetime, ...inputObject },
    combinedAttribute = 'crew'
) {
    if (![jobLeadUuid, startedDatetime, completedDatetime].every(relevantAttribute => relevantAttribute)) {
        return {
            jobLead: { uuid: jobLeadUuid },
            startedDatetime,
            completedDatetime,
            ...inputObject
        };
    }

    inputObject[combinedAttribute] = [
        {
            uuid: jobLeadUuid,
            workedHours: [
                {
                    start: startedDatetime,
                    end: completedDatetime
                }
            ],
            state: 'completed'
        }
    ];
    inputObject['jobLead'] = { uuid: jobLeadUuid };
    return inputObject;
}

/**
 * Format taskgroup to be included under the 'tasks' attribute
 *
 * @param {Object} inputObject - Parsed CSV line
 * @param {Object} inputObject.taskGroup - Includes the uuid of the taskgroup to be unrolled
 * @param {string} combinedAttribute - Key of the attribute the combined attribute is assigned to
 *
 * @return {Object}
 */
export function taskGroupUuidAsTasks(
    { taskGroup: { uuid: taskGroupUuid } = {}, ...inputObject },
    combinedAttribute = 'tasks'
) {
    if (!taskGroupUuid) {
        return inputObject;
    }

    inputObject[combinedAttribute] = [{ taskGroup: { uuid: taskGroupUuid } }];
    return inputObject;
}

/**
 * The expectation is that the combinator will use these separate values to calculate a single output value on the schema, but there is no
 * restriction on how the combinator is used.
 *
 * Note: the schemas formatters are called before the combinators.
 *
 * @param {Object} inputObject  - the payload
 * @param {Object} combinators  - the combinator function keyed by schema key. If an empty object, the input is returned as is
 *
 *
 * @return {Object} - the payload with combinators applied
 */
export function applyCombinators(inputObject, combinators) {
    for (let i = 0; i < combinators.length; i++) {
        const combinator = combinators[i];
        inputObject = combinator(inputObject);
    }
    return inputObject;
}

const COMBINATORS_BY_ENTITY = {
    [ENTITIES.JOB_HISTORY]: [crewForHistoricalJob, taskGroupUuidAsTasks]
};

export function getEntityCombinators(entity) {
    if (!(entity in COMBINATORS_BY_ENTITY)) return [];
    return COMBINATORS_BY_ENTITY[entity];
}
