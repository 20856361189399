import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FAButton, FASectionTitle } from 'FA_STORYBOOK';
import { entities, importsExcludedEntities } from '../../constants';
import { entityLabels, getDefaultHeaders } from '../../schemas';
import { getCustomFields } from '../../api';
import { exportStringToCsv } from '../../utils/csvExporter';

import './TemplateGenerator.sass';

const CLASSES = {
    BASE: 'fa-template-generator'
};

function positionComparator(objectA, objectB) {
    return objectA.position - objectB.position;
}

async function getCustomFieldsAndDownloadTemplate(url, token, entity, isWebapp, isEnterprise) {
    const entityDefaultHeaders = getDefaultHeaders(entity, isEnterprise);
    const entityCustomFields = await getCustomFields({ url, token, entity, isWebapp });

    exportStringToCsv(`${entity}_template.csv`, [
        ...entityDefaultHeaders,
        ...entityCustomFields.sort(positionComparator).map(cf => cf.name)
    ]);
}

function TemplateGenerator({ url, token, isWebapp, isEnterprise, isRebrand }) {
    const [templateKey, setTemplateKey] = useState(null);
    const entityItems = Object.values(entities)
        .filter(entity => !importsExcludedEntities.includes(entity))
        .map(key => ({
            key,
            label: entityLabels[key]
        }));

    return (
        <div className={CLASSES.BASE}>
            <FASectionTitle>Click the button to get a CSV Template for any entity</FASectionTitle>
            {entityItems.map(({ key, label }) => (
                <FAButton
                    block
                    loading={templateKey === key}
                    size="large"
                    key={key}
                    onClick={() => {
                        setTemplateKey(key);
                        getCustomFieldsAndDownloadTemplate(url, token, key, isWebapp, isEnterprise);
                        setTemplateKey(null);
                    }}
                    isRebrand={isRebrand}
                >
                    {label}
                </FAButton>
            ))}
        </div>
    );
}

TemplateGenerator.propTypes = {
    url: PropTypes.string,
    token: PropTypes.string,
    isWebapp: PropTypes.bool,
    isEnterprise: PropTypes.bool,
    isRebrand: PropTypes.bool
};

export default TemplateGenerator;
