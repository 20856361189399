import React from 'react';
import { Modal } from 'antd';

export function showErrorModal(props) {
    return Modal.error({
        okButtonProps: {
            type: 'danger'
        },
        ...props
    });
}

export function showConfirmModal(props) {
    return Modal.confirm({
        okButtonProps: {
            className: 'fa-ant-button'
        },
        cancelButtonProps: {
            className: 'fa-ant-button'
        },
        ...props
    });
}

export function showResetModal(additionalMessage) {
    return Modal.confirm({
        width: 500,
        closable: true,
        title: 'Start a new import session with the current credentials?',
        content: (
            <div>
                <p>This action will clear all session data and start the Import workflow again.</p>
                {additionalMessage && <p>{additionalMessage}</p>}
            </div>
        ),
        okButtonProps: {
            className: 'fa-ant-button'
        },
        cancelButtonProps: {
            className: 'fa-ant-button'
        },
        onOk() {
            // TODO: pass history from Router and push new location to history stack
            window.location.hash = '#/import';
        }
    });
}
