import React from 'react';
import PropTypes from 'prop-types';

import './AnnotatedJSON.sass';

function AnnotatedJSON({ data, renderValue }) {
    return (
        <pre className="annotated-json">
            {'{'}
            <JSONObject data={data} renderValue={renderValue} />
            {'}'}
        </pre>
    );
}

function JSONObject({ parentKeys, data, renderValue }) {
    const keys = Object.keys(data);
    const lastIndex = keys.length - 1;
    return (
        <pre className="json-object">
            {keys.map((dataKey, index) => (
                <JSONLine
                    key={dataKey}
                    parentKeys={parentKeys}
                    dataKey={dataKey}
                    dataValue={data[dataKey]}
                    isLastKey={index === lastIndex}
                    renderValue={renderValue}
                />
            ))}
        </pre>
    );
}

function JSONLine({ parentKeys, dataKey, dataValue, isLastKey, renderValue }) {
    const isNested = !['number', 'boolean', 'string'].includes(typeof dataValue);
    const isArray = Array.isArray(dataValue);
    const separator = isLastKey ? '' : ',';
    const output = renderValue
        ? renderValue(Array.isArray(parentKeys) ? [...parentKeys, dataKey].join('.') : dataKey, dataValue)
        : dataValue;

    return (
        <div className="json-line">
            <span className="json-key">"{dataKey}"</span>:{isNested ? (isArray ? ' [' : ' {') : ''}{' '}
            {isNested ? (
                isArray ? (
                    <pre className="json-list">
                        {'{'}
                        <JSONObject parentKeys={[dataKey]} data={dataValue[0]} renderValue={renderValue} />
                        {'}'}
                    </pre>
                ) : (
                    <JSONObject parentKeys={[dataKey]} data={dataValue} renderValue={renderValue} />
                )
            ) : (
                <span className="json-value">{output}</span>
            )}
            {isNested ? (isArray ? ']' : '}') : ''}
            {separator}
        </div>
    );
}

AnnotatedJSON.propTypes = {
    data: PropTypes.object.isRequired,
    renderValue: PropTypes.func
};

export default AnnotatedJSON;
