import * as localStorageInterface from '../utils/localStorageInterface';

export function set(email, exportHistory) {
    localStorageInterface.write(`${email}_export_history`, exportHistory);
}

export function get(email) {
    return localStorageInterface.read(`${email}_export_history`) || [];
}

export function clear(email) {
    set(email, []);
}
