import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';

import { FAButton, FACollapse, FADataTable, FAIconWithTooltip } from 'FA_STORYBOOK';
import withTooltip from 'FA_STORYBOOK_HOCS/withTooltip';
import ImportResultModal from '../ImportResultModal';
import { IMPORT_RESULT_STATUS } from '../ImportResultModal/constants';
import { IMPORT_STATUS } from '../../constants';
import { showResetModal } from '../../utils/modalInterface';

import 'antd/lib/badge/style/index.css';
import 'antd/lib/result/style/index.css';
import './ImportReport.sass';

const { Panel } = FACollapse;
const BadgeWithTooltip = withTooltip(Badge);

const REPORT_METRICS = {
    CSV_TOTAL: 'CSV_TOTAL',
    CSV_VALID: 'CSV_VALID',
    CSV_INVALID: 'CSV_INVALID',
    CREATE_REQUESTS: 'CREATE_REQUESTS',
    UPDATE_REQUESTS: 'UPDATE_REQUESTS',
    REQUESTS_TOTAL: 'REQUESTS_TOTAL',
    REQUESTS_SUCCESS: 'REQUESTS_SUCCESS',
    REQUESTS_ERROR: 'REQUESTS_ERROR'
};

const CLASSES = {
    BASE: 'fa-import-report',
    PREVIEW_PANEL: 'fa-import-report--preview-panel',
    REPORT_PANEL: 'fa-import-report--report-panel',
    RECORD_PANEL: 'fa-import-report--record-panel',
    MODIFIERS: {
        IS_SUCCESS_ROW: 'is-success-row',
        IS_WARNING_ROW: 'is-warning-row',
        IS_ERROR_ROW: 'is-error-row'
    }
};

const columns = [
    {
        key: 'label',
        dataIndex: 'label',
        title: 'Report Item',
        render(value, { description }) {
            return (
                <div>
                    <span style={{ color: 'rgba(0, 0, 0, 0.3)', marginRight: '0.5em' }}>
                        <FAIconWithTooltip message={description} />
                    </span>
                    {value}
                </div>
            );
        }
    },
    {
        key: 'result',
        dataIndex: 'result',
        title: 'Result'
    }
];

function getStatusFromMetrics(importMetrics) {
    const { successRequests, errorRequests } = importMetrics;

    if (successRequests > 0 && errorRequests === 0) {
        // No errors so far
        return IMPORT_RESULT_STATUS.SUCCESS;
    }
    if (successRequests === 0 && errorRequests > 0) {
        // No successess so far
        return IMPORT_RESULT_STATUS.ERROR;
    }
    if (successRequests > 0 && errorRequests > 0) {
        // Mix of errors and successes so far
        return IMPORT_RESULT_STATUS.WARNING;
    }
}

function RecordsTablePanelHeader({ displayMetrics, importMetrics, importStatus }) {
    const { totalRequests, targetRequests } = importMetrics;
    const metricsString = `${totalRequests}/${targetRequests}`;
    const badgeStatus = [IMPORT_STATUS.PREPARING, IMPORT_STATUS.INTERRUPTED].includes(importStatus)
        ? null
        : getStatusFromMetrics(importMetrics);
    return (
        <span>
            API Requests
            {displayMetrics() && <span> {metricsString}</span>}
            {badgeStatus && [IMPORT_RESULT_STATUS.WARNING, IMPORT_RESULT_STATUS.ERROR].includes(badgeStatus) && (
                <BadgeWithTooltip
                    style={{ marginLeft: '0.5em' }}
                    status={getStatusFromMetrics(importMetrics)}
                    message="Some API requests were not successful"
                />
            )}
        </span>
    );
}

function ImportReport({
    children,
    // State
    importDone,
    importStatus,
    csvLines,
    validationMetrics,
    importMetrics,
    showImportResult,
    isRebrand,
    // Functions
    onErrorsCsvExport,
    setShowImportResult
}) {
    const [activePanelKeys, setActivePanelKeys] = useState([0]);
    const [importResultStatus, setImportResultStatus] = useState(IMPORT_RESULT_STATUS.NONE);

    useEffect(onImportDone, [importDone]);
    function onImportDone() {
        if (!importDone || importStatus === IMPORT_STATUS.RETRYING) return;
        // Import has been Stopped before completion
        if (importStatus === IMPORT_STATUS.STOPPED) {
            setImportResultStatus(IMPORT_RESULT_STATUS.STOPPED);
            return;
        }
        // Import is Complete
        const reportStatus = getStatusFromMetrics(importMetrics);
        setImportResultStatus(reportStatus);
    }

    useEffect(onImportStart, [importStatus]);
    function onImportStart() {
        if (importStatus === IMPORT_STATUS.RUNNING) setActivePanelKeys([1]);
    }

    function onNewSessionClick() {
        showResetModal('You will not be able to export errors from the current session after this action is taken.');
    }

    function shouldDisplayImportMetrics() {
        return importMetrics.totalRequests > 0 || importDone;
    }

    function getRowClassName({ id, result }) {
        if (importDone && id === REPORT_METRICS.REQUESTS_SUCCESS && result === importMetrics.totalRequests) {
            // All imports were successful
            return CLASSES.MODIFIERS.IS_SUCCESS_ROW;
        }
        if (id === REPORT_METRICS.REQUESTS_ERROR && result > 0) {
            // Error row has errors
            return CLASSES.MODIFIERS.IS_ERROR_ROW;
        }
    }

    // These metrics are relevant before the import run
    const importPreviewMetrics = [
        {
            id: REPORT_METRICS.CSV_TOTAL,
            label: 'Total CSV Lines',
            description: 'The number of records in the uploaded CSV file',
            result: csvLines.length
        },
        {
            id: REPORT_METRICS.CSV_INVALID,
            label: 'Skipped CSV Lines',
            description:
                'The number of records in the CSV file which had validation errors and were not included in any request',
            result: validationMetrics.errorLines
        },
        {
            id: REPORT_METRICS.CSV_VALID,
            label: 'Valid CSV Lines',
            description: 'The number of valid records in the uploaded CSV file for which API requests will be made',
            result: validationMetrics.validLines
        },
        {
            id: REPORT_METRICS.CREATE_REQUESTS,
            label: 'Create Requests',
            description: 'The number of records that will be created during the import run',
            result: validationMetrics.createRequests
        },
        {
            id: REPORT_METRICS.UPDATE_REQUESTS,
            label: 'Update Requests',
            description: 'The number of records that will be updated during the import run',
            result: validationMetrics.updateRequests
        }
    ];

    // These metrics are relevant during and after the import run
    const importRunMetrics = [
        {
            id: REPORT_METRICS.REQUESTS_TOTAL,
            label: 'Total Requests',
            description: 'The total number of API calls made during the import run',
            result: importMetrics.totalRequests
        },
        {
            id: REPORT_METRICS.REQUESTS_ERROR,
            label: 'Error Requests',
            description:
                'The number of failed requests due to data validation errors on the API, or HTTP issues during the import run',
            result: importMetrics.errorRequests
        },
        {
            id: REPORT_METRICS.REQUESTS_SUCCESS,
            label: 'Successful Requests',
            description: 'The number of successful requests resulting in a created or updated record',
            result: importMetrics.successRequests
        }
    ];

    const hasRecordsToExport = importDone && Boolean(importMetrics.errorRequests + validationMetrics.errorLines);

    return (
        <div className={CLASSES.BASE}>
            <FACollapse activeKey={activePanelKeys} onChange={keys => setActivePanelKeys(keys)}>
                <Panel header="Import Preview" className={CLASSES.REPORT_PANEL}>
                    <FADataTable
                        rowKey="id"
                        columns={columns}
                        dataSource={importPreviewMetrics.filter(row => {
                            // if the row has a shouldDisplay function, call the function, otherwise display the row
                            return !row.shouldDisplay || row.shouldDisplay();
                        })}
                        bordered={false}
                        isCompact={false}
                        hasHDText
                    />
                </Panel>
                <Panel header="Import Report" className={CLASSES.REPORT_PANEL}>
                    <FADataTable
                        rowKey="id"
                        columns={columns}
                        dataSource={importRunMetrics}
                        rowClassName={getRowClassName}
                        bordered={false}
                        isCompact={false}
                        hasHDText
                    />
                </Panel>
                {children && (
                    <Panel
                        className={CLASSES.RECORD_PANEL}
                        header={
                            <RecordsTablePanelHeader
                                importMetrics={importMetrics}
                                importStatus={importStatus}
                                displayMetrics={() => shouldDisplayImportMetrics()}
                            />
                        }
                    >
                        {children}
                    </Panel>
                )}
            </FACollapse>
            {hasRecordsToExport && (
                <FAButton block ghost size="large" type="danger" icon="download" onClick={onErrorsCsvExport}>
                    Export all errors to CSV
                </FAButton>
            )}
            {importDone && (
                <FAButton isRebrand={isRebrand} block size="large" type="primary" onClick={() => onNewSessionClick()}>
                    New Import Session
                </FAButton>
            )}
            <ImportResultModal
                importDone={importDone}
                importMetrics={importMetrics}
                importResultStatus={importResultStatus}
                validationMetrics={validationMetrics}
                showResult={showImportResult}
                onNewSessionClick={onNewSessionClick}
                setShowImportResult={setShowImportResult}
                isRebrand={isRebrand}
            />
        </div>
    );
}

ImportReport.propTypes = {
    importDone: PropTypes.bool,
    importStatus: PropTypes.string,
    csvLines: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    validationMetrics: PropTypes.object,
    importMetrics: PropTypes.object,
    // Functions
    onErrorsCsvExport: PropTypes.func,
    setShowImportResult: PropTypes.func.isRequired
};

export default ImportReport;
