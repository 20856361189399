import axios from 'axios';

import { entities } from '../constants';
import { parseCookies } from '../utils';
import { API_WEBAPP_AUTH_COOKIE } from '../utils/constants';
import { ACTIVITY_DATA_KEYS } from '../schemas/dataKeys';

/**
 * @see https://github.com/fieldaware/fieldaware/pull/4091 for details on FA-Import-Tool header
 *
 * @param {String}  token                     - the API access token
 */
function getRequestConfig(token, isWebapp = false) {
    const baseConfig = {
        headers: {
            'Content-Type': 'application/json',
            // Note: value is a placeholder, it can be any string
            'FA-Import-Tool': 'x'
        }
    };

    if (!isWebapp && !token) return baseConfig;
    if (isWebapp) {
        const webappCookies = parseCookies();
        const webappAuthCookie = webappCookies[API_WEBAPP_AUTH_COOKIE];
        if (!webappAuthCookie) return baseConfig;
        return {
            ...baseConfig,
            headers: {
                ...baseConfig.headers,
                Authorization: `Cookie ${webappAuthCookie.trim()}`
            }
        };
    }

    return {
        ...baseConfig,
        headers: {
            ...baseConfig.headers,
            Authorization: `Token ${token.trim()}`
        }
    };
}

function objectToQuery(map) {
    let value;
    const query = [];

    Object.keys(map).forEach(key => {
        value = map[key];
        key = encodeURIComponent(key);
        if (typeof value === 'boolean') {
            value && query.push(key);
        } else if (Array.isArray(value)) {
            value.forEach(valueItem => query.push(`${key}=${encodeURIComponent(valueItem)}`));
        } else {
            query.push(`${key}=${encodeURIComponent(value)}`);
        }
    });

    return query.join('&');
}

export function checkApiCredentials({ url, token }) {
    return axios.get(`${url}/auth/whoami`, getRequestConfig(token));
}

function getPage({ url, token, page, pageSize, extraQueryStringParams = {}, isWebapp }) {
    return axios.get(
        `${url}?${objectToQuery({ pageSize, page, ...extraQueryStringParams })}`,
        getRequestConfig(token, isWebapp)
    );
}

/**
 * Get all items for an endpoint for a company by traversing the list page by page.
 * endpoint in the API, so it's more than likely we will get more than 20
 * (which is the default pageSize if not specified).
 *
 * @param {String} url - base API url
 * @param {String} token - API access token
 *
 * @return {List} - CSV contents with pagesize elements
 */
const PAGE_SIZE = 100;
async function getSettings({ url, token, extraQueryStringParams, isWebapp }) {
    const allItems = [];
    let pageSize = PAGE_SIZE;
    let pageIndex = 0;

    // Just being extra careful with the comparison, although pageSize should always be <= PAGE_SIZE
    while (pageSize >= PAGE_SIZE) {
        const pagedItems = await getPage({ url, token, page: pageIndex, pageSize, extraQueryStringParams, isWebapp });
        const pageItems = pagedItems.data.items;
        allItems.push(...pageItems);
        pageSize = pageItems.length;
        pageIndex++;
    }

    return allItems;
}

export const ENTITY_TO_ENTITY_CLASS = {
    [entities.ASSETS]: 'Asset',
    [entities.CONTACTS]: 'Contact',
    [entities.CUSTOMERS]: 'Customer',
    [entities.ITEMS]: 'Product',
    [entities.JOBS]: 'Activity',
    [entities.JOB_HISTORY]: 'Activity',
    [entities.JOB_TYPES]: 'JobType',
    [entities.LOCATIONS]: 'Location',
    [entities.TASKS]: 'Task',
    [entities.QUOTES]: 'Activity',
    [entities.USERS]: 'User'
};

export async function getCustomFields({ url, token, entity, isWebapp }) {
    const entityClass = ENTITY_TO_ENTITY_CLASS[entity];
    const fullUrl = `${url}/settings/customfields/`;
    return await getSettings({ url: fullUrl, token, extraQueryStringParams: { entityClass }, isWebapp });
}

export async function getTaxes({ url, token, isWebapp }) {
    const fullUrl = `${url}/settings/tax/`;
    return await getSettings({ url: fullUrl, token, isWebapp });
}

const ENTITY_PATHS = {
    [entities.ASSETS]: '/asset/',
    [entities.CONTACTS]: '/contact/',
    [entities.CUSTOMERS]: '/customer/',
    [entities.ITEMS]: '/item/',
    [entities.JOBS]: '/job/',
    [entities.JOB_HISTORY]: '/job/',
    [entities.JOB_TYPES]: '/jobtype/',
    [entities.LOCATIONS]: '/location/',
    [entities.TASKS]: '/task/',
    [entities.USERS]: '/user/',
    [entities.FIELD_QUOTES]: '/fieldquote/',
    [entities.WEB_QUOTES]: '/webquote/'
};

function getEntityPath(entity, requestData) {
    if (entity !== entities.QUOTES) {
        return ENTITY_PATHS[entity];
    }

    return ACTIVITY_DATA_KEYS.IS_FIELD_QUOTE in requestData && requestData[ACTIVITY_DATA_KEYS.IS_FIELD_QUOTE]
        ? ENTITY_PATHS[entities.FIELD_QUOTES]
        : ENTITY_PATHS[entities.WEB_QUOTES];
}

export function postNewItem({ url, token, entity, data, isWebapp }) {
    const entityPath = getEntityPath(entity, data);
    const requestPath = `${url}${entityPath}`;

    return axios.post(requestPath, data, getRequestConfig(token, isWebapp));
}

export function putItemUpdate({ url, token, entity, uuid, data, isWebapp }) {
    const entityPath = getEntityPath(entity, data);
    const requestPath = `${url}${entityPath}${uuid}`;

    return axios.put(requestPath, data, getRequestConfig(token, isWebapp));
}

// Required for jobs, and maybe quotes
export function postItemUpdate({ url, token, entity, uuid, data, isWebapp }) {
    const entityPath = getEntityPath(entity, data);
    const requestPath = `${url}${entityPath}${uuid}`;

    return axios.post(requestPath, data, getRequestConfig(token, isWebapp));
}

// not using getEntityPath as quotes are not included as exportable entities
function getListEndpoint(url, entity) {
    const entityPath = ENTITY_PATHS[entity];
    return `${url}${entityPath}list`;
}

export function getTotalCountOfEntityForExport({ url, token, entity, isWebapp }) {
    const queryStringParams = {
        page: 0,
        pageSize: 1
    };
    const requestPath = `${getListEndpoint(url, entity)}?${objectToQuery(queryStringParams)}`;

    return axios
        .get(requestPath, getRequestConfig(token, isWebapp))
        .then(({ data }) => data.count)
        .catch(() => -1);
}

/**
 * Get a page of results for a particular entity via the API as CSV.
 * Note that this is accomplished by including the `Accept` header
 * set to 'text/csv'.
 *
 * It's also worth mentioning that the pageSize is capped to 100 items
 * in the API, so it's more than likely that this method will be used
 * to iterate over until all results are gathered.
 *
 * @param {String} url - base API url
 * @param {String} token - API access token
 * @param {bool} isWebapp - import is served from the webapp
 * @param {String} entity - identifier for the entity
 * @param {Number} pageSize - number of items expected to come in the response
 * @param {Number} page - page index, used to iterate over paginated results
 * @param {bool} exportArchived - include archived results in query
 *
 * @return {String} - CSV contents with pagesize elements
 */
export function getEntityItemsPage({ url, token, isWebapp, entity, pageSize, page, exportArchived }) {
    const queryStringParams = {
        page,
        pageSize
    };
    //if user doesnt want to include archived results add a filter to include only unarchived
    if (!exportArchived) queryStringParams.archived = 0;

    const requestPath = `${getListEndpoint(url, entity)}?${objectToQuery(queryStringParams)}`;
    const requestHeaders = getRequestConfig(token, isWebapp);
    requestHeaders.headers['Accept'] = 'text/csv';
    return axios.get(requestPath, requestHeaders).then(({ data }) => data);
}

/**
 * Get webapp specific settings.
 * @returns {Promise<{data: {count: number}}>}
 */
export async function getWebappSettings() {
    const response = await axios.get('/account/get_import_tool_settings', {
        maxRedirects: 0,
        validateStatus: status => true
    });

    return await response;
}

export const WEBAPP_EXPORT_URLS = {
    [entities.ASSETS]: '/account/settings/importexport/export_assets_csv',
    [entities.CONTACTS]: '/account/settings/importexport/export_contacts_csv',
    [entities.CUSTOMERS]: '/account/settings/importexport/export_customers_csv',
    [entities.ITEMS]: '/account/settings/importexport/export_products_csv',
    [entities.LOCATIONS]: '/account/settings/importexport/export_locations_csv',
    [entities.TASKS]: '/account/settings/importexport/export_tasks_csv',
    [entities.USERS]: '/account/settings/importexport/export_users_csv'
};

/**
 * Make a GET request to the corresponding export endpoint in the webapp.
 * @returns {Promise<{data}>}
 */
export async function startWebappExport(entity, exportArchived) {
    const requestPath = `${WEBAPP_EXPORT_URLS[entity]}${
        entity === entities.ITEMS ? `?exportArchived=${exportArchived}` : ''
    }`;
    return axios.get(requestPath);
}

/**
 * Fetch uuid in bulk for a given entity from the API.
 * The input data is an object with the following structure:
 *  {
 *     <entity>: [{
 *      attribute: value
 *     }]
 *  }
 *
 * @param {String} url - base API url
 * @param {String} token - API access token
 * @param {String} entity - identifier for the entity
 * @param {bool} isWebapp - import is served from the webapp
 * @param {Object{List[Object]}} data - data to be sent to the API, will take the form of a
 *              key based object, with the key being the entity name and the value an object
 *              {fieldName: value}
 * @returns {Promise<Object|int>} - response from the API, or -1 if the request failed
 *     The regular response has the following structure:
 *     { entity: [uuids]}
 *     where uuids has a value for the value sent, or null
 */
export async function getUuidsForData({ url, token, entity, isWebapp, data }) {
    const entityClass = ENTITY_TO_ENTITY_CLASS[entity];
    const payload = {
        [entityClass]: data
    };
    const uuidMappingUrl = `${url}/get_uuids/`;
    return await axios
        .post(uuidMappingUrl, payload, getRequestConfig(token, isWebapp))
        .then(({ data }) => data)
        .catch(() => -1);
}
