import * as check from 'check-types';
import uuidv4 from 'uuid/v4';
import { camelCase } from 'change-case';

import { defaultHeaders, validateEntity, validateSchema } from '../schemas';
import { COMMON_DATA_KEYS } from '../schemas/dataKeys';
import { getEntityFormatters, getRequiredFieldsForUuidRequest } from '../formatters';
import { getEntityCombinators } from '../combinators';
import { getRequestPayloadJson } from './csvParser';
import { CUSTOM_FIELD_PREFIX, PREVIEW_ITEM_KEYS, ROW_INDEX_KEY, UNKNOWN_DATA_TYPE } from './constants';
import { entities, WEBAPP_DOMAINS } from '../constants';

const { STRING_VALUE, IS_VALID, VALIDATION_ERROR_MESSAGE } = PREVIEW_ITEM_KEYS;

/**
 * Test if @param key is a string and starts with customFields., denoting a custom field property on the schema or record
 *
 * @param {String} key - the key to test
 *
 * @return {Boolean} - true if @param key is a string and starts with customFields.
 */
export function getKeyIsCustomField(key) {
    return typeof key === 'string' && key.indexOf(CUSTOM_FIELD_PREFIX) === 0 && key.length > CUSTOM_FIELD_PREFIX.length;
}

/**
 * Parse out the uuid from a key in the Custom Field format (prefixed with customFields.)
 *
 * @param {String} key - the key to parse the uuid from
 *
 * @return {String|Any} - if @param key is a string, return the input string without the customFields. prefix. If not, return the input value.
 */
export function getCustomFieldUuidFromKey(key) {
    if (typeof key !== 'string') return key;
    return key.replace(CUSTOM_FIELD_PREFIX, '');
}

export function isNestedKey(key) {
    return check.string(key) && key.includes('.');
}

export function splitNestedKey(key) {
    return check.string(key) && key.split('.');
}

export function isNestedDictKey(key) {
    const isNested = isNestedKey(key);
    if (!isNested) return false;

    const keyParts = splitNestedKey(key);
    return keyParts.every(part => isNaN(parseFloat(part)));
}

export function isNestedListKey(key) {
    const isNested = isNestedKey(key);
    if (!isNested) return false;

    const keyParts = splitNestedKey(key);
    return keyParts.some(part => !isNaN(parseFloat(part)));
}

const customCheckToTypeMap = {
    adaptFloatAndCheck: 'number',
    adaptYesNoToBoolAndCheck: 'boolean'
};
/**
 * Return a user-readable data type based on the validator.
 * Note that Custom Fields expect @param validator to be an object with validator.type.name = 'Some String'
 * and non-Custom Fields expect @param validator to be a function
 *
 * @param {Function|Object} validator           - the validator function or an object containing the Custom Field data type
 * @param {Boolean}         isCustomFieldKey    - whether the schema item is a Custom Field
 *
 * @return {String} - the user-readable data type
 */
export function parseDataType(validator, isCustomFieldKey) {
    // Custom Field types expect validator to be an object
    if (isCustomFieldKey) {
        return validator.type ? `${validator.type.name} (Custom Field)` : UNKNOWN_DATA_TYPE;
    }
    // non-Custom Field types expect validator to be a function
    if (validator && typeof validator === 'function') {
        return customCheckToTypeMap[validator.name] || validator.name;
    } else {
        return UNKNOWN_DATA_TYPE;
    }
}

export function getRowValidationError(row) {
    const previewRow = formatRowForPreview(row);
    const hasValidationError = anyAttributeIsNotValid(previewRow);
    const validationErrorMessage = hasValidationError ? getValidationErrorMessageForExportCsv(previewRow) : false;
    const validationErrorMetrics = getValidationMetricsForRow(previewRow);

    return [validationErrorMessage, validationErrorMetrics];
}

export function formatRowForPreview({ index, csvLine, csvColumnHeaders, keyMap, schema }) {
    if (typeof index !== 'number' || !csvLine || !csvColumnHeaders || !keyMap || !schema) {
        throw new Error('Invalid arguments provided for formatting preview row');
    }
    // populate the schema structure with the line data
    const lineAsObject = Object.keys(schema).reduce((lineObject, key) => {
        const columnHeader = keyMap[key];
        // key is not mapped to value -> ignore
        if (!columnHeader) return lineObject;

        const columnIndex = csvColumnHeaders.indexOf(columnHeader);
        const value = csvLine[columnIndex];

        lineObject[ROW_INDEX_KEY] = index;
        lineObject[key] = value;

        return lineObject;
    }, {});

    const validatedRowObject = Object.keys(lineAsObject).reduce((previewObject, key) => {
        const isCustomField = getKeyIsCustomField(key);
        const attributeValidator = isCustomField ? schema[key] && schema[key].type : schema[key];

        // if there is no validator, assume the data is valid
        const keyValue = lineAsObject[key];
        const validationErrors = attributeValidator ? attributeValidator(keyValue, lineAsObject) : [];

        const isValid = validationErrors.length === 0;
        const previewData = {
            [STRING_VALUE]: keyValue,
            [IS_VALID]: isValid,
            [VALIDATION_ERROR_MESSAGE]: Array.isArray(validationErrors) ? validationErrors.join('\n') : ''
        };

        previewObject[ROW_INDEX_KEY] = index;
        previewObject[key] = previewData;

        return previewObject;
    }, {});

    return validatedRowObject;
}

/**
 * Returns true if any object value on the provided object @param previewRow contains a key isValid whose value is falsy
 *
 * @param {Object} previewRow - object representing the csv row
 *
 * @return {Boolean} - true if the isValid flag exists and is falsy on the provided object
 */
export function anyAttributeIsNotValid(previewRow) {
    if (check.not.object(previewRow)) {
        throw new Error(`[anyAttributeIsNotValid] expected arg previewRow to be an object, got ${typeof previewRow}`);
    }
    const isNotValidValue = valueAndValidation => {
        return (
            typeof valueAndValidation === 'object' && IS_VALID in valueAndValidation && !valueAndValidation[IS_VALID]
        );
    };

    return Object.values(previewRow).some(isNotValidValue);
}

/**
 * Merge all validation errors on the row to a single message for output in the Error Message column in the CSV export at the end of the workflow
 *
 * Each non-empty validationErrorMessage on an item in the @param previewRow will be included in the output message.
 *
 * Example input: {
 *      name: {
 *          validationErrorMessage: 'error in name field'
 *     },
 *     description: {
 *          validationErrorMessage: 'error in description field'
 *     }
 * }
 *
 * Example output: "This line has 2 validation errors: (1) error in name field (2) error in description field"
 *
 * @param {Object} previewRow - the row preview object including validation status and validation error message
 *
 * @return {String} - a message string formed from the combination of all @param previewRow.validationErrorMessage values
 */
export function getValidationErrorMessageForExportCsv(previewRow) {
    if (check.not.object(previewRow)) {
        throw new Error(`[anyAttributeIsNotValid] expected arg previewRow to be an object, got ${typeof previewRow}`);
    }
    const validationErrorMessages = Object.values(previewRow)
        .filter(item => check.object(item))
        .reduce((messages, previewItem) => {
            const message = previewItem[VALIDATION_ERROR_MESSAGE];
            if (message) messages.push(`(${messages.length + 1}) ${message}`);
            return messages;
        }, []);

    const messagesAsString = validationErrorMessages.join(' ');
    const validationErrorCount = validationErrorMessages.length;

    return `This line has ${validationErrorCount} validation ${pluralise(
        'error',
        validationErrorCount
    )}: ${messagesAsString}`;
}

export async function getRequestPayloadFromImportDataRow(
    importDataRow,
    keyMap,
    schema,
    csvColumnHeaders,
    outputFormatter,
    combinators
) {
    const [validationError, validationMetrics] = getRowValidationError({
        index: importDataRow.index,
        csvLine: importDataRow.csvLine,
        csvColumnHeaders,
        keyMap,
        schema
    });

    importDataRow.isValid = !validationError;
    importDataRow.validationError = validationError;
    importDataRow.validationMetrics = validationMetrics;

    // don't create a request payload for the item if there is a validation error
    if (!validationError) {
        importDataRow.requestPayload = await getRequestPayloadJson(
            importDataRow.csvLine,
            csvColumnHeaders,
            keyMap,
            outputFormatter,
            combinators
        );
    }

    return importDataRow;
}

export async function parseImportDataFromState(
    csvLines,
    offsetIndex,
    { keyMap, schema, entity, csvColumnHeaders, url, token, isWebapp, timezone }
) {
    validateEntity(entity);
    const initialImportDataRow = {
        preview: null,
        requestPayload: null,
        response: null,
        validationError: null,
        requestError: null
    };

    const outputFormatter = await getEntityFormatters(url, token, entity, isWebapp, timezone);
    if (!outputFormatter) return;
    const combinators = getEntityCombinators(entity);
    const parsedImportDataPromises = [...csvLines].map(async (csvLine, index) => {
        const importDataRow = { index: offsetIndex + index, csvLine, ...initialImportDataRow };

        // TODO: investigate making single call to this function for the entire CSV (i.e. not line-by-line)
        return await getRequestPayloadFromImportDataRow(
            importDataRow,
            keyMap,
            schema,
            csvColumnHeaders,
            outputFormatter,
            combinators
        );
    });

    // async map returns an array of Promises which must all resolve to return the data array
    return await Promise.all(parsedImportDataPromises);
}

/**
 * Determine if the data for the provided cell will result in an update when the API request is made.
 *
 * An update will happen if the cell has a value in stringValue (i.e. is not empty) and if the validation has passed
 *
 * @param {Object} uuidCell - this should be the cell object under the 'uuid' key in the row
 *
 * @return {Boolean}
 */
export function cellWillTriggerUpdate(uuidCell) {
    // blank uuids are valid but will not result in an update, so additionally check the string value is not empty
    const isValidUpdate =
        uuidCell && uuidCell[STRING_VALUE] && (!uuidCell.hasOwnProperty(IS_VALID) || uuidCell.isValid);
    return Boolean(isValidUpdate);
}

/**
 * Detect from the requestPayload if a row in the importedData will result in an update,
 * meaning that it contains a uuid value
 *
 * @param {Object} importData - this will include a requestPayload, which is populated after parsing the row
 * @returns {Boolean}
 */
export function importDataWillTriggerUpdate(requestPayload) {
    const isUpdate = requestPayload && requestPayload[COMMON_DATA_KEYS.UUID];
    return Boolean(isUpdate);
}

/**
 * Determine if the provided row will result in a create or an update, and count the validation errors on the row
 *
 * @param {Object} row - the row represented as JSON
 *
 * @return {Object} an object with the metrics for the row
 *
 * Example outputs:
 *
 *      // No valid uuid on input
 *      {
 *          creates: true,
 *          updates: false,
 *          errors: 0
 *      }
 *
 *      // Valid uuid on input
 *      {
 *          creates: false,
 *          updates: true,
 *          errors: 0
 *      }
 *
 *      // Errors found on input
 *      {
 *          creates: false,
 *          updates: false,
 *          errors: 5
 *      }
 */
export function getValidationMetricsForRow(row) {
    if (!row || !Object.keys(row).length) {
        return {
            creates: false,
            updates: false,
            errors: 0
        };
    }

    const updates = cellWillTriggerUpdate(row[COMMON_DATA_KEYS.UUID]);

    const errors = Object.keys(row).reduce((errorCountForRow, key) => {
        const cell = row[key];
        const isValid = !cell.hasOwnProperty(IS_VALID) || cell.isValid;
        if (!isValid) errorCountForRow++;
        return errorCountForRow;
    }, 0);

    return {
        creates: !updates && !errors,
        updates: updates,
        errors: errors
    };
}

/**
 * Recalculate the validation metrics for a row based on its requestPayload attribute
 * @param {Object} importData
 * @returns Object
 */
export function getValidationMetricsForImportData({ requestPayload }) {
    if (!requestPayload || !Object.keys(requestPayload).length) {
        return {
            creates: false,
            updates: false
        };
    }

    const isImportDataUpdate = importDataWillTriggerUpdate(requestPayload);
    return {
        creates: !isImportDataUpdate,
        updates: isImportDataUpdate
    };
}

/**
 * Recalculate the validationMetrics for a row based on the data in its requestPayload
 * @param {Object} importData
 * @returns {Object} updated version of the input with recalculated validationMetrics
 */
export function recalculateMetrics(importData) {
    return importData.map(importDataItem => {
        return {
            ...importDataItem,
            validationMetrics: {
                ...importDataItem.validationMetrics,
                ...getValidationMetricsForImportData(importDataItem)
            }
        };
    });
}

export function parseTotalValidationMetrics(importData) {
    const initResult = {
        isParsed: true,
        totalErrors: 0,
        errorLines: 0,
        validLines: 0,
        createRequests: 0,
        updateRequests: 0
    };

    return importData.reduce((metrics, { validationMetrics }) => {
        if (!validationMetrics || !check.object(validationMetrics))
            throw new Error('Expected each item to provide validationMetrics');
        metrics.totalErrors += validationMetrics.errors;
        metrics.errorLines += validationMetrics.errors ? 1 : 0;
        metrics.validLines += validationMetrics.errors ? 0 : 1;
        metrics.createRequests += validationMetrics.creates ? 1 : 0;
        metrics.updateRequests += validationMetrics.updates ? 1 : 0;

        return metrics;
    }, initResult);
}

export function pluralise(word, count) {
    return `${word}${count === 1 ? '' : 's'}`;
}

export function capitalise(word) {
    if (typeof word !== 'string') return null;
    return word.charAt(0).toUpperCase() + word.slice(1);
}

/**
 * Recursive function to flatten an object into a single level
 *
 * @param {Object} source - the object to flatten
 * @param {String} parentPath - the path to the current object
 * @param {Object} target - the target object to add the flattened object to
 * @returns {Object} the target object, where there are no nested objects,
 *                   and the keys look like 'parentPath.key'
 */
export function flatten(source, parentPath = '', target = {}) {
    for (const key in source) {
        // Construct the necessary pieces of metadata
        const value = source[key];
        const path = parentPath ? `${parentPath}.${key}` : key;

        // Either append or dive another level
        if (typeof value === 'object') {
            flatten(value, path, target);
        } else {
            target[path] = source[key];
        }
    }

    return target;
}

export function newUuid() {
    return uuidv4().replace(/-/g, '');
}

function parseKey(entityDefaultHeaders, schema, columnHeader) {
    const defaultColumnHeaderToKey = Object.keys(entityDefaultHeaders).reduce((acc, key) => {
        const columnHeader = entityDefaultHeaders[key];
        acc[columnHeader] = key;
        return acc;
    }, {});

    const customFieldHeaderMapping = Object.keys(schema).reduce((acc, key) => {
        if (key.startsWith(CUSTOM_FIELD_PREFIX)) {
            const cfName = schema[key].name;
            acc[cfName] = key;
            return acc;
        }
        return acc;
    }, {});

    const columnHeaderToKey = {
        ...defaultColumnHeaderToKey,
        ...customFieldHeaderMapping
    };
    return columnHeaderToKey[columnHeader] || camelCase(columnHeader);
}

export function createSchemaKeyToColumnNameMap({ entity, schema, csvColumnHeaders, userDefinedKeyMap = {} }) {
    validateSchema(schema);
    if (!Array.isArray(csvColumnHeaders) || !csvColumnHeaders.length) {
        throw new Error(`expected arg csvColumnHeaders to be an non-empty array, got ${csvColumnHeaders}`);
    }
    if (!check.object(userDefinedKeyMap)) {
        throw new Error(`expected arg userDefinedKeyMap to be an object, got ${userDefinedKeyMap}`);
    }

    // Auto-map by looking the default headers for the keys defined for the entity
    const entityDefaultHeaders = defaultHeaders[entity];
    const csvColumnsToParsedKeyMap = csvColumnHeaders.reduce((hashMap, csvColumnName) => {
        const parsedKey = parseKey(entityDefaultHeaders, schema, csvColumnName);

        // If the parsed key is defined in the schema, add it to the key map
        if (schema[parsedKey]) hashMap[parsedKey] = csvColumnName;
        return hashMap;
    }, {});

    const relevantUserDefinedKeyMap = Object.keys(userDefinedKeyMap).reduce((outputMap, key) => {
        const mappedValue = userDefinedKeyMap[key];
        // Remove keys from the userDefinedKeyMap whose CSV column values do not exist in the current file (but preserve blank mapping values i.e. unmapped by user)
        if (mappedValue && !csvColumnHeaders.includes(mappedValue)) return outputMap;

        const customFieldsKeys = Object.keys(schema).filter(value => value.startsWith(CUSTOM_FIELD_PREFIX));
        if (key in entityDefaultHeaders || customFieldsKeys.includes(key)) {
            outputMap[key] = mappedValue;
        }
        return outputMap;
    }, {});

    // Overwrite the automatically parsed mapping with the user defined mapping
    const mergedMap = Object.assign({}, csvColumnsToParsedKeyMap, relevantUserDefinedKeyMap);
    return {
        keyMap: mergedMap,
        mappedKeys: Object.keys(mergedMap).filter(mappedKey => Boolean(mergedMap[mappedKey]))
    };
}

// Example URL: https://apiqa3.fieldaware.net/doc/reference/tasks.html
export function getApiDocumentationUrl(apiBaseUrl, entity) {
    const entityToApiDocs = [
        entities.ASSETS,
        entities.CUSTOMERS,
        entities.ITEMS,
        entities.JOBS,
        entities.QUOTES,
        entities.TASKS,
        entities.USERS
    ].reduce((entityToApiDocsMapping, regularEntity) => {
        entityToApiDocsMapping[regularEntity] = `${apiBaseUrl}/doc/reference/${regularEntity}.html`;
        return entityToApiDocsMapping;
    }, {});

    entityToApiDocs[entities.LOCATIONS] = `${apiBaseUrl}/doc/reference/customers.html#locations`;
    entityToApiDocs[entities.CONTACTS] = `${apiBaseUrl}/doc/reference/customers.html#contacts`;
    entityToApiDocs[entities.JOB_TYPES] = `${apiBaseUrl}/doc/reference/jobtype_skills.html#jobtype-and-skill`;

    // Creation of historical jobs is a non documented API feature
    entityToApiDocs[entities.JOB_HISTORY] = '';
    return entityToApiDocs[entity];
}

export function getAdditionalEntityInfo(entity) {
    if (entity === entities.JOB_HISTORY) {
        return 'Only creation of historical jobs is permitted. Updates are not supported.';
    }

    return '';
}

/**
 * The return object from csvtojson includes values not matching a header, keyed as field1, field2 etc
 * AFAIK there is no parameter to turn this off @see https://www.npmjs.com/package/csvtojson
 *
 * This function strips any keys matching @param excludePattern
 *
 * @param {Object} inputJson        - a JSON object
 * @param {RegExp} excludePattern   - regex to match keys against for exclusion
 *
 * @return {Object} - like inputJson, but without keys matching the exludePattern
 */
export function stripAdditionalFields(inputJson, excludePattern = /field\d/) {
    return Object.keys(inputJson).reduce((outputJson, key) => {
        if (!key.match(excludePattern)) {
            outputJson[key] = inputJson[key];
        }
        return outputJson;
    }, {});
}

/**
 * Given @param inputObject, return a new object whose keys are the values of inputObject and whose corresponding values
 * are the keys of inputObject
 *
 * Note: It is expected that the inputObject is a flat map, and so both keys and values should be unique
 *
 * Example:
 *  Input:
 *      {
 *          'foo': 'bar',
 *          '123': 'yes'
 *      }
 *
 *  Output:
 *      {
 *          'bar': 'foo',
 *          'yes': '123'
 *      }
 *
 * @param {Object} inputObject - a JSON object
 *
 * @return {Object} - JSON object with keys swapped with values
 */
export function swapKeyValuePairs(inputObject) {
    return Object.keys(inputObject).reduce((outputObject, key) => {
        outputObject[inputObject[key]] = key;
        return outputObject;
    }, {});
}

/**
 * Replace each string value in the input array @param csvHeaders with
 * the corresponding value in the JSON object @param keyMap
 *
 * Examples:
 *
 *  csvHeaders = ['A', 'B', 'C']
 *  keyMap = {
 *      'apple': 'A',
 *      'banana': 'B',
 *      'carrot': 'C'
 *  }
 *  return ['apple', 'banana', 'carrot']
 *
 *  csvHeaders = ['Street', 'City', 'Country']
 *  keyMap = {
 *      'address.street': 'Street',
 *      'address.city': 'City'
 *  }
 *  return ['address.street', 'address.city']
 *
 *
 * @param {String[]}    csvHeaders  - array of string values of CSV column names
 * @param {Object}      keyMap      - an object mapping a data key to a CSV column name
 *
 * @return {String[]} - an array of string values of data keys
 */
export function getKeyMappedHeaders(csvHeaders, keyMap) {
    if (!keyMap || !Object.keys(keyMap).length) return csvHeaders;
    const headersAsKeys = swapKeyValuePairs(keyMap);
    return csvHeaders.map(originalValue => {
        return headersAsKeys[originalValue];
    });
}

export function isHttpSuccess(status) {
    return status >= 200 && status < 300;
}

export function isHttpCreated(status) {
    return status === 201;
}

export function wait(milliseconds = 100) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

/**
 * Return the cookies available in document as an object.
 *
 * Javascript accessible cookies (those that are not httponly)
 * under 'document.cookie' are stored as a string of pairs key=value
 * separated by semicolon + space. This method fetches these cookies
 * and returns then in object format.
 *
 * Examples:
 *
 *  document.cookie:
 *      name=oeschger; favorite_food=tripe; test1=Hello
 *  return {
 *      name: 'oeschger',
 *      favorite_food: 'tripe',
 *      test1: 'Hello'
 *  }
 *
 *
 * @return {Object} - an object with the values stored in the cookies for the site
 */
export function parseCookies() {
    const cookieValues = document.cookie.split('; ');
    const parsedCookieKeyValues = cookieValues.map(cookieValue =>
        // split uses limit=2 to avoid splitting values containing the equal symbol
        cookieValue.split(/=(.*)$/, 2).map(decodeURIComponent)
    );
    return Object.fromEntries(parsedCookieKeyValues);
}

/**
 * Check if the site running the import tool is under one of the webapp
 * instances defined in a list of domains.
 *
 * @return {Boolean} - true if the location corresponds with a url in the WEBAPP_DOMAINS list
 */
export function isWebappDomain() {
    return WEBAPP_DOMAINS.some(webappDomain => document.location.href.includes(webappDomain));
}

export function getRequiredFieldsForUuidMapping(entity) {
    return getRequiredFieldsForUuidRequest(entity);
}

export function isObject(value) {
    return value === Object(value);
}
