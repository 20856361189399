import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Divider, Form, Input } from 'antd';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { FAButton, FAIcon, FAModal, FAOverlineLabel, FAPageTitle } from 'FA_STORYBOOK';

import Obfuscate from '../../components/Obfuscate';
import * as api from '../../api';

import 'antd/lib/alert/style/index.css';
import 'antd/lib/divider/style/index.css';
import 'antd/lib/form/style/index.css';
import 'antd/lib/input/style/index.css';
import './Authentication.sass';

const { Item } = Form;

const STYLES = {
    PLACEHOLDER_ICON: {
        color: 'rgba(0, 0, 0, .25)'
    }
};

function Authentication({ url, token, form, onAuthSuccess }) {
    const location = useLocation();
    const history = useHistory();
    const { getFieldDecorator, getFieldsValue } = form;

    const [authError, setAuthError] = useState(null);
    const [submittedFields, setSubmittedFields] = useState({});

    const { from } = location.state || { from: '/import' };

    function onSubmit(event) {
        event.stopPropagation();
        event.preventDefault();
        const fields = getFieldsValue();
        setSubmittedFields(fields);
        api.checkApiCredentials(fields)
            .then(response => {
                onAuthSuccess({
                    ...fields,
                    email: response.data.email,
                    business: response.data.business_name,
                    timezone: response.data.timezone,
                    isEnterprise: response.data.isEnterprise || false,
                    isRebrand: response.data.isRebrand || false,
                    isUuidLookupEnabled: response.data.uuidLookup || false
                });
                history.replace(from === location.pathname ? '/import' : from);
            })
            .catch(error => setAuthError(error));
    }

    return (
        <div className="Authentication">
            <FAPageTitle>API Credentials</FAPageTitle>
            <Form onSubmit={onSubmit}>
                <FAOverlineLabel>URL</FAOverlineLabel>
                <Item>
                    {getFieldDecorator('url', {
                        rules: [
                            {
                                required: true,
                                message: 'API URL is required'
                            }
                        ],
                        initialValue: url
                    })(
                        <Input
                            prefix={<FAIcon icon="cloud" style={STYLES.PLACEHOLDER_ICON} />}
                            placeholder="FieldAware API URL"
                        />
                    )}
                </Item>
                <FAOverlineLabel>Token</FAOverlineLabel>
                <Item>
                    {getFieldDecorator('token', {
                        rules: [
                            {
                                required: true,
                                message: 'API Token is required'
                            }
                        ],
                        initialValue: token
                    })(
                        <Input.Password
                            prefix={<FAIcon icon="lock" style={STYLES.PLACEHOLDER_ICON} />}
                            placeholder="FieldAware API Token"
                        />
                    )}
                </Item>
                <Item>
                    <FAButton
                        block
                        type="primary"
                        htmlType="submit"
                        disabled={!Object.values(getFieldsValue()).every(value => value)}
                    >
                        Authenticate
                    </FAButton>
                </Item>
                <Link to="/help" className="fa-anchor">
                    Help
                </Link>
            </Form>
            <ErrorModal submittedFields={submittedFields} authError={authError} setAuthError={setAuthError} />
        </div>
    );
}

function ErrorModal({ submittedFields, authError, setAuthError }) {
    return (
        <FAModal
            visible={Boolean(authError)}
            width={'500px'}
            title="Authentication Failed"
            centered={false}
            footer={null}
            onCancel={() => setAuthError(null)}
        >
            <p>Unable to authenticate with the following credentials:</p>
            <ul>
                <li>
                    <strong>URL: </strong>
                    <span>{submittedFields.url}</span>
                </li>
                <li>
                    <strong>Token: </strong>
                    <Obfuscate>{submittedFields.token}</Obfuscate>
                </li>
            </ul>
            <p>Please check your credentials and try again.</p>
            <Divider />
            <p>The message from the server is:</p>
            {authError && <Alert message="Error" description={`${authError}`} type="error" showIcon />}
        </FAModal>
    );
}

Authentication.propTypes = {
    url: PropTypes.string,
    token: PropTypes.string,
    onAuthSuccess: PropTypes.func.isRequired
};

export default Form.create({ name: 'auth' })(Authentication);
