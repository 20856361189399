import * as check from 'check-types';
import flat from 'flat';

import * as api from '../api';
import { entities as ENTITIES, MANDATORY_STATUS } from '../constants';
import {
    getCustomFieldUuidFromKey,
    getKeyIsCustomField,
    splitNestedKey,
    parseDataType,
    isNestedDictKey,
    isNestedListKey
} from '../utils';
import { CUSTOM_FIELD_PREFIX } from '../utils/constants';
import * as ASSET from './asset.schema';
import * as CONTACT from './contact.schema';
import * as CUSTOMER from './customer.schema';
import * as ENTERPRISE from './enterprise.schema';
import * as ITEM from './item.schema';
import * as JOB from './job.schema';
import * as JOB_HISTORY from './historicalJob.schema';
import * as JOB_TYPE from './jobType.schema';
import * as LOCATION from './location.schema';
import * as QUOTE from './quote.schema';
import * as TASK from './task.schema';
import * as USER from './user.schema';
import validators, { setupDatetimeValidators } from '../validators';

const validEntities = Object.values(ENTITIES);

export function isValidEntity(entity) {
    return validEntities.includes(entity);
}

export function validateEntity(entity) {
    if (!entity || !isValidEntity(entity)) {
        throw new Error(`expected arg entity to be one of: [${validEntities.join(', ')}]. Got ${entity}`);
    }
}

export function validateSchema(schema) {
    if (!check.object(schema)) {
        throw new Error(`expected arg schema to be an object. Got ${schema}`);
    }
}

/**
 * Takes the schema definition as input, which may contain nested data, and outputs a flat object
 * @see https://www.npmjs.com/package/flat and unit tests for details
 *
 * @param {Object} schemaDefinition - the schema defined in schemas/*.schema.js
 *
 * @return {Object} - flat schema
 */
export function getFlatSchemaFromSchemaDefinition(schemaDefinition) {
    validateSchema(schemaDefinition);
    return flat(schemaDefinition);
}

export const entityLabels = {
    [ENTITIES.ASSETS]: 'Asset',
    [ENTITIES.CONTACTS]: 'Contact',
    [ENTITIES.CUSTOMERS]: 'Customer',
    [ENTITIES.ITEMS]: 'Item',
    [ENTITIES.JOB_HISTORY]: 'Historical Jobs',
    [ENTITIES.JOB_TYPES]: 'Job Types',
    [ENTITIES.JOBS]: 'Job',
    [ENTITIES.LOCATIONS]: 'Location',
    [ENTITIES.QUOTES]: 'Quote',
    [ENTITIES.TASKS]: 'Task',
    [ENTITIES.USERS]: 'User'
};

const BASE_ENTITY_SCHEMAS = {
    [ENTITIES.ASSETS]: getFlatSchemaFromSchemaDefinition(ASSET.SCHEMA),
    [ENTITIES.CONTACTS]: getFlatSchemaFromSchemaDefinition(CONTACT.SCHEMA),
    [ENTITIES.CUSTOMERS]: getFlatSchemaFromSchemaDefinition(CUSTOMER.SCHEMA),
    [ENTITIES.ITEMS]: getFlatSchemaFromSchemaDefinition(ITEM.SCHEMA),
    [ENTITIES.JOB_HISTORY]: getFlatSchemaFromSchemaDefinition(JOB_HISTORY.SCHEMA),
    [ENTITIES.JOBS]: getFlatSchemaFromSchemaDefinition(JOB.SCHEMA),
    [ENTITIES.JOB_TYPES]: getFlatSchemaFromSchemaDefinition(JOB_TYPE.SCHEMA),
    [ENTITIES.LOCATIONS]: getFlatSchemaFromSchemaDefinition(LOCATION.SCHEMA),
    [ENTITIES.QUOTES]: getFlatSchemaFromSchemaDefinition(QUOTE.SCHEMA),
    [ENTITIES.TASKS]: getFlatSchemaFromSchemaDefinition(TASK.SCHEMA),
    [ENTITIES.USERS]: getFlatSchemaFromSchemaDefinition(USER.SCHEMA)
};

const ENTERPRISE_ENTITY_SCHEMAS = {
    [ENTITIES.JOB_HISTORY]: getFlatSchemaFromSchemaDefinition({
        ...JOB_HISTORY.SCHEMA,
        ...ENTERPRISE.ACTIVITY_SCHEMA
    }),
    [ENTITIES.JOBS]: getFlatSchemaFromSchemaDefinition({
        ...JOB.SCHEMA,
        ...ENTERPRISE.ACTIVITY_SCHEMA
    }),
    [ENTITIES.QUOTES]: getFlatSchemaFromSchemaDefinition({
        ...QUOTE.SCHEMA,
        ...ENTERPRISE.ACTIVITY_SCHEMA
    }),
    [ENTITIES.USERS]: getFlatSchemaFromSchemaDefinition({
        ...USER.SCHEMA,
        ...ENTERPRISE.USER_SCHEMA
    })
};

const NON_SCHEMA_ATTRIBUTES = {
    [ENTITIES.ASSETS]: ASSET.NON_SCHEMA_ATTRIBUTES,
    [ENTITIES.CUSTOMERS]: CUSTOMER.NON_SCHEMA_ATTRIBUTES,
    [ENTITIES.CONTACTS]: CONTACT.NON_SCHEMA_ATTRIBUTES,
    [ENTITIES.LOCATIONS]: LOCATION.NON_SCHEMA_ATTRIBUTES,
    [ENTITIES.JOBS]: JOB.NON_SCHEMA_ATTRIBUTES,
    [ENTITIES.QUOTES]: JOB.NON_SCHEMA_ATTRIBUTES,
    [ENTITIES.JOB_HISTORY]: JOB.NON_SCHEMA_ATTRIBUTES
};

function getBaseSchema(entity, isEnterprise) {
    if (isEnterprise && entity in ENTERPRISE_ENTITY_SCHEMAS) {
        return ENTERPRISE_ENTITY_SCHEMAS[entity];
    }

    return BASE_ENTITY_SCHEMAS[entity];
}

/*
 * defaultHeaders specifies the default csv headers expected on csv files.
 * They are used to do the initial mapping between column headers and attributes
 * for the different ENTITIES, resulting on straight mapping of all attributes
 * when importing a csv generated by doing an export on the tool.
 */
export const defaultHeaders = {
    [ENTITIES.ASSETS]: ASSET.DEFAULT_HEADER_MAPPING,
    [ENTITIES.CONTACTS]: CONTACT.DEFAULT_HEADER_MAPPING,
    [ENTITIES.CUSTOMERS]: CUSTOMER.DEFAULT_HEADER_MAPPING,
    [ENTITIES.ITEMS]: ITEM.DEFAULT_HEADER_MAPPING,
    [ENTITIES.JOB_HISTORY]: JOB_HISTORY.DEFAULT_HEADER_MAPPING,
    [ENTITIES.JOBS]: JOB.DEFAULT_HEADER_MAPPING,
    [ENTITIES.JOB_TYPES]: JOB_TYPE.DEFAULT_HEADER_MAPPING,
    [ENTITIES.QUOTES]: QUOTE.DEFAULT_HEADER_MAPPING,
    [ENTITIES.LOCATIONS]: LOCATION.DEFAULT_HEADER_MAPPING,
    [ENTITIES.TASKS]: TASK.DEFAULT_HEADER_MAPPING,
    [ENTITIES.USERS]: USER.DEFAULT_HEADER_MAPPING
};

export const defaultHeadersEnterprise = {
    [ENTITIES.JOB_HISTORY]: {
        ...JOB_HISTORY.DEFAULT_HEADER_MAPPING,
        ...ENTERPRISE.ACTIVITY_HEADER_MAPPING
    },
    [ENTITIES.JOBS]: {
        ...JOB.DEFAULT_HEADER_MAPPING,
        ...ENTERPRISE.ACTIVITY_HEADER_MAPPING
    },
    [ENTITIES.QUOTES]: {
        ...QUOTE.DEFAULT_HEADER_MAPPING,
        ...ENTERPRISE.ACTIVITY_HEADER_MAPPING
    },
    [ENTITIES.USERS]: {
        ...USER.DEFAULT_HEADER_MAPPING,
        ...ENTERPRISE.USER_HEADER_MAPPING
    }
};

/*
 * entityMinimumMapping indicates which are the minimum attributes to be mapped
 * to proceed with either creation or updates for each entity during the configuration
 * step of an import.
 */
const entityMinimumMapping = {
    [ENTITIES.ASSETS]: ASSET.MINIMUM_ATTRS_TO_MAP,
    [ENTITIES.CONTACTS]: CONTACT.MINIMUM_ATTRS_TO_MAP,
    [ENTITIES.CUSTOMERS]: CUSTOMER.MINIMUM_ATTRS_TO_MAP,
    [ENTITIES.ITEMS]: ITEM.MINIMUM_ATTRS_TO_MAP,
    [ENTITIES.JOB_HISTORY]: JOB_HISTORY.MINIMUM_ATTRS_TO_MAP,
    [ENTITIES.JOBS]: JOB.MINIMUM_ATTRS_TO_MAP,
    [ENTITIES.JOB_TYPES]: JOB_TYPE.MINIMUM_ATTRS_TO_MAP,
    [ENTITIES.QUOTES]: QUOTE.MINIMUM_ATTRS_TO_MAP,
    [ENTITIES.LOCATIONS]: LOCATION.MINIMUM_ATTRS_TO_MAP,
    [ENTITIES.TASKS]: TASK.MINIMUM_ATTRS_TO_MAP,
    [ENTITIES.USERS]: USER.MINIMUM_ATTRS_TO_MAP
};

const entityMinimumMappingEnterprise = {
    [ENTITIES.JOB_HISTORY]: {
        ...JOB_HISTORY.MINIMUM_ATTRS_TO_MAP,
        [MANDATORY_STATUS.ON_CREATION]: [
            ...JOB_HISTORY.MINIMUM_ATTRS_TO_MAP[MANDATORY_STATUS.ON_CREATION],
            ...ENTERPRISE.ACTIVITY_MINIMUM_ATTRS_TO_MAP[MANDATORY_STATUS.ON_CREATION]
        ]
    },
    [ENTITIES.JOBS]: {
        ...JOB.MINIMUM_ATTRS_TO_MAP,
        [MANDATORY_STATUS.ON_CREATION]: [
            ...JOB.MINIMUM_ATTRS_TO_MAP[MANDATORY_STATUS.ON_CREATION],
            ...ENTERPRISE.ACTIVITY_MINIMUM_ATTRS_TO_MAP[MANDATORY_STATUS.ON_CREATION]
        ]
    },
    [ENTITIES.QUOTES]: {
        ...QUOTE.MINIMUM_ATTRS_TO_MAP,
        [MANDATORY_STATUS.ON_CREATION]: [
            ...QUOTE.MINIMUM_ATTRS_TO_MAP[MANDATORY_STATUS.ON_CREATION],
            ...ENTERPRISE.ACTIVITY_MINIMUM_ATTRS_TO_MAP[MANDATORY_STATUS.ON_CREATION]
        ]
    },
    [ENTITIES.USERS]: {
        ...USER.MINIMUM_ATTRS_TO_MAP,
        [MANDATORY_STATUS.ON_CREATION]: [
            ...USER.MINIMUM_ATTRS_TO_MAP[MANDATORY_STATUS.ON_CREATION],
            ...ENTERPRISE.USER_MINIMUM_ATTRS_TO_MAP[MANDATORY_STATUS.ON_CREATION]
        ]
    }
};

export function getMinimumAttributes(entity, isEnterprise) {
    if (isEnterprise && entity in entityMinimumMappingEnterprise) {
        return entityMinimumMappingEnterprise[entity];
    }
    return entityMinimumMapping[entity];
}

/**
 * Compares the mapped @param dataSource array to the array of attributes that are required
 * to create / update a record and returns true if the minimum requirement is met
 *
 * @param {String[]} attributes - array of keys required to create or update a record of a certain entity
 * @param {Object[]} dataSource - array of row objects for the table in the mapping step in the Import Tool workflow
 *
 * @return {Boolean} - if true, the mapped dataSource is valid for running an import
 */
function hasMinimumAttributesForOperation(attributes, dataSource) {
    if (!attributes || !dataSource) throw new Error(`Expected arguments 'attributes' and 'dataSource' to be provided.`);
    // If the minimum attributes is an empty array, the operation (create / update) is not supported for the entity
    return (
        attributes.length &&
        attributes.every(attribute => {
            return dataSource.some(row => row.schemaKey === attribute && row.isMapped);
        })
    );
}

function hasAnyAttributesForOperation(attributes, dataSource) {
    if (!attributes || !dataSource) throw new Error(`Expected arguments 'attributes' and 'dataSource' to be provided.`);
    return (
        attributes.length &&
        attributes.some(attribute => {
            return dataSource.some(row => row.schemaKey === attribute && row.isMapped);
        })
    );
}

/**
 * Determine if the provided @array dataSource matches the minimum required mapping for the provided @param entity
 *
 * @param {String}   entity     - the key of the entity
 * @param {Object[]} dataSource - array of row objects for the table in the mapping step in the Import Tool workflow
 * @param {bool} isEnterprise   - if the org enterprise enabled?
 *
 * @return {Null|String[]} - if the dataSource does NOT meet the minimum required mapping, return the minumum required mapping array. Return null otherwise.
 */
export function getMinimumAttributesIfNotMapped(entity, dataSource, isEnterprise) {
    if (!entity) return null;
    const minimumMappingForEntity = getMinimumAttributes(entity, isEnterprise);
    const hasMinimumAttrsForCreation = hasMinimumAttributesForOperation(
        minimumMappingForEntity[MANDATORY_STATUS.ON_CREATION],
        dataSource
    );
    const hasMinimumAttrsForUpdate = hasMinimumAttributesForOperation(
        minimumMappingForEntity[MANDATORY_STATUS.ON_UPDATE],
        dataSource
    );
    const hasAnyAttrsForUpdateWithAlternateKeys = hasAnyAttributesForOperation(
        minimumMappingForEntity[MANDATORY_STATUS.ALTERNATIVE_ID],
        dataSource
    );

    return hasMinimumAttrsForCreation || hasMinimumAttrsForUpdate || hasAnyAttrsForUpdateWithAlternateKeys
        ? null
        : minimumMappingForEntity;
}

function isSuperset(set, subset) {
    for (let elem of subset) {
        if (!set.has(elem)) {
            return false;
        }
    }
    return true;
}

export function hasMinimumMappedKeys(entity, mappedKeys, isEnterprise = false) {
    if (!entity) return false;
    const minimumMappingForEntity = getMinimumAttributes(entity, isEnterprise);
    const hasMinimumAttrsForCreation = isSuperset(
        new Set(mappedKeys),
        new Set(minimumMappingForEntity[MANDATORY_STATUS.ON_CREATION])
    );
    const hasMinimumAttrsForUpdate = isSuperset(
        new Set(mappedKeys),
        new Set(minimumMappingForEntity[MANDATORY_STATUS.ON_UPDATE])
    );

    return hasMinimumAttrsForCreation || hasMinimumAttrsForUpdate;
}

const TAX_GROUP_SUFFIX = 'group';
const TAX_NAME_SUFFIX = 'name';

export async function getTaxesValidators({ url, token, entity, isWebapp, isEnterprise = false }) {
    validateEntity(entity);
    if (![ENTITIES.CUSTOMERS, ENTITIES.ITEMS, ENTITIES.LOCATIONS, ENTITIES.TASKS].includes(entity)) {
        return {};
    }

    const baseSchema = getBaseSchema(entity, isEnterprise);
    const taxes = await api.getTaxes({ url, token, isWebapp });

    const taxNames = taxes.map(tax => tax.name);
    const taxGroups = taxes.map(tax => tax.group);
    return Object.keys(baseSchema)
        .map(attribute => attribute.toLowerCase())
        .filter(
            attribute =>
                attribute.includes('tax') &&
                (attribute.includes(TAX_NAME_SUFFIX) || attribute.includes(TAX_GROUP_SUFFIX))
        )
        .reduce((taxValidatorsAcc, taxAttribute) => {
            if (taxAttribute.includes(TAX_NAME_SUFFIX)) {
                taxValidatorsAcc[taxAttribute] = validators.getTaxValidator(
                    taxAttribute,
                    taxAttribute.replace(TAX_NAME_SUFFIX, TAX_GROUP_SUFFIX),
                    [...new Set(taxNames)]
                );
            }
            if (taxAttribute.includes(TAX_GROUP_SUFFIX)) {
                taxValidatorsAcc[taxAttribute] = validators.getTaxValidator(
                    taxAttribute,
                    taxAttribute.replace(TAX_GROUP_SUFFIX, TAX_NAME_SUFFIX),
                    [...new Set(taxGroups)]
                );
            }

            return taxValidatorsAcc;
        }, {});
}

export async function getCustomFieldValidators({ url, token, entity, isWebapp }) {
    validateEntity(entity);
    const entityCustomFields = await api.getCustomFields({ url, token, entity, isWebapp });

    const entityCustomFieldsToSchema = entityCustomFields.reduce((cfSchema, { uuid, name, type, options }) => {
        cfSchema[`${CUSTOM_FIELD_PREFIX}${uuid}`] = {
            name: name,
            type: validators.getCustomFieldValidator(type, options)
        };

        return cfSchema;
    }, {});

    return entityCustomFieldsToSchema;
}

export const getEntitySchema = async ({ url, token, entity, isWebapp, isEnterprise, timezone }) => {
    validateEntity(entity);
    setupDatetimeValidators(timezone);
    const baseSchema = getBaseSchema(entity, isEnterprise);
    const taxValidators = await getTaxesValidators({ url, token, entity, isWebapp, isEnterprise });
    const customFieldValidators = await getCustomFieldValidators({ url, token, entity, isWebapp });
    const entitySchema = Object.assign({}, baseSchema, taxValidators, customFieldValidators);

    return entitySchema;
};

export function parseReadableSchema(schema) {
    const customFields = {};
    const baseSchema = Object.keys(schema).reduce((readableSchema, key) => {
        if (getKeyIsCustomField(key)) {
            customFields[getCustomFieldUuidFromKey(key)] = parseDataType(schema[key].type);
        } else if (isNestedDictKey(key)) {
            const [parent, child] = splitNestedKey(key);
            readableSchema[parent] = {
                ...readableSchema[parent],
                [child]: parseDataType(schema[key])
            };
        } else if (isNestedListKey(key)) {
            // Assuming that index 1 is the list index
            // eslint-disable-next-line
            const [parent, _, child, grandChild] = splitNestedKey(key);
            const nestedListElement = {};
            nestedListElement[child] =
                grandChild === undefined ? parseDataType(schema[key]) : { [grandChild]: parseDataType(schema[key]) };
            readableSchema[parent] = [nestedListElement];
        } else {
            readableSchema[key] = parseDataType(schema[key]);
        }
        return readableSchema;
    }, {});

    return Object.keys(customFields).length ? { ...baseSchema, customFields } : baseSchema;
}

export function getDefaultHeaders(entity, isEnterprise = false) {
    let entityDefaultHeaders = defaultHeaders[entity];
    if (isEnterprise && entity in defaultHeadersEnterprise) {
        entityDefaultHeaders = defaultHeadersEnterprise[entity];
    }
    return Object.values(entityDefaultHeaders);
}

export function addNonSchemaAttributes(entity, schema, isUuidLookupEnabled = false) {
    if (isUuidLookupEnabled && entity in NON_SCHEMA_ATTRIBUTES) {
        return {
            ...schema,
            ...NON_SCHEMA_ATTRIBUTES[entity]
        };
    }

    return schema;
}
