import validators from '../validators';
import { MANDATORY_STATUS } from '../constants';
import { COMMON_DATA_KEYS, USER_DATA_KEYS as DATA_KEYS } from './dataKeys';

/**
 * Defines the schema structure and validator functions for the user entity.
 *
 * Nested definitions are flattened for ease of lookup @see schemas/getFlatSchemaFromSchemaDefinition
 */
export const SCHEMA = {
    [COMMON_DATA_KEYS.UUID]: validators.optionalUuid,
    [DATA_KEYS.NAME]: validators.mandatoryStringOnCreation128,
    [DATA_KEYS.SURNAME]: validators.mandatoryStringOnCreation128,
    [DATA_KEYS.EMAIL_ADDRESS]: validators.mandatoryEmailAddressOnCreation,
    [DATA_KEYS.PHONE_NUMBER]: validators.string128,
    [DATA_KEYS.PASSWORD]: validators.mandatoryPasswordOnCreation,
    [DATA_KEYS.LOCALE]: validators.locale,
    [DATA_KEYS.PLATFORM]: validators.platform,
    [DATA_KEYS.TIMEZONE]: validators.timezone,
    [DATA_KEYS.ROLE]: validators.role,
    [DATA_KEYS.ARCHIVED]: validators.yesNo,
    [DATA_KEYS.CAN_BE_SMART_SCHEDULED]: validators.yesNo
};

export const DEFAULT_HEADER_MAPPING = {
    [COMMON_DATA_KEYS.UUID]: 'UUID',
    [DATA_KEYS.EMAIL_ADDRESS]: 'Email',
    [DATA_KEYS.NAME]: 'First Name',
    [DATA_KEYS.SURNAME]: 'Last Name',
    [DATA_KEYS.PHONE_NUMBER]: 'Phone Number',
    [DATA_KEYS.TIMEZONE]: 'Timezone',
    [DATA_KEYS.LOCALE]: 'Language',
    [DATA_KEYS.PLATFORM]: 'Mobile',
    [DATA_KEYS.ARCHIVED]: 'Archive',
    [DATA_KEYS.PASSWORD]: 'Password',
    [DATA_KEYS.ROLE]: 'Role'
};

export const MINIMUM_ATTRS_TO_MAP = {
    [MANDATORY_STATUS.ON_CREATION]: [DATA_KEYS.NAME, DATA_KEYS.SURNAME, DATA_KEYS.EMAIL_ADDRESS, DATA_KEYS.PASSWORD],
    [MANDATORY_STATUS.ON_UPDATE]: [COMMON_DATA_KEYS.UUID],
    [MANDATORY_STATUS.ALTERNATIVE_ID]: [DATA_KEYS.NAME, DATA_KEYS.SURNAME]
};
