import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Divider, Tag } from 'antd';
import { Redirect } from 'react-router-dom';
import 'intl-list-format';
import 'intl-list-format/locale-data/en';

import { FAButton, FADataTable, FAIcon, FASelect, FATooltip } from 'FA_STORYBOOK';
import withTooltip from 'FA_STORYBOOK_HOCS/withTooltip';
import ParsingModal from '../../components/ParsingModal';
import StrongList from '../../components/StrongList';
import { getCustomFieldUuidFromKey, getKeyIsCustomField, parseDataType } from '../../utils';
import { getRequiredFieldsForUuidRequest, getOptionalFieldsForUuidRequest } from '../../formatters';
import { MANDATORY_STATUS } from '../../constants';
import { COLOURS, UNKNOWN_DATA_TYPE } from '../../utils/constants';
import { COMMON_DATA_KEYS } from '../../schemas/dataKeys';
import { getMinimumAttributesIfNotMapped, addNonSchemaAttributes } from '../../schemas';
import { showConfirmModal } from '../../utils/modalInterface';
import { getLabelAndDescription } from '../../validators';

import 'antd/lib/alert/style/index.css';
import 'antd/lib/tag/style/index.css';
import 'antd/lib/divider/style/index.css';
import './Configuration.sass';

const TagWithTooltip = withTooltip(Tag);

const CLASSES = {
    BASE: 'fa-configuration',
    CONTROLS: 'fa-configuration--controls',
    MAPPING_CELL: 'fa-configuration--mapping-cell',
    MAPPING_STATUS: 'fa-configuration--mapping-status'
};

const STYLES = {
    SUCCESS_LARGE: {
        fontSize: '16px',
        color: COLOURS.SUCCESS
    },
    SUCCESS_SMALL: {
        fontSize: '10px',
        color: COLOURS.SUCCESS
    },
    HIDDEN_DIVIDER: {
        opacity: 0
    },
    TOOLTIP: {
        minWidth: '300px'
    }
};

function getKeyIsAlternativeId(entity, key) {
    const alternativeFields = getRequiredFieldsForUuidRequest(entity);
    const optionalAlternativeIdFieldsForEntity = getOptionalFieldsForUuidRequest(entity);
    if (optionalAlternativeIdFieldsForEntity) {
        alternativeFields.push(...optionalAlternativeIdFieldsForEntity);
    }
    return alternativeFields.includes(key);
}

function Configuration({
    // Data
    csvColumnHeaders,
    csvLines,
    entity,
    fileName,
    keyMap,
    schema,
    url,
    token,
    isWebapp,
    isEnterprise,
    isRebrand,
    isUuidLookupEnabled,
    timezone,
    // Functions
    onColumnSelect,
    onParsingComplete,
    onUnmapClick,
    resetMappedColumns
}) {
    const [isParsing, setIsParsing] = useState(false);
    const [isComplete, setIsComplete] = useState(false);

    // If the user navigates back to the Configuration component, we should reset the previous mapping
    useEffect(resetMappedColumns, []);

    const extendedSchema = addNonSchemaAttributes(entity, schema, isUuidLookupEnabled);
    const dataSource = Object.keys(extendedSchema || {}).map(schemaKey => {
        const csvColumnName = keyMap[schemaKey];
        const isCustomField = getKeyIsCustomField(schemaKey);
        const isAlternativeId = getKeyIsAlternativeId(entity, schemaKey);
        const dataType = parseDataType(extendedSchema[schemaKey], isCustomField);

        const baseData = {
            schemaKey,
            csvColumnName,
            isCustomField,
            isAlternativeId,
            dataType,
            isMapped: Boolean(csvColumnName)
        };
        const customFieldData = isCustomField
            ? {
                  uuid: getCustomFieldUuidFromKey(schemaKey),
                  name: schema[schemaKey].name
              }
            : {};
        return {
            ...baseData,
            customFieldData
        };
    });

    const totalRowCount = dataSource.length;
    const unmappedRowCount = dataSource.filter(row => !row.isMapped).length;
    const mappedRowCount = totalRowCount - unmappedRowCount;

    const listFormatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
    const minimumRequiredKeys = getMinimumAttributesIfNotMapped(entity, dataSource, isEnterprise);
    const hasMinimumRequiredMappingForImport = minimumRequiredKeys === null;

    const columns = [
        {
            title: 'CSV Column Name',
            dataIndex: 'csvColumnName',
            key: 'csvColumnName',
            render(value, record) {
                const options = [
                    {
                        uuid: '',
                        value: '',
                        dropdownDisplayValue: <i>Unmapped</i>
                    },
                    ...csvColumnHeaders.map(dataKey => {
                        const isMapped = dataSource.find(item => item.csvColumnName === dataKey);
                        return {
                            uuid: dataKey,
                            value: dataKey,
                            disabled: isMapped,
                            dropdownDisplayValue: (
                                <span>
                                    {isMapped && <FAIcon icon="check" style={STYLES.SUCCESS_SMALL} />}
                                    {'  '}
                                    {dataKey}
                                </span>
                            )
                        };
                    })
                ];
                return (
                    <div className={CLASSES.MAPPING_CELL}>
                        <FASelect
                            options={options}
                            value={record.isMapped ? value : ''}
                            onChange={value => onColumnSelect(record.schemaKey, value)}
                            isRebrand={isRebrand}
                        />
                        <Divider type="vertical" style={STYLES.HIDDEN_DIVIDER} />
                        {value && (
                            <FAButton isRebrand={isRebrand} size="small" onClick={() => onUnmapClick(record.schemaKey)}>
                                Unmap
                            </FAButton>
                        )}
                    </div>
                );
            }
        },
        {
            title: '→',
            dataIndex: 'mappingArrow',
            key: 'mappingArrow',
            width: '70px',
            render(_, { csvColumnName, schemaKey, isMapped }) {
                return isMapped ? (
                    <FATooltip title={`CSV Column "${csvColumnName}" is mapped to "${schemaKey}"`}>
                        <span
                            style={{
                                fontSize: '16px',
                                color: COLOURS.SUCCESS,
                                opacity: 1
                            }}
                        >
                            {'→'}
                        </span>
                    </FATooltip>
                ) : (
                    <FATooltip title={`No CSV Column is mapped to "${schemaKey}"`}>
                        <span style={{ opacity: 0.3 }}>{'→'}</span>
                    </FATooltip>
                );
            }
        },
        {
            title: 'Maps to',
            dataIndex: 'schemaKey',
            key: 'schemaKey',
            width: '100px',
            render(value, { isCustomField, customFieldData }) {
                if (value === COMMON_DATA_KEYS.UUID) {
                    return (
                        <TagWithTooltip
                            message="When the uuid is mapped, the import will update records with a valid uuid value."
                            color="blue"
                        >
                            {value}
                        </TagWithTooltip>
                    );
                }
                return isCustomField ? customFieldData.name : value;
            }
        },
        {
            title: 'Data Type',
            dataIndex: 'dataType',
            key: 'dataType',
            render(value) {
                if (value === UNKNOWN_DATA_TYPE) {
                    return (
                        <TagWithTooltip
                            color="red"
                            message={`No validator was found for this field in the schema for ${entity}`}
                        >
                            No validator
                        </TagWithTooltip>
                    );
                }
                const { displayValue, description } = getLabelAndDescription(value);
                return (
                    <FATooltip title={description}>
                        <Tag>{displayValue}</Tag>
                    </FATooltip>
                );
            }
        },
        {
            title: 'Info',
            dataIndex: 'info',
            key: 'info',
            render(_, { isCustomField, isAlternativeId, customFieldData }) {
                // Render Custom Field indicator if row is a Custom Field
                if (isCustomField) {
                    return (
                        <FATooltip title={`This item is a Custom Field: ${customFieldData.uuid}`}>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    visibility: isCustomField ? 'initial' : 'hidden',
                                    color: isRebrand ? COLOURS.REBRAND : COLOURS.CUSTOM_FIELD
                                }}
                            >
                                CF
                            </span>
                        </FATooltip>
                    );
                }
                if (isAlternativeId) {
                    return (
                        <FATooltip title={'This column can be used as an alternative to a uuid for this entity'}>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    visibility: isUuidLookupEnabled && isAlternativeId ? 'initial' : 'hidden',
                                    color: COLOURS.ALTERNATIVE_ID
                                }}
                            >
                                ID
                            </span>
                        </FATooltip>
                    );
                }
                return '';
            }
        },
        {
            title: 'Status',
            dataIndex: 'isMapped',
            key: 'isMapped',
            render(isMapped, { schemaKey }) {
                return isMapped ? (
                    ''
                ) : (
                    <div className={CLASSES.MAPPING_STATUS}>
                        <FATooltip title={`${schemaKey} is not mapped to a CSV column`}>
                            <FAIcon icon="rounded-warning" />
                        </FATooltip>
                    </div>
                );
            }
        }
    ];

    return (
        <div className={CLASSES.BASE}>
            {isComplete && <Redirect push to={`/import/${entity}/runner`} />}
            {hasMinimumRequiredMappingForImport ? (
                <Alert
                    showIcon
                    type={unmappedRowCount ? 'warning' : 'success'}
                    message={
                        unmappedRowCount
                            ? `${unmappedRowCount} CSV Columns are not mapped`
                            : 'All CSV Columns are successfully mapped'
                    }
                    description={unmappedRowCount ? `You can import data without mapping all columns` : null}
                />
            ) : (
                <Alert
                    showIcon
                    type="error"
                    message="Unable to Parse CSV File because some required mappings are missing"
                    description={
                        <span>
                            One of the following attributes must be mapped to run the import:
                            <ul>
                                {minimumRequiredKeys[MANDATORY_STATUS.ON_CREATION].length ? (
                                    <li>
                                        To create new entries,{' '}
                                        <StrongList
                                            listParts={listFormatter.formatToParts(
                                                minimumRequiredKeys[MANDATORY_STATUS.ON_CREATION]
                                            )}
                                        />{' '}
                                        {minimumRequiredKeys[MANDATORY_STATUS.ON_CREATION].length > 1 ? 'are' : 'is'}{' '}
                                        required.
                                    </li>
                                ) : (
                                    ''
                                )}
                                {minimumRequiredKeys[MANDATORY_STATUS.ON_UPDATE].length ? (
                                    <li>
                                        For updates,{' '}
                                        <StrongList
                                            listParts={listFormatter.formatToParts(
                                                minimumRequiredKeys[MANDATORY_STATUS.ON_UPDATE]
                                            )}
                                        />{' '}
                                        {minimumRequiredKeys[MANDATORY_STATUS.ON_UPDATE].length > 1 ? 'are' : 'is'}{' '}
                                        required.
                                    </li>
                                ) : (
                                    ''
                                )}
                            </ul>
                        </span>
                    }
                />
            )}
            <section className={CLASSES.CONTROLS}>
                <FAButton
                    ghost
                    type="danger"
                    disabled={!mappedRowCount}
                    onClick={() => {
                        showConfirmModal({
                            width: 500,
                            closable: true,
                            title: 'Remove all mappings?',
                            content: (
                                <p>
                                    This action will map all schema keys to an empty value and save this configuration
                                    in local storage until new mappings are defined, or until the stored mappings are
                                    cleared via the menu action <strong>Clear Stored Column Mappings</strong>
                                </p>
                            ),
                            onOk: () => Object.keys(schema).forEach(key => onUnmapClick(key))
                        });
                    }}
                >
                    Unmap All
                </FAButton>
                <FAButton
                    isRebrand={isRebrand}
                    disabled={!hasMinimumRequiredMappingForImport}
                    loading={isParsing}
                    icon={isParsing ? 'loading' : 'file-search'}
                    onClick={() => setIsParsing(true)}
                    type="primary"
                >
                    Parse File
                </FAButton>
            </section>
            <FADataTable
                rowKey="schemaKey"
                columns={columns}
                dataSource={dataSource}
                isCompact={false}
                bordered={false}
                pagination={false}
            />
            <ParsingModal
                url={url}
                token={token}
                fileName={fileName}
                csvLines={csvLines}
                keyMap={keyMap}
                schema={schema}
                entity={entity}
                isWebapp={isWebapp}
                timezone={timezone}
                csvColumnHeaders={csvColumnHeaders}
                isParsing={isParsing}
                onParsingComplete={importData => {
                    setIsParsing(false);
                    onParsingComplete(importData);
                    setIsComplete(true);
                }}
            />
        </div>
    );
}

Configuration.propTypes = {
    // Data
    url: PropTypes.string,
    token: PropTypes.string,
    isWebapp: PropTypes.bool,
    isEnterprise: PropTypes.bool,
    isRebrand: PropTypes.bool,
    isUuidLookupEnabled: PropTypes.bool,
    timezone: PropTypes.string,
    fileName: PropTypes.string,
    csvColumnHeaders: PropTypes.arrayOf(PropTypes.string),
    entity: PropTypes.string,
    entityLabel: PropTypes.string,
    importData: PropTypes.arrayOf(PropTypes.object),
    schema: PropTypes.object,
    keyMap: PropTypes.object,
    // Functions
    onColumnSelect: PropTypes.func.isRequired,
    onParsingComplete: PropTypes.func.isRequired,
    onUnmapClick: PropTypes.func.isRequired,
    resetMappedColumns: PropTypes.func.isRequired
};

export default Configuration;
