import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Popover } from 'antd';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { FAButton, FAIcon, FAModal, FANavMenu, FAOverlineLabel } from 'FA_STORYBOOK';
import Obfuscate from '../Obfuscate';
import { showConfirmModal, showResetModal } from '../../utils/modalInterface';
import version from '../../version';
import logo from '../../logo.svg';
import rebrandLogo from '../../logo_rebrand.svg';
import { MODIFIERS } from '../../utils/constants';

import 'antd/lib/divider/style/index.css';
import 'antd/lib/popover/style/index.css';
import './NavBar.sass';

const CLASSES = {
    BASE: 'fa-nav-bar',
    LOGO: 'fa-nav-bar--logo',
    NAVIGATION: 'fa-nav-bar--navigation',
    NEW_SESSION: 'fa-nav-bar--new-session',
    POPOVER_TRIGGER_ICON: 'fa-nav-bar--popover-trigger-icon',
    USER: 'fa-nav-bar--user',
    USER_POPOVER: 'fa-nav-bar--user-popover',
    USER_DETAILS: 'fa-nav-bar--user-details',
    USER_ICON: 'fa-nav-bar--user-icon',
    USER_NAME: 'fa-nav-bar--user-name',
    BACK_TO_FA: 'fa-nav-bar--back'
};

const NAVIGATION_KEYS = {
    IMPORT: 'import',
    EXPORT: 'export',
    TEMPLATES: 'templates',
    OPTIONS: 'options',
    IMPORT_HISTORY: 'import_history',
    HELP: 'help'
};

function getSelectedKeyForCurrentLocation({ pathname }) {
    // Example pathname: /import/items/upload
    const [, path] = pathname.split('/');
    if (path === NAVIGATION_KEYS.IMPORT || path === NAVIGATION_KEYS.EXPORT) return path;

    if (path.includes(NAVIGATION_KEYS.IMPORT_HISTORY) || path.includes(NAVIGATION_KEYS.HELP)) {
        return `${NAVIGATION_KEYS.OPTIONS}:${path}`;
    }

    return path;
}

function NavBar({
    entity,
    url,
    token,
    email,
    timezone,
    business,
    importHistory,
    isWebapp,
    isRebrand,
    // passed from the withRouter HOC
    location,
    // Functions
    resetState
}) {
    const history = useHistory();
    const [showVersionModal, setShowVersionModal] = useState(false);
    const hasCredentials = Boolean((isWebapp && url) || email);

    return (
        <nav className={CLASSES.BASE}>
            <section className={CLASSES.LOGO}>
                <img
                    src={isRebrand ? rebrandLogo : logo}
                    width="32"
                    height="32"
                    alt="FieldAware Import Tool Logo"
                    onClick={() => setShowVersionModal(true)}
                />
                {!isWebapp && (
                    <span onClick={() => setShowVersionModal(true)}>
                        <span>FieldAware Import Tool</span>
                        <FAOverlineLabel>v{version}</FAOverlineLabel>
                    </span>
                )}
                {isWebapp && url && (
                    <a className={CLASSES.BACK_TO_FA} href="/account" target="_blank">
                        Back to Fieldaware
                    </a>
                )}
            </section>
            {hasCredentials && (
                <section className={CLASSES.NAVIGATION}>
                    <FANavMenu
                        isRebrand={isRebrand}
                        defaultSelectedKey={getSelectedKeyForCurrentLocation(location)}
                        menu={[
                            {
                                key: NAVIGATION_KEYS.IMPORT,
                                label: 'Import',
                                href: '#/import'
                            },
                            {
                                key: NAVIGATION_KEYS.EXPORT,
                                label: 'Export',
                                href: '#/export'
                            },
                            {
                                key: NAVIGATION_KEYS.TEMPLATES,
                                label: 'Templates',
                                href: '#/templates'
                            },
                            {
                                key: NAVIGATION_KEYS.IMPORT_HISTORY,
                                label: `Import History (${importHistory.length})`,
                                href: '#/import_history'
                            },
                            {
                                key: NAVIGATION_KEYS.HELP,
                                label: 'Help',
                                href: '#/help'
                            }
                        ]}
                    />
                    {!isWebapp && (
                        <div className={CLASSES.NEW_SESSION}>
                            <FAButton
                                ghost
                                type="primary"
                                onClick={() => {
                                    if (entity) return showResetModal();
                                    resetState();
                                    history.push('/import');
                                }}
                                isRebrand={isRebrand}
                            >
                                New Session
                            </FAButton>
                        </div>
                    )}
                </section>
            )}
            {!isWebapp && email && (
                <section className={CLASSES.USER}>
                    <Popover
                        placement="bottomRight"
                        title="Session Credentials"
                        overlayStyle={{
                            width: '300px',
                            paddingTop: '0'
                        }}
                        content={
                            <div className={CLASSES.USER_POPOVER}>
                                <FAOverlineLabel>User</FAOverlineLabel>
                                <pre>{email}</pre>
                                <FAOverlineLabel>Business</FAOverlineLabel>
                                <pre>{business}</pre>
                                <FAOverlineLabel>URL</FAOverlineLabel>
                                <pre>{url}</pre>
                                <FAOverlineLabel>Token</FAOverlineLabel>
                                <pre>
                                    <Obfuscate>{token}</Obfuscate>
                                </pre>
                                <FAOverlineLabel>Timezone</FAOverlineLabel>
                                <pre>{timezone}</pre>
                                <Divider />
                                <FAButton
                                    ghost
                                    block
                                    type="danger"
                                    onClick={() => {
                                        showConfirmModal({
                                            width: 500,
                                            closable: true,
                                            title: 'Clear Stored Column Mappings?',
                                            content: (
                                                <p>This action will clear CSV column header mappings for {email}</p>
                                            ),
                                            onOk: () => resetState(true, email)
                                        });
                                    }}
                                >
                                    Clear Stored Column Mappings
                                </FAButton>
                                <div style={{ marginTop: '0.5em' }}>
                                    <FAButton
                                        ghost
                                        block
                                        type="danger"
                                        onClick={() => {
                                            showConfirmModal({
                                                width: 500,
                                                closable: true,
                                                title: 'Log Out of Import Tool?',
                                                content: (
                                                    <p>
                                                        This action will clear stored api url and access token and
                                                        navigate back to log in screen
                                                    </p>
                                                ),
                                                onOk: () => resetState(true, 'credentials')
                                            });
                                        }}
                                    >
                                        Log Out
                                    </FAButton>
                                </div>
                            </div>
                        }
                    >
                        <div className={classNames(CLASSES.USER_DETAILS, { [MODIFIERS.IS_REBRAND]: isRebrand })}>
                            <section className={CLASSES.USER_ICON}>
                                <FAIcon icon="user" />
                            </section>
                            <section className={CLASSES.USER_NAME}>
                                <FAOverlineLabel>{business}</FAOverlineLabel>
                                <small>
                                    <span>{email}</span>
                                </small>
                            </section>
                            <span className={CLASSES.POPOVER_TRIGGER_ICON}>
                                <FAIcon icon="down" />
                            </span>
                        </div>
                    </Popover>
                </section>
            )}
            <FAModal
                width={400}
                visible={showVersionModal}
                title="FieldAware Import Tool"
                centered={false}
                footer={null}
                onCancel={() => setShowVersionModal(false)}
            >
                Version {version}
            </FAModal>
        </nav>
    );
}

NavBar.propTypes = {
    importHistory: PropTypes.arrayOf(PropTypes.object),
    resetState: PropTypes.func.isRequired,
    setSchemaModalIsVisible: PropTypes.func.isRequired,
    setCsvModalIsVisible: PropTypes.func.isRequired
};

export default NavBar;
