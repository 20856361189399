import React from 'react';
import PropTypes from 'prop-types';
import { Result } from 'antd';
import { Link } from 'react-router-dom';

import { FAButton, FAIcon, FAModal, FASpinner } from 'FA_STORYBOOK';
import { pluralise } from '../../utils';
import { IMPORT_RESULT_STATUS } from './constants';

const CUSTOM_IMPORT_RESULT_ICON = {
    [IMPORT_RESULT_STATUS.STOPPED]: (
        <span style={{ fontSize: '6em' }}>
            <FAIcon type="stop" />
        </span>
    )
};

function getResultTitlesFromStatus(status, { totalRequests, successRequests, errorRequests }, { validLines }) {
    return (
        {
            [IMPORT_RESULT_STATUS.SUCCESS]: {
                title: 'Import Completed with No Errors',
                subtitle: `${successRequests}/${totalRequests} API requests were successful`
            },
            [IMPORT_RESULT_STATUS.STOPPED]: {
                title: 'Import Stopped',
                subtitle: `${validLines - successRequests} records were not imported`
            },
            [IMPORT_RESULT_STATUS.WARNING]: {
                title: `Import Completed with ${errorRequests} ${pluralise('Error', errorRequests)}`,
                subtitle: `${successRequests}/${totalRequests} API requests were successful`
            },
            [IMPORT_RESULT_STATUS.ERROR]: {
                title: 'Import Failed',
                subtitle: `${successRequests}/${totalRequests} API requests were successful`
            }
        }[status] || ''
    );
}

function ImportResultModal({
    importDone,
    importMetrics,
    validationMetrics,
    importResultStatus,
    showResult,
    onNewSessionClick,
    setShowImportResult,
    isRebrand,
}) {
    const { title, subtitle } = getResultTitlesFromStatus(importResultStatus, importMetrics, validationMetrics);

    return (
        <FAModal
            className={'import-result-modal'}
            width={'60%'}
            visible={showResult}
            onCancel={() => setShowImportResult(false)}
            closable={false}
            maskClosable={false}
            centered={false}
            footer={null}
        >
            <Result
                status={importResultStatus === IMPORT_RESULT_STATUS.STOPPED ? '' : importResultStatus}
                title={title}
                subTitle={subtitle}
                icon={
                    importResultStatus === IMPORT_RESULT_STATUS.STOPPED ? (
                        CUSTOM_IMPORT_RESULT_ICON[importResultStatus]
                    ) : importDone ? null : (
                        <FASpinner size="large" isGrey />
                    )
                }
                extra={[
                    <FAButton isRebrand={isRebrand} key="new" type="primary" size="large" onClick={() => onNewSessionClick(false)}>
                        New Import Session
                    </FAButton>,
                    <FAButton isRebrand={isRebrand} key="view" size="large" onClick={() => setShowImportResult(false)}>
                        View Complete Report
                    </FAButton>,
                    <Link key="history" to="/import_history">
                        <FAButton isRebrand={isRebrand} size="large">View Import History</FAButton>
                    </Link>
                ]}
            />
        </FAModal>
    );
}

ImportResultModal.propTypes = {
    showResult: PropTypes.bool,
    setShowImportResult: PropTypes.func.isRequired
};

export default ImportResultModal;
