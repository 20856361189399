import * as formatters from '.';
import { LOCATION_DATA_KEYS as DATA_KEYS } from '../schemas/dataKeys';

export const FORMATTERS = {
    [DATA_KEYS.UUID]: formatters.trimString,
    [DATA_KEYS.NAME]: formatters.trimString,
    [`${DATA_KEYS.CUSTOMER}.${DATA_KEYS.UUID}`]: formatters.trimString,
    [DATA_KEYS.USE_LAT_LNG]: formatters.yesNoToBoolean,
    [DATA_KEYS.STREET_NAME]: formatters.trimString,
    [DATA_KEYS.CITY]: formatters.trimString,
    [DATA_KEYS.STATE]: formatters.trimString,
    [DATA_KEYS.ZIP_CODE]: formatters.trimString,
    [DATA_KEYS.COUNTRY]: formatters.trimString,
    [DATA_KEYS.LATITUDE]: formatters.toNumber,
    [DATA_KEYS.LONGITUDE]: formatters.toNumber,
    [DATA_KEYS.TYPE]: formatters.trimString,
    [DATA_KEYS.TAX_NAME]: formatters.trimString,
    [DATA_KEYS.TAX_GROUP]: formatters.trimString,
    [DATA_KEYS.IS_BILLING]: formatters.yesNoToBoolean
};

export function getFormatters() {
    return FORMATTERS;
}

export const REQUIRED_FIELDS_FOR_UUID_REQUEST = [`${DATA_KEYS.CUSTOMER}.${DATA_KEYS.UUID}`, DATA_KEYS.NAME];
export const OPTIONAL_FIELDS_FOR_UUID_REQUEST = [
    DATA_KEYS.STREET_NAME,
    DATA_KEYS.CITY,
    DATA_KEYS.STATE,
    DATA_KEYS.ZIP_CODE,
    DATA_KEYS.COUNTRY
];
