import * as Cookies from 'js-cookie';

export function set (name, value) {
    Cookies.set(name, value, { expires: 7, SameSite: 'Strict' });
}

export function get (name) {
    return Cookies.get(name);
}

export function remove (name) {
    Cookies.remove(name);
}
