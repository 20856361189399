import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useRouteMatch } from 'react-router-dom';

import { FAButton, FASteps, NonBreakingSpace } from 'FA_STORYBOOK';

import { pluralise } from '../../utils';
import { CSV_MODAL_TAB_KEYS } from '../../utils/constants';

import './Progress.sass';

const { Step } = FASteps;

const CLASSES = {
    BASE: 'Progress',
    STEP_DESCRIPTION: 'step-description'
};

const STEP_STATUS = {
    FINISH: 'finish'
};

function Progress({
    // Data
    entityLabel,
    fileName,
    validationMetrics,
    importMetrics,
    importDone,
    isRebrand,
    // Functions
    setSchemaModalIsVisible,
    setCsvModalIsVisible,
    setShowImportResult
}) {
    /**
     * Define the expected routes for each step in order.
     *
     * Routes not matching will be null, while matching routes will be an object that can be
     * read for exact or partial match @see https://reactrouter.com/web/api/Hooks/useroutematch
     */
    const pathnameToStepMap = [
        useRouteMatch('/import'),
        useRouteMatch('/import/:entity'),
        useRouteMatch('/import/:entity/configuration'),
        useRouteMatch('/import/:entity/runner')
    ];

    // If we don't find the route in the steps set the initial step (i.e. 0)
    const currentStep = Math.max(
        pathnameToStepMap.findIndex(match => match && match.isExact),
        0
    );
    const { errorLines } = validationMetrics;

    return (
        <section className={CLASSES.BASE}>
            <FASteps isRebrand={isRebrand} current={currentStep}>
                <Step
                    title="Select Entity"
                    description={
                        entityLabel && (
                            <Fragment>
                                {entityLabel}
                                <NonBreakingSpace />
                                <FAButton
                                    isRebrand={isRebrand}
                                    type="link"
                                    onClick={() => setSchemaModalIsVisible(true)}
                                >
                                    [view schema]
                                </FAButton>
                            </Fragment>
                        )
                    }
                />
                <Step
                    title="Upload CSV File"
                    description={
                        fileName && (
                            <Fragment>
                                {fileName}
                                <NonBreakingSpace />
                                <FAButton isRebrand={isRebrand} type="link" onClick={() => setCsvModalIsVisible(true)}>
                                    [view file]
                                </FAButton>
                            </Fragment>
                        )
                    }
                />
                <Step
                    title="Map CSV Columns"
                    description={
                        validationMetrics.isParsed && (
                            <div
                                className={classNames(CLASSES.STEP_DESCRIPTION, {
                                    'is-error': Boolean(errorLines)
                                })}
                            >
                                {Boolean(errorLines)
                                    ? `${errorLines} ${pluralise('Invalid Line', errorLines)}`
                                    : `No Errors`}
                                <NonBreakingSpace />
                                <FAButton
                                    type="link"
                                    onClick={() => setCsvModalIsVisible(true, CSV_MODAL_TAB_KEYS.VALIDATED_CSV)}
                                    isRebrand={isRebrand}
                                >
                                    [inspect]
                                </FAButton>
                            </div>
                        )
                    }
                />
                <Step
                    title="Run Import"
                    status={importDone ? STEP_STATUS.FINISH : null}
                    description={
                        importDone && (
                            <div
                                className={classNames(CLASSES.STEP_DESCRIPTION, {
                                    'is-error': importMetrics.successRequests === 0,
                                    'is-warning':
                                        importMetrics.successRequests > 0 &&
                                        importMetrics.successRequests < importMetrics.totalRequests,
                                    'is-success': importMetrics.successRequests === importMetrics.totalRequests
                                })}
                            >
                                {importMetrics.successRequests}/{importMetrics.totalRequests} successful
                                <NonBreakingSpace />
                                <FAButton isRebrand={isRebrand} type="link" onClick={() => setShowImportResult(true)}>
                                    [view]
                                </FAButton>
                            </div>
                        )
                    }
                />
            </FASteps>
        </section>
    );
}

Progress.propTypes = {
    validationMetrics: PropTypes.object,
    importMetrics: PropTypes.object,
    entityLabel: PropTypes.string,
    fileName: PropTypes.string,
    importDone: PropTypes.bool,
    isRebrand: PropTypes.bool,
    setSchemaModalIsVisible: PropTypes.func.isRequired,
    setCsvModalIsVisible: PropTypes.func.isRequired,
    setShowImportResult: PropTypes.func.isRequired
};

export default Progress;
