import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FAButton } from 'FA_STORYBOOK';
import PreviewRowValue from '../PreviewRowValue';
import { flatten } from '../../../../utils';
import { getUuidsForData, ENTITY_TO_ENTITY_CLASS } from '../../../../api';
import { COMMON_DATA_KEYS, ENTITY_DATA_KEYS } from '../../../../schemas/dataKeys';

import { getRequiredFieldsForUuidRequest, getOptionalFieldsForUuidRequest } from '../../../../formatters';
import { getLookupFieldKey, updateImportDataWithUuids, updatePreviewDataWithUuids } from '../../helpers';
import { entities } from '../../../../constants';
import { showErrorModal } from '../../../../utils/modalInterface';
import { capitalise } from '../../../../utils';

const FETCH_UUID_PER_ENTITY = {
    [entities.ASSETS]: [entities.CUSTOMERS, entities.CONTACTS, entities.LOCATIONS, entities.ASSETS],
    [entities.CONTACTS]: [entities.CUSTOMERS, entities.CONTACTS],
    [entities.CUSTOMERS]: [entities.CUSTOMERS],
    [entities.ITEMS]: [entities.ITEMS],
    [entities.JOBS]: [
        entities.CUSTOMERS,
        entities.CONTACTS,
        entities.LOCATIONS,
        entities.ASSETS,
        entities.USERS,
        entities.JOB_TYPES
    ],
    [entities.JOB_HISTORY]: [
        entities.CUSTOMERS,
        entities.CONTACTS,
        entities.LOCATIONS,
        entities.ASSETS,
        entities.USERS,
        entities.JOB_TYPES
    ],
    [entities.JOB_TYPES]: [],
    [entities.LOCATIONS]: [entities.CUSTOMERS, entities.LOCATIONS],
    [entities.TASKS]: [entities.TASKS],
    [entities.QUOTES]: [
        entities.CUSTOMERS,
        entities.CONTACTS,
        entities.LOCATIONS,
        entities.ASSETS,
        entities.USERS,
        entities.JOB_TYPES
    ],
    [entities.USERS]: [entities.USERS]
};

const ENTITY_TO_PAYLOAD_ATTRIBUTE = {
    [entities.ASSETS]: ENTITY_DATA_KEYS.ASSET,
    [entities.CONTACTS]: ENTITY_DATA_KEYS.CONTACT,
    [entities.CUSTOMERS]: ENTITY_DATA_KEYS.CUSTOMER,
    [entities.LOCATIONS]: ENTITY_DATA_KEYS.LOCATION,
    [entities.USERS]: ENTITY_DATA_KEYS.JOB_LEAD
};

const FETCH_UUID_STATE_ATTRS = {
    REQUIRED_FIELDS: 'requiredFields',
    LOOKUP_FIELDS: 'lookupFields',
    UUID_IS_MAPPED: 'uuidIsMapped',
    UUIDS_CAN_BE_FETCHED: 'uuidsCanBeFetched'
};

const FETCH_UUID_BUTTONS_ATTRS = {
    DISABLED: 'disabled',
    IS_LOADING: 'isLoading',
    LABEL: 'label'
};

function LookupButton({
    entity,
    hasDataToImport,
    importData,
    allPreviewRows,
    mappedKeys,
    url,
    token,
    isWebapp,
    isRebrand,
    columns,
    onParsingComplete,
    onUuidFetched,
    setColumns,
    setAllPreviewRows
}) {
    const [lookupButtonState, setLookupButtonState] = useState({
        [FETCH_UUID_BUTTONS_ATTRS.DISABLED]: false,
        [FETCH_UUID_BUTTONS_ATTRS.IS_LOADING]: false,
        [FETCH_UUID_BUTTONS_ATTRS.LABEL]: 'Fetch UUIDs'
    });
    const [display, setDisplay] = useState(false);
    const [uuidFetchingState, setUuidFetchingState] = useState({});

    function updateUuidFetchingState() {
        // Setup fetch uuid state for the related entities
        const nextUuidFetchingState = FETCH_UUID_PER_ENTITY[entity].reduce((nextState, entityToFetch) => {
            const requiredFields = getRequiredFieldsForUuidRequest(entityToFetch).map(field =>
                getLookupFieldKey(entity, entityToFetch, field)
            );

            const lookupFields = [
                ...requiredFields,
                ...getOptionalFieldsForUuidRequest(entityToFetch).map(field =>
                    getLookupFieldKey(entity, entityToFetch, field)
                )
            ];

            const isEntityUuidMapped = mappedKeys.includes(
                entity === entityToFetch
                    ? COMMON_DATA_KEYS.UUID
                    : `${ENTITY_TO_PAYLOAD_ATTRIBUTE[entityToFetch]}.${COMMON_DATA_KEYS.UUID}`
            );

            const canEntityUuidsBeFetched = requiredFields.every(field => mappedKeys.includes(field));

            nextState[entityToFetch] = {
                [FETCH_UUID_STATE_ATTRS.REQUIRED_FIELDS]: requiredFields,
                [FETCH_UUID_STATE_ATTRS.LOOKUP_FIELDS]: lookupFields,
                [FETCH_UUID_STATE_ATTRS.UUID_IS_MAPPED]: isEntityUuidMapped,
                [FETCH_UUID_STATE_ATTRS.UUIDS_CAN_BE_FETCHED]: canEntityUuidsBeFetched
            };

            return nextState;
        }, {});

        setUuidFetchingState(nextUuidFetchingState);

        const nextLookupButtonState = {
            ...lookupButtonState,
            [FETCH_UUID_BUTTONS_ATTRS.DISABLED]: !Object.values(nextUuidFetchingState).some(
                entityState => entityState[FETCH_UUID_STATE_ATTRS.UUIDS_CAN_BE_FETCHED]
            )
        };

        setLookupButtonState(nextLookupButtonState);
        setDisplay(hasDataToImport);
    }

    function handleUuidLookupError(fetchedEntity, uuidLookupResponse) {
        // consider a uuidLookup an error if no uuids were found as well
        // as when an actual an actual HTTP error is returned
        const errorContent =
            uuidLookupResponse === -1
                ? `Couldn't retrieve uuids for the ${fetchedEntity} data`
                : Object.keys(uuidLookupResponse).length === 0 ||
                  uuidLookupResponse[ENTITY_TO_ENTITY_CLASS[fetchedEntity]].every(uuid => !Boolean(uuid))
                ? `No uuids for ${fetchedEntity} were found`
                : '';

        if (!errorContent) return null;
        return errorContent;
    }

    /**
     * Given certain attributes for items of a certain entity, fetch the uuids for those items from the API.
     *
     * The method works through the following steps:
     *  1. Build the payload for the API request. This is expected to be a dictionary of the form:
     *      { entityName: [{ attributeName: attributeValue, ... }, ...] }
     *  2. Set the loading state on the corresponding entity fetch button.
     *  3. Send the request to the API.
     *  4. Check the response for errors, displaying an error modal if there are any.
     *  5. If not errors were found, inject the returned uuid values into the import data and preview.
     *  6. Update the overall state with updated data.
     *  7. Remove the loading state from the fetch button, and disable it to avoid more lookups
     *
     * @param {String} entity
     */
    async function fetchUuids() {
        const fetchUuidErrors = [];
        // mappedKeys, but updated within the loop so we can chain uuid requests
        const internalMappedKeys = [...mappedKeys];
        let currentColumns = [...columns];

        for (const entityToLookup in uuidFetchingState) {
            const canEntityUuidsBeFetched = uuidFetchingState[entityToLookup][
                FETCH_UUID_STATE_ATTRS.REQUIRED_FIELDS
            ].every(field => internalMappedKeys.includes(field));
            if (!canEntityUuidsBeFetched) continue;

            const nextLookupButtonStateIsLoading = {
                ...lookupButtonState,
                [FETCH_UUID_BUTTONS_ATTRS.IS_LOADING]: true,
                [FETCH_UUID_BUTTONS_ATTRS.DISABLED]: true,
                [FETCH_UUID_BUTTONS_ATTRS.LABEL]: `Fetching ${entityToLookup} UUIDs...`
            };
            setLookupButtonState(nextLookupButtonStateIsLoading);

            const fetchUuidPayload = importData.map(({ requestPayload }) => {
                const lookupFields = uuidFetchingState[entityToLookup][FETCH_UUID_STATE_ATTRS.LOOKUP_FIELDS];
                const flatRequestPayload = flatten(requestPayload);
                return lookupFields.reduce((uuidLookupPayload, field) => {
                    if (flatRequestPayload && field in flatRequestPayload && flatRequestPayload[field]) {
                        let fieldAfterDot = field.substring(field.indexOf('.') + 1);
                        // turn a field like "customer.uuid" into "customerRef"
                        if (fieldAfterDot === COMMON_DATA_KEYS.UUID) {
                            const fieldBeforeDot = field.substring(0, field.indexOf('.'));
                            fieldAfterDot = `${fieldBeforeDot}Ref`;
                        }
                        uuidLookupPayload[fieldAfterDot] = flatRequestPayload[field];
                    }
                    return uuidLookupPayload;
                }, {});
            });

            const uuidLookup = await getUuidsForData({
                url,
                token,
                entity: entityToLookup,
                isWebapp,
                data: fetchUuidPayload
            });

            // error handling
            const errorModalContent = handleUuidLookupError(entityToLookup, uuidLookup);
            if (errorModalContent !== null) {
                fetchUuidErrors.push(errorModalContent);
                continue;
            }

            // All good, inject the uuids on the importData.payloads, and display the uuid column on the preview
            const dataIndex = getLookupFieldKey(entity, entityToLookup, COMMON_DATA_KEYS.UUID);
            internalMappedKeys.push(dataIndex);

            const uuidColumnHeader = `${capitalise(entityToLookup)} UUID`;
            const nextColumns = [
                {
                    key: dataIndex,
                    dataIndex,
                    csvHeader: uuidColumnHeader,
                    title() {
                        return (
                            <span>
                                <span>{uuidColumnHeader}</span>
                            </span>
                        );
                    },
                    render(value) {
                        return <PreviewRowValue columnKey={dataIndex} previewRowValue={value} />;
                    }
                },
                ...currentColumns
            ];

            setColumns(nextColumns);
            // keep internal state in sync with the App state
            currentColumns = nextColumns;

            onParsingComplete(
                updateImportDataWithUuids(importData, uuidLookup, entity, entityToLookup),
                /* recalculateMetricsFromImportData= */ true
            );
            setAllPreviewRows(updatePreviewDataWithUuids(allPreviewRows, uuidLookup, entity, entityToLookup));
            onUuidFetched(dataIndex);
        }

        const nextLookupButtonStateIsDone = {
            [FETCH_UUID_BUTTONS_ATTRS.DISABLED]: true,
            [FETCH_UUID_BUTTONS_ATTRS.IS_LOADING]: false,
            [FETCH_UUID_BUTTONS_ATTRS.LABEL]: 'Done'
        };

        setLookupButtonState(nextLookupButtonStateIsDone);

        if (fetchUuidErrors.length > 0) {
            showErrorModal({
                title: 'Unable to fetch UUIDs',
                content: fetchUuidErrors.map(line => (
                    <span>
                        {line}
                        <br />
                    </span>
                ))
            });
        }
    }

    useEffect(updateUuidFetchingState, [mappedKeys]);

    return (
        display && (
            <>
                <FAButton
                    key={`fetch-uuids`}
                    disabled={lookupButtonState[FETCH_UUID_BUTTONS_ATTRS.DISABLED]}
                    type={'default'}
                    isRebrand={isRebrand}
                    onClick={() => fetchUuids()}
                    icon={lookupButtonState[FETCH_UUID_BUTTONS_ATTRS.IS_LOADING] ? 'loading' : ''}
                >
                    {lookupButtonState[FETCH_UUID_BUTTONS_ATTRS.LABEL]}
                </FAButton>{' '}
            </>
        )
    );
}

LookupButton.propTypes = {
    entity: PropTypes.string.isRequired,
    mappedKeys: PropTypes.array.isRequired,
    importData: PropTypes.array.isRequired,
    allPreviewRows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    url: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    isWebapp: PropTypes.bool,
    isRebrand: PropTypes.bool,
    onParsingComplete: PropTypes.func.isRequired,
    onUuidFetched: PropTypes.func.isRequired,
    setColumns: PropTypes.func.isRequired,
    setAllPreviewRows: PropTypes.func.isRequired
};

export default LookupButton;
