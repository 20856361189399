import React from 'react';
import PropTypes from 'prop-types';

import { FAModal, FATabs } from 'FA_STORYBOOK';

import CsvFilePreview from '../CsvFilePreview';
import CsvFileSummary from '../CsvFileSummary';
import CsvFileSummaryMessage from '../CsvFileSummaryMessage';
import CsvValidationPreview from '../CsvValidationPreview';
import SchemaViewer from '../SchemaViewer';
import { getApiDocumentationUrl, getAdditionalEntityInfo } from '../../utils';
import { CSV_MODAL_TAB_KEYS } from '../../utils/constants';

import './ModalContainer.sass';

const { TabPane } = FATabs;

function ModalContainer({
    // Data
    fileName,
    csvColumnHeaders,
    csvLines,
    csvFilePreview,
    importData,
    entity,
    entityLabel,
    url,
    token,
    isWebapp,
    schema,
    keyMap,
    mappedKeys,
    schemaModalIsVisible,
    csvModalIsVisible,
    csvModalActiveTabKey,
    validationMetrics,
    hasDataToImport,
    isEnterprise,
    isRebrand,
    isUuidLookupEnabled,
    // Functions
    resetMappedColumns,
    setSchemaModalIsVisible,
    setCsvModalActiveKey,
    setCsvModalIsVisible,
    onParsingComplete,
    onUuidFetched
}) {
    const canViewCsvValidation = validationMetrics.isParsed;

    return (
        <div>
            {/* Entity Modal */}
            {entity && (
                <FAModal
                    className={'entity-modal'}
                    width={'50%'}
                    title={`${entityLabel} Schema`}
                    visible={schemaModalIsVisible}
                    onCancel={() => setSchemaModalIsVisible(false)}
                    centered={false}
                    footer={null}
                >
                    <SchemaViewer
                        entity={entity}
                        schema={schema}
                        isEnterprise={isEnterprise}
                        isRebrand={isRebrand}
                        apiDocumentationUrl={getApiDocumentationUrl(url, entity)}
                        additionalEntityInfo={getAdditionalEntityInfo(entity)}
                    />
                </FAModal>
            )}
            {/* CSV Modal */}
            {fileName && (
                <FAModal
                    className={'csv-modal'}
                    width={'98%'}
                    title={fileName}
                    visible={csvModalIsVisible}
                    onCancel={() => setCsvModalIsVisible(false)}
                    footer={null}
                >
                    <FATabs isRebrand={isRebrand} activeKey={csvModalActiveTabKey} onChange={key => setCsvModalActiveKey(key)}>
                        <TabPane tab="CSV Viewer" key={CSV_MODAL_TAB_KEYS.RAW_CSV}>
                            <CsvFilePreview
                                isRebrand={isRebrand}
                                csvColumnHeaders={csvColumnHeaders}
                                csvFilePreview={csvFilePreview}
                                extra={
                                    <CsvFileSummaryMessage
                                        fileName={fileName}
                                        csvColumnHeaders={csvColumnHeaders}
                                        csvLines={csvLines}
                                    />
                                }
                            />
                        </TabPane>
                        <TabPane
                            tab="Validated CSV Viewer"
                            key={CSV_MODAL_TAB_KEYS.VALIDATED_CSV}
                            disabled={!canViewCsvValidation}
                        >
                            <CsvFileSummary
                                entity={entity}
                                entityLabel={entityLabel}
                                fileName={fileName}
                                csvColumnHeaders={csvColumnHeaders}
                                keyMap={keyMap}
                                csvLines={csvLines}
                                validationMetrics={validationMetrics}
                                resetMappedColumns={resetMappedColumns}
                                setCsvModalIsVisible={setCsvModalIsVisible}
                                showValidation
                            />
                            <CsvValidationPreview
                                isRebrand={isRebrand}
                                entity={entity}
                                csvColumnHeaders={csvColumnHeaders}
                                keyMap={keyMap}
                                mappedKeys={mappedKeys}
                                schema={schema}
                                importData={importData}
                                validationMetrics={validationMetrics}
                                onParsingComplete={onParsingComplete}
                                onUuidFetched={onUuidFetched}
                                hasDataToImport={hasDataToImport}
                                url={url}
                                token={token}
                                isWebapp={isWebapp}
                                isUuidLookupEnabled={isUuidLookupEnabled}
                            />
                        </TabPane>
                    </FATabs>
                </FAModal>
            )}
        </div>
    );
}

ModalContainer.propTypes = {
    csvColumnHeaders: PropTypes.arrayOf(PropTypes.string),
    validationMetrics: PropTypes.object,
    schemaModalIsVisible: PropTypes.bool,
    setSchemaModalIsVisible: PropTypes.func.isRequired,
    setCsvModalActiveKey: PropTypes.func.isRequired,
    setCsvModalIsVisible: PropTypes.func.isRequired,
    onParsingComplete: PropTypes.func.isRequired,
    onUuidFetched: PropTypes.func.isRequired
};

export default ModalContainer;
