import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

import { FAAnchor, FASectionTitle, FATooltip } from 'FA_STORYBOOK';

import AnnotatedJSON from '../AnnotatedJSON';
import { MANDATORY_STATUS } from '../../constants';
import { getMinimumAttributes, parseReadableSchema } from '../../schemas';
import { getLabelAndDescription } from '../../validators';

import 'antd/lib/tag/style/index.css';

function getMandatoryStatus(onCreate, onUpdate) {
    if (!onCreate && !onUpdate) return MANDATORY_STATUS.NEVER;
    if (onCreate && onUpdate) return MANDATORY_STATUS.ALWAYS;

    if (onCreate) return MANDATORY_STATUS.ON_CREATION;
    if (onUpdate) return MANDATORY_STATUS.ON_UPDATE;
}

function getMandatoryStatusColour(mandatoryStatus) {
    return {
        [MANDATORY_STATUS.NEVER]: null,
        [MANDATORY_STATUS.ALWAYS]: 'purple',
        [MANDATORY_STATUS.ON_CREATION]: 'green',
        [MANDATORY_STATUS.ON_UPDATE]: 'blue'
    }[mandatoryStatus];
}

function SchemaViewer({ apiDocumentationUrl, entity, schema, additionalEntityInfo, isEnterprise, isRebrand }) {
    const minimumMappingForEntity = getMinimumAttributes(entity, isEnterprise);
    return (
        <div>
            <p>Below is the payload schema for {entity} on the FieldAware API.</p>
            {apiDocumentationUrl && (
                <p>
                    See complete API documentation for {entity}{' '}
                    <FAAnchor isRebrand={isRebrand} href={apiDocumentationUrl} openInNewTab>
                        here
                    </FAAnchor>
                </p>
            )}
            {additionalEntityInfo && <p>{additionalEntityInfo}</p>}
            {schema && (
                <AnnotatedJSON
                    data={parseReadableSchema(schema)}
                    renderValue={(dataKey, dataValue) => {
                        if (!minimumMappingForEntity) return dataValue;
                        const isMandatoryOnCreate = minimumMappingForEntity[MANDATORY_STATUS.ON_CREATION].includes(
                            dataKey
                        );
                        const isMandatoryOnUpdate = minimumMappingForEntity[MANDATORY_STATUS.ON_UPDATE].includes(
                            dataKey
                        );

                        return (
                            <ValueTag mandatoryStatus={getMandatoryStatus(isMandatoryOnCreate, isMandatoryOnUpdate)}>
                                {dataValue}
                            </ValueTag>
                        );
                    }}
                />
            )}
            <FASectionTitle>Schema Guide</FASectionTitle>
            {schema && (
                <section>
                    <Tag color={getMandatoryStatusColour(MANDATORY_STATUS.NEVER)}>grey</Tag>= attribute is optional
                    <br />
                    <Tag color={getMandatoryStatusColour(MANDATORY_STATUS.ON_CREATION)}>green</Tag>= attribute is
                    mandatory on record creation
                    <br />
                    <Tag color={getMandatoryStatusColour(MANDATORY_STATUS.ON_UPDATE)}>blue</Tag>= attribute mandatory on
                    record update
                    <br />
                    <Tag color={getMandatoryStatusColour(MANDATORY_STATUS.ALWAYS)}>purple</Tag>= attribute mandatory on
                    record creation and record update
                </section>
            )}
        </div>
    );
}

SchemaViewer.propTypes = {
    apiDocumentationUrl: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    schema: PropTypes.object.isRequired
};

function ValueTag({ children: value, mandatoryStatus }) {
    const { displayValue, description } = getLabelAndDescription(value, mandatoryStatus);
    const colour = getMandatoryStatusColour(mandatoryStatus);

    return (
        <FATooltip title={description}>
            <Tag color={colour}>{displayValue}</Tag>
        </FATooltip>
    );
}

export default SchemaViewer;
