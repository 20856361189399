import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Progress } from 'antd';

import { FAButton, FACheckbox, FAIcon, FASectionTitle, FATooltip } from 'FA_STORYBOOK';
import * as exportHistoryLocalStorage from '../../state/exportHistoryLocalStorage';
import { entities as ENTITIES, exportsExcludedEntities, MAX_HISTORY_SIZE } from '../../constants';
import { entityLabels } from '../../schemas';
import { getTotalCountOfEntityForExport, getEntityItemsPage, startWebappExport, WEBAPP_EXPORT_URLS } from '../../api';
import { exportStringToCsv } from '../../utils/csvExporter';
import { showErrorModal, showConfirmModal } from '../../utils/modalInterface';
import { COLOURS, PROGRESS_STATUS } from '../../utils/constants';

import 'antd/lib/divider/style/index.css';
import 'antd/lib/progress/style/index.css';
import './ExportRunner.sass';

const CLASSES = {
    BASE: 'fa-export-runner',
    HISTORY: 'fa-export-runner--history',
    WITH_ARCHIVED: 'fa-export-runner--with-archived',
    WITHOUT_ARCHIVED: 'fa-export-runner--without-archived',
    BUTTON_WIDTH: 'button-width',
    CHECKBOX: 'checkbox-area'
};

const PAGE_SIZE = 100;

function onEmptyExportError(entity) {
    showErrorModal({
        title: 'There is nothing to export',
        content: `There are no ${entity} to export`
    });
}

function onImportInProgress(entity) {
    showConfirmModal({
        width: 500,
        closable: true,
        title: 'Import in Progress',
        content: (
            <main>
                <p>
                    You are already exporting <i>{entity}</i>.
                </p>
                <p>Please wait for this export to finish before exporting another entity.</p>
            </main>
        ),
        cancelButtonProps: {
            style: {
                display: 'none'
            }
        }
    });
}

async function downloadExportFilesAndDownload(
    url,
    token,
    isWebapp,
    entity,
    nItems,
    setExportPercent,
    handleExportError,
    exportArchived
) {
    let linesExported = 0;
    setExportPercent(0);

    let pageSize = PAGE_SIZE;
    let pageIndex = 0;
    let fileCsvColumnHeaders;
    const exportCsvLines = [];

    // Just being extra careful with the comparison, although pageSize should always be <= PAGE_SIZE
    while (pageSize >= PAGE_SIZE) {
        const pageCsvContent = await getEntityItemsPage({
            url,
            token,
            isWebapp,
            entity,
            page: pageIndex,
            pageSize: PAGE_SIZE,
            exportArchived
        });
        const [headers, ...lines] = pageCsvContent.replace(/[\r]/g, '').split('\n');

        if (!fileCsvColumnHeaders) {
            fileCsvColumnHeaders = headers;
        }
        pageIndex++;

        // cleanLines -> lines with contents, excluding new lines
        const cleanLines = lines.filter(item => item);
        pageSize = cleanLines.length;
        linesExported = linesExported + pageSize;
        setExportPercent(Math.round((linesExported * 100) / nItems));
        exportCsvLines.push(...cleanLines);
    }

    if (!fileCsvColumnHeaders) {
        handleExportError(entity);
        return;
    }

    setExportPercent(100);
    exportStringToCsv(`${entity}.csv`, [fileCsvColumnHeaders, ...exportCsvLines].join('\n'));
}

const LARGE_EXPORT_LIMIT = 5000;
async function startAPIExport({
    url,
    token,
    isWebapp,
    entity,
    setShowExportProgressBar,
    setExportPercent,
    handleExportError,
    setExportingKey,
    exportArchived
}) {
    const nItems = await getTotalCountOfEntityForExport({ url, token, entity, isWebapp });
    if (nItems === 0) {
        handleExportError(entity);
    } else if (nItems === -1) {
        showErrorModal({
            title: 'There was an error',
            content: `Something went wrong while trying to export ${entity}`
        });
        handleExportError(entity, false);
    } else if (nItems > LARGE_EXPORT_LIMIT) {
        showConfirmModal({
            width: 500,
            closable: true,
            title: 'Warning',
            content: (
                <p>
                    This action will start a large export ({nItems} {entity} will be included)
                </p>
            ),
            onOk() {
                setShowExportProgressBar(true);
                downloadExportFilesAndDownload(
                    url,
                    token,
                    isWebapp,
                    entity,
                    nItems,
                    setExportPercent,
                    handleExportError,
                    exportArchived
                );
            },
            onCancel() {
                setExportingKey(null);
            }
        });
        return;
    } else {
        setShowExportProgressBar(true);
        downloadExportFilesAndDownload(
            url,
            token,
            isWebapp,
            entity,
            nItems,
            setExportPercent,
            handleExportError,
            exportArchived
        );
    }
}

function finishWebappExport({ key, data, setExportingKey, addExportHistoryItem }) {
    addExportHistoryItem({ key, time: Date.now(), success: true });
    setExportingKey(null);
    exportStringToCsv(`${key}.csv`, data);
}

function ExportRunner({ email, url, token, isWebapp, isRebrand }) {
    const [exportPercent, setExportPercent] = useState(0);
    const [showExportProgressBar, setShowExportProgressBar] = useState(false);
    const [exportingKey, setExportingKey] = useState(null);
    const [exportHistory, setExportHistory] = useState([]);
    const [exportArchivedItems, setExportArchivedItems] = useState(false);
    const [exportArchivedUsers, setExportArchivedUsers] = useState(false);

    const entityItems = Object.values(ENTITIES)
        .filter(entity => !exportsExcludedEntities.includes(entity))
        .map(key => ({
            key,
            label: entityLabels[key]
        }));

    function handleExportComplete() {
        if (exportPercent === 100) {
            const key = exportingKey;
            if (!key) return;
            addExportHistoryItem({
                key,
                time: Date.now(),
                success: true
            });
            setExportingKey(null);
            setTimeout(setShowExportProgressBar(false), 5000);
        }
    }

    function handleExportError(key, showModal = true) {
        if (!key) return;
        if (showModal) onEmptyExportError(key);
        addExportHistoryItem({
            key,
            time: Date.now(),
            success: false
        });
        setExportingKey(null);
        setTimeout(setShowExportProgressBar(false), 5000);
    }

    function addExportHistoryItem(item) {
        const nextExportHistory = item ? [item, ...exportHistory.slice(0, MAX_HISTORY_SIZE - 1)] : [];
        setExportHistory(nextExportHistory);
        exportHistoryLocalStorage.set(email, nextExportHistory);
    }

    function clearExportHistory() {
        setExportHistory([]);
        exportHistoryLocalStorage.clear(email);
    }

    function lookForExportHistory() {
        const history = exportHistoryLocalStorage.get(email);
        if (history) setExportHistory(history);
    }
    useEffect(handleExportComplete, [exportPercent]);
    useEffect(lookForExportHistory, []);

    const shouldExportArchivedMap = {
        [ENTITIES.ITEMS]: {
            get: exportArchivedItems,
            set: setExportArchivedItems
        },
        [ENTITIES.USERS]: {
            get: exportArchivedUsers,
            set: setExportArchivedUsers
        }
    };

    const checkArchivedOption = key => shouldExportArchivedMap.hasOwnProperty(key);

    const getShouldExportArchived = key => shouldExportArchivedMap[key].get;
    const setShouldExportArchived = (key, value) => shouldExportArchivedMap[key].set(value);

    function exportWithCheckbox(key, label) {
        return (
            <span key={key} className={CLASSES.WITH_ARCHIVED}>
                <span className={CLASSES.BUTTON_WIDTH}>
                    <FAButton
                        isRebrand={isRebrand}
                        block
                        loading={exportingKey === key}
                        size="large"
                        key={key}
                        onClick={() => {
                            if (exportingKey) {
                                onImportInProgress(exportingKey);
                                return;
                            }
                            setExportingKey(key);
                            // job types are not supported on the webapp, so use the API instead
                            if (isWebapp && Object.keys(WEBAPP_EXPORT_URLS).includes(key)) {
                                startWebappExport(key, getShouldExportArchived(key), addExportHistoryItem).then(
                                    ({ data }) => {
                                        finishWebappExport({
                                            key,
                                            data,
                                            setExportingKey,
                                            addExportHistoryItem
                                        });
                                    }
                                );
                            } else {
                                startAPIExport({
                                    url,
                                    token,
                                    isWebapp,
                                    entity: key,
                                    setShowExportProgressBar,
                                    setExportPercent,
                                    handleExportError,
                                    setExportingKey,
                                    exportArchived: getShouldExportArchived(key)
                                });
                            }
                        }}
                    >
                        {label}
                    </FAButton>
                </span>
                <span className={CLASSES.CHECKBOX}>
                    <FATooltip title={`Click the checkbox to include archived ${key} in the ${label} export`}>
                        <FACheckbox
                            onChange={value => setShouldExportArchived(key, value.target.checked)}
                            checked={getShouldExportArchived(key)}
                            isRebrand={isRebrand}
                        >
                            {`Include archived ${key}`}
                        </FACheckbox>
                    </FATooltip>
                </span>
            </span>
        );
    }

    function exportWithoutCheckbox(key, label) {
        return (
            <span key={key} className={CLASSES.WITHOUT_ARCHIVED}>
                <FAButton
                    isRebrand={isRebrand}
                    block
                    loading={exportingKey === key}
                    size="large"
                    key={key}
                    onClick={() => {
                        if (exportingKey) {
                            onImportInProgress(exportingKey);
                            return;
                        }
                        setExportingKey(key);
                        // job types are not supported on the webapp, so use the API instead
                        if (isWebapp && Object.keys(WEBAPP_EXPORT_URLS).includes(key)) {
                            // send 'true' as the second argument to get items results unfiltered (with archived)
                            startWebappExport(key, true, addExportHistoryItem).then(({ data }) =>
                                finishWebappExport({
                                    key,
                                    data,
                                    setExportingKey,
                                    addExportHistoryItem
                                })
                            );
                        } else {
                            startAPIExport({
                                url,
                                token,
                                isWebapp,
                                entity: key,
                                setShowExportProgressBar,
                                setExportPercent,
                                handleExportError,
                                setExportingKey,
                                // Just send false as there is no archived filter for this item.
                                exportArchived: false
                            });
                        }
                    }}
                >
                    {label}
                </FAButton>
            </span>
        );
    }

    return (
        <div className={CLASSES.BASE}>
            <FASectionTitle>Click a button to start an entity export</FASectionTitle>
            <p>
                Note that the entities <b>Item</b> and <b>User</b> allow for the inclusion of archived items.
                <br />
                Check the box beside the entity if you would like to include archived items in the export
                <br />
                <br />
            </p>
            {showExportProgressBar && (
                <Progress
                    percent={exportPercent}
                    status={exportPercent === 100 ? PROGRESS_STATUS.SUCCESS : PROGRESS_STATUS.ACTIVE}
                />
            )}
            {entityItems.map(({ key, label }) => {
                const hasArchived = checkArchivedOption(key);
                return hasArchived ? exportWithCheckbox(key, label) : exportWithoutCheckbox(key, label);
            })}
            {Boolean(exportHistory.length) && (
                <section className={CLASSES.HISTORY}>
                    <Divider />
                    <header>
                        <FASectionTitle>Recent Exports ({exportHistory.length})</FASectionTitle>
                        <FAButton
                            type="danger"
                            onClick={() => {
                                showConfirmModal({
                                    width: 500,
                                    closable: true,
                                    title: 'Clear Recent Exports?',
                                    content: (
                                        <p>
                                            This action will remove all entries in Recent Exports. You cannot undo this
                                            action.
                                        </p>
                                    ),
                                    onOk: () => clearExportHistory()
                                });
                            }}
                        >
                            Clear Recent Exports
                        </FAButton>
                    </header>
                    {exportHistory.map(({ key, time, success }, index) => (
                        <p key={`${key}-${index}`}>
                            <span style={{ color: success ? COLOURS.SUCCESS : COLOURS.ERROR }}>
                                <FAIcon icon={success ? 'check' : 'close'} />
                            </span>{' '}
                            {entityLabels[key]} export {success ? 'completed' : 'failed'} -{' '}
                            <small>{new Date(time).toLocaleString()}</small>
                        </p>
                    ))}
                </section>
            )}
        </div>
    );
}

ExportRunner.propTypes = {
    url: PropTypes.string,
    token: PropTypes.string
};

export default ExportRunner;
