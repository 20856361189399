import React from 'react';
import PropTypes from 'prop-types';

import { pluralise } from '../../utils';

function CsvMetric({ name, count }) {
    return (
        <span>
            <strong>{count}</strong> {name ? pluralise(name, count) : ''}
        </span>
    );
}

CsvMetric.propTypes = {
    name: PropTypes.string,
    count: PropTypes.number
};

export default CsvMetric;
