import { ExportToCsv } from 'export-to-csv';
import { isObject } from '../utils';

const csvGenericOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: false,
    showTitle: false,
    title: 'Import Errors',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true
};

export function exportErrorsToCsv(fileName, csvColumnHeaders, importData) {
    const errors = importData.filter(item => item.requestError || item.validationError);
    if (errors.length === 0) {
        alert('No errors to export');
        return;
    }
    // remove new lines from headers
    const headers = csvColumnHeaders.map(key => key.replace(/(\r\n|\n|\r)/gm, ''));
    const errorColumnName = 'Error';
    headers.push(errorColumnName);
    const csvOptions = Object.assign(csvGenericOptions, {
        headers,
        filename: `${fileName.replace('.', '_')}_errors`
    });
    const csvExporter = new ExportToCsv(csvOptions);
    const csvErrorLines = errors.map(({ csvLine, requestError, validationError }) => {
        const baseRowObject = headers.reduce((rowObject, key, index) => {
            rowObject[key] = csvLine[index];
            return rowObject;
        }, {});
        baseRowObject[errorColumnName] = requestError ? requestError?.errorMessage : validationError;
        return baseRowObject;
    });

    csvExporter.generateCsv(csvErrorLines);
}

export function exportParsedDataToCsv(entity, csvColumnHeadersMapping, importData) {
    if (importData.length === 0) {
        alert('No data to export');
        return;
    }

    const csvOptions = Object.assign(csvGenericOptions, {
        title: 'Parsed Data',
        filename: `parsed_${entity}`
    });
    const csvExporter = new ExportToCsv(csvOptions);
    const csvLines = importData.map(csvLine => {
        return Object.keys(csvColumnHeadersMapping).reduce((flatCsvLine, columnKey) => {
            if (!isObject(csvLine[columnKey])) return flatCsvLine;
            // or empty space so we don't export null values as such
            const formattedHeader = csvColumnHeadersMapping[columnKey];
            flatCsvLine[formattedHeader] = csvLine[columnKey].stringValue || '';
            return flatCsvLine;
        }, {});
    });
    csvExporter.generateCsv(csvLines);
}

/**
 * Adapted from the implementation used by export-to-csv,
 * it starts the download of a csv file in the browser given
 * the contents of this file.
 *
 * @param {String} filename - name of the file to be downloaded in the browser
 * @param {String} csvContents - formatted data as CSV
 */
export function exportStringToCsv(filename, csvContents) {
    const blob = new Blob([csvContents], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
    } else {
        let link = document.createElement('a');
        link.href = URL.createObjectURL(blob);

        link.setAttribute('visibility', 'hidden');
        link.download = filename;

        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}
