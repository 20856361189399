import { getKeyMappedHeaders, stripAdditionalFields } from './';
import { applyCombinators } from '../combinators';
import * as Papa from 'papaparse';
const csvtojson = require('csvtojson');

export function getRequestPayloadJson(csvLine, headers, keyMap, formatters = {}, combinators = []) {
    return csvtojson({
        noheader: true,
        // this is how we get nested JSON in the response
        headers: getKeyMappedHeaders(headers, keyMap),
        // this is where the formatting functions are called on each value
        colParser: formatters,
        trim: true,
        ignoreEmpty: true
    })
        .fromString(Papa.unparse([csvLine]))
        .then(([fullLineAsJson]) => applyCombinators(stripAdditionalFields(fullLineAsJson), combinators));
}
