export function write(key, data) {
    window.localStorage.setItem(key, JSON.stringify(data));
}

export function read(key) {
    return JSON.parse(window.localStorage.getItem(key));
}

export function remove(key) {
    window.localStorage.removeItem(key);
}
