export const PREVIEW_ITEM_KEYS = {
    STRING_VALUE: 'stringValue',
    IS_VALID: 'isValid',
    VALIDATION_ERROR_MESSAGE: 'validationErrorMessage'
};

export const CSV_MODAL_TAB_KEYS = {
    RAW_CSV: 'RAW_CSV',
    VALIDATED_CSV: 'VALIDATED_CSV'
};

export const CUSTOM_FIELD_PREFIX = 'customFields.';

export const ROW_INDEX_KEY = '_index';

export const PROGRESS_STATUS = {
    ACTIVE: 'active',
    SUCCESS: 'success'
};

export const UNKNOWN_DATA_TYPE = '__UNKNOWN_DATA_TYPE__';

export const COLOURS = {
    SUCCESS: '#06ba1e',
    PROGRESS: '#108ee9',
    WARNING: 'orange',
    STOPPED: '#333',
    NONE: '#CCC',
    ERROR: 'crimson',
    CUSTOM_FIELD: '#00BEB7',
    REBRAND: '#3589CD',
    ALTERNATIVE_ID: 'blue'
};

// TODO It would be good to import this from FA_STORYBOOK_UTILS, but doing that
// caused the tests to fail.
export const MODIFIERS = {
    IS_REBRAND: 'is-rebrand'
};

export const API_WEBAPP_AUTH_COOKIE = 'api_webapp_auth';
