import * as formatters from '.';
import { COMMON_DATA_KEYS, USER_DATA_KEYS as DATA_KEYS } from '../schemas/dataKeys';

export const FORMATTERS = {
    [COMMON_DATA_KEYS.UUID]: formatters.trimString,
    [DATA_KEYS.NAME]: formatters.trimString,
    [DATA_KEYS.SURNAME]: formatters.trimString,
    [DATA_KEYS.EMAIL_ADDRESS]: formatters.trimString,
    [DATA_KEYS.PHONE_NUMBER]: formatters.trimString,
    [DATA_KEYS.PASSWORD]: formatters.trimString,
    [DATA_KEYS.LOCALE]: formatters.trimString,
    [DATA_KEYS.PLATFORM]: formatters.trimString,
    [DATA_KEYS.TIMEZONE]: formatters.trimString,
    [DATA_KEYS.ROLE]: formatters.trimString,
    [DATA_KEYS.ARCHIVED]: formatters.yesNoToBoolean,
    [DATA_KEYS.CAN_BE_SMART_SCHEDULED]: formatters.yesNoToBoolean
};

export function getFormatters() {
    return FORMATTERS;
}

export const REQUIRED_FIELDS_FOR_UUID_REQUEST = [DATA_KEYS.NAME, DATA_KEYS.SURNAME, DATA_KEYS.EMAIL_ADDRESS];
