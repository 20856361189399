import React from 'react';
import PropTypes from 'prop-types';
import { List, Tag } from 'antd';

import { FAButton, FASectionTitle } from 'FA_STORYBOOK';
import withTooltip from 'FA_STORYBOOK_HOCS/withTooltip';
import { showConfirmModal } from '../../utils/modalInterface';
import { IMPORT_STATUS } from '../../constants';

import 'antd/lib/list/style/index.css';
import 'antd/lib/tag/style/index.css';
import './ImportHistory.sass';

const TagWithTooltip = withTooltip(Tag);

const RESULT_STATUS = {
    SUCCESS: {
        colour: 'green',
        label: 'Complete',
        message: 'All records in CSV were imported successfully'
    },
    PARTIAL: {
        colour: 'orange',
        label: 'Partial',
        message: 'Some records in CSV were imported successfully'
    },
    FAILURE: {
        colour: 'red',
        label: 'Failed',
        message: 'No records were imported successfully'
    },
    STOPPED: {
        colour: '#555',
        label: 'Stopped',
        message: 'The import was stopped before all records were imported'
    }
};

function getResultStatusFromMetrics(importStatus, successRequests, totalCsvLines) {
    const diff = totalCsvLines - successRequests;
    let resultStatus;
    if (importStatus === IMPORT_STATUS.STOPPED) {
        resultStatus = RESULT_STATUS.STOPPED;
    } else if (diff === 0) {
        resultStatus = RESULT_STATUS.SUCCESS;
    } else if (diff === totalCsvLines) {
        resultStatus = RESULT_STATUS.FAILURE;
    } else if (diff >= 1) {
        resultStatus = RESULT_STATUS.PARTIAL;
    }

    return (
        resultStatus && (
            <TagWithTooltip color={resultStatus.colour} message={resultStatus.message}>
                {resultStatus.label}
            </TagWithTooltip>
        )
    );
}

/**
 * Basic duration formatting which will display ms if it's < 1 second, s if it's < 1 min, and min if it's > 1 min
 *
 * Examples:
 *      801ms
 *      12s
 *      29min
 * @param {Number} durationInMs
 *
 * @return {String}
 */
function formatDuration(durationInMs) {
    const formattedValue = durationInMs;
    if (durationInMs < 1000) {
        return `${formattedValue}ms`;
    } else if (durationInMs < 60000) {
        return `${Math.round(formattedValue / 1000)}s`;
    } else {
        return `${Math.round(formattedValue / 60000)}min`;
    }
}

function ImportHistory({ items, clearImportHistory }) {
    return (
        <div className="fa-import-history">
            <header>
                <FASectionTitle>Import History {items.length ? `(${items.length})` : ''}</FASectionTitle>
                <FAButton
                    type="danger"
                    disabled={!items.length}
                    onClick={() => {
                        showConfirmModal({
                            width: 500,
                            closable: true,
                            title: 'Clear Import History?',
                            content: (
                                <p>
                                    This action will remove all existing entries in Import History. You cannot undo this
                                    action.
                                </p>
                            ),
                            onOk: () => clearImportHistory()
                        });
                    }}
                >
                    Clear Import History
                </FAButton>
            </header>
            <main>
                {
                    <List
                        split={false}
                        dataSource={items}
                        locale={{
                            emptyText: 'You have no Import History'
                        }}
                        renderItem={({
                            fileName,
                            entityLabel,
                            importMetrics,
                            importStatus,
                            importTimer,
                            validationMetrics
                        }) => {
                            const startTime = new Date(importTimer.start).toLocaleString();
                            const statusTag = getResultStatusFromMetrics(
                                importStatus,
                                importMetrics.successRequests,
                                validationMetrics.validLines + validationMetrics.errorLines
                            );
                            const duration = formatDuration(importTimer.end - importTimer.start);
                            const importedMetric = `${importMetrics.successRequests}/${validationMetrics.validLines +
                                validationMetrics.errorLines}`;
                            return (
                                <List.Item>
                                    <List.Item.Meta title={fileName} description={startTime} />
                                    <br />
                                    <strong>Entity: </strong>
                                    {entityLabel}
                                    <br />
                                    <strong>Status: </strong>
                                    {statusTag}
                                    <br />
                                    <strong>Duration: </strong>
                                    {duration}
                                    <br />
                                    <strong>Imported: </strong>
                                    {importedMetric}
                                    <br />
                                </List.Item>
                            );
                        }}
                    />
                }
            </main>
        </div>
    );
}

ImportHistory.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    clearImportHistory: PropTypes.func.isRequired
};

export default ImportHistory;
