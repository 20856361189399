import { ENTITY_TO_ENTITY_CLASS } from '../../api';
import { COMMON_DATA_KEYS, ENTITY_DATA_KEYS } from '../../schemas/dataKeys';
import { PREVIEW_ITEM_KEYS } from '../../utils/constants';
import { entities } from '../../constants';

const ENTITY_TO_DATA_KEY_PREFIX = {
    [entities.ASSETS]: ENTITY_DATA_KEYS.ASSET,
    [entities.CUSTOMERS]: ENTITY_DATA_KEYS.CUSTOMER,
    [entities.JOB_TYPES]: ENTITY_DATA_KEYS.JOB_TYPE,
    [entities.LOCATIONS]: ENTITY_DATA_KEYS.LOCATION,
    [entities.CONTACTS]: ENTITY_DATA_KEYS.CONTACT,
    [entities.USERS]: ENTITY_DATA_KEYS.JOB_LEAD
};

export function getLookupFieldKey(currentEntity, entityButton, field) {
    return entityButton === currentEntity || field.includes('.')
        ? field
        : `${ENTITY_TO_DATA_KEY_PREFIX[entityButton]}.${field}`;
}

export function updateImportDataWithUuids(importData, uuidLookup, currentEntity, fetchedEntity) {
    const entityClass = ENTITY_TO_ENTITY_CLASS[fetchedEntity];
    const uuidLookupValues = uuidLookup[entityClass];
    const uuidKey = getLookupFieldKey(currentEntity, fetchedEntity, COMMON_DATA_KEYS.UUID);
    const [baseKey, ...otherKeys] = uuidKey.split('.');
    const updatedImportData = importData.map((rowData, index) => {
        let { requestPayload } = rowData;
        const lookupValue = uuidLookupValues[index];
        if (!lookupValue) return rowData;
        if (otherKeys.length) {
            requestPayload = {
                ...requestPayload,
                [baseKey]: {
                    [otherKeys[0]]: lookupValue
                }
            };
        } else {
            requestPayload[baseKey] = lookupValue;
        }
        const updatedRowData = {
            ...rowData,
            requestPayload
        };
        return updatedRowData;
    });
    return updatedImportData;
}

export function updatePreviewDataWithUuids(previewRows, uuidLookup, currentEntity, fetchedEntity) {
    const entityClass = ENTITY_TO_ENTITY_CLASS[fetchedEntity];
    const uuidLookupValues = uuidLookup[entityClass];
    const uuidKey = getLookupFieldKey(currentEntity, fetchedEntity, COMMON_DATA_KEYS.UUID);
    const updatedPreviewRows = previewRows.map((rowData, index) => {
        rowData[uuidKey] = {
            [PREVIEW_ITEM_KEYS.STRING_VALUE]: uuidLookupValues[index],
            [PREVIEW_ITEM_KEYS.IS_VALID]: true,
            [PREVIEW_ITEM_KEYS.VALIDATION_ERROR_MESSAGE]: ''
        };
        return rowData;
    });
    return updatedPreviewRows;
}
