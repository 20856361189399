import * as formatters from '.';
import { ASSET_DATA_KEYS as DATA_KEYS } from '../schemas/dataKeys';

export const FORMATTERS = {
    [DATA_KEYS.UUID]: formatters.trimString,
    [DATA_KEYS.NAME]: formatters.trimString,
    [DATA_KEYS.DESCRIPTION]: formatters.trimString,
    [DATA_KEYS.MAKE]: formatters.trimString,
    [DATA_KEYS.MODEL]: formatters.trimString,
    [DATA_KEYS.SERIAL_NUMBER]: formatters.trimString,
    [DATA_KEYS.MANUFACTURER]: formatters.trimString,
    [DATA_KEYS.MAINTENANCE_NOTES]: formatters.trimString,
    [DATA_KEYS.MANUFACTURER_NOTES]: formatters.trimString,
    [DATA_KEYS.AREA]: formatters.trimString,
    [`${DATA_KEYS.LOCATION}.${DATA_KEYS.UUID}`]: formatters.trimString,
    [`${DATA_KEYS.CONTACT}.${DATA_KEYS.UUID}`]: formatters.trimString
};

export function getFormatters() {
    return FORMATTERS;
}

export const REQUIRED_FIELDS_FOR_UUID_REQUEST = [DATA_KEYS.NAME];
export const OPTIONAL_FIELDS_FOR_UUID_REQUEST = [DATA_KEYS.SERIAL_NUMBER];
