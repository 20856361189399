import validators from '../validators';
import {
    ASSET_DATA_KEYS as DATA_KEYS,
    COMMON_DATA_KEYS,
    CONTACT_DATA_KEYS,
    CUSTOMER_DATA_KEYS,
    LOCATION_DATA_KEYS
} from './dataKeys';
import { MANDATORY_STATUS } from '../constants';

/**
 * Defines the schema structure and validator functions for the asset entity.
 *
 * Nested definitions are flattened for ease of lookup @see schemas/getFlatSchemaFromSchemaDefinition
 */
export const SCHEMA = {
    [DATA_KEYS.UUID]: validators.optionalUuid,
    [DATA_KEYS.NAME]: validators.mandatoryStringOnCreation128,
    [DATA_KEYS.DESCRIPTION]: validators.string4096,
    [DATA_KEYS.MAKE]: validators.string128,
    [DATA_KEYS.MODEL]: validators.string128,
    [DATA_KEYS.SERIAL_NUMBER]: validators.string128,
    [DATA_KEYS.MANUFACTURER]: validators.string128,
    [DATA_KEYS.MAINTENANCE_NOTES]: validators.string4096,
    [DATA_KEYS.MANUFACTURER_NOTES]: validators.string4096,
    [DATA_KEYS.AREA]: validators.string128,
    [DATA_KEYS.LOCATION]: {
        uuid: validators.mandatoryUuidOnCreation
    },
    [DATA_KEYS.CONTACT]: {
        uuid: validators.optionalUuid
    }
};

export const DEFAULT_HEADER_MAPPING = {
    [DATA_KEYS.UUID]: 'UUID',
    [DATA_KEYS.NAME]: 'Name',
    [DATA_KEYS.DESCRIPTION]: 'Description',
    [DATA_KEYS.MAKE]: 'Make',
    [DATA_KEYS.MODEL]: 'Model',
    [DATA_KEYS.SERIAL_NUMBER]: 'Serial Number',
    [DATA_KEYS.MANUFACTURER]: 'Manufacturer',
    [DATA_KEYS.MANUFACTURER_NOTES]: 'Manufacturer Guidelines',
    [DATA_KEYS.MAINTENANCE_NOTES]: 'Maintenance Notes',
    [DATA_KEYS.AREA]: 'Area',
    [`${DATA_KEYS.LOCATION}.uuid`]: 'Location UUID',
    [`${DATA_KEYS.CONTACT}.uuid`]: 'Contact UUID'
};

export const NON_SCHEMA_ATTRIBUTES = {
    [`${DATA_KEYS.CUSTOMER}.${CUSTOMER_DATA_KEYS.NAME}`]: validators.string128,
    [`${DATA_KEYS.LOCATION}.${LOCATION_DATA_KEYS.NAME}`]: validators.string128,
    [`${DATA_KEYS.LOCATION}.${LOCATION_DATA_KEYS.STREET_NAME}`]: validators.string128,
    [`${DATA_KEYS.LOCATION}.${LOCATION_DATA_KEYS.CITY}`]: validators.string128,
    [`${DATA_KEYS.LOCATION}.${LOCATION_DATA_KEYS.STATE}`]: validators.string128,
    [`${DATA_KEYS.LOCATION}.${LOCATION_DATA_KEYS.ZIP_CODE}`]: validators.string128,
    [`${DATA_KEYS.LOCATION}.${LOCATION_DATA_KEYS.COUNTRY}`]: validators.string128,
    [`${DATA_KEYS.CONTACT}.${CONTACT_DATA_KEYS.NAME}`]: validators.string128,
    [`${DATA_KEYS.CONTACT}.${CONTACT_DATA_KEYS.SURNAME}`]: validators.string128
};

export const MINIMUM_ATTRS_TO_MAP = {
    [MANDATORY_STATUS.ON_CREATION]: [`${DATA_KEYS.LOCATION}.${DATA_KEYS.UUID}`, DATA_KEYS.NAME],
    [MANDATORY_STATUS.ON_UPDATE]: [DATA_KEYS.UUID],
    [MANDATORY_STATUS.ALTERNATIVE_ID]: [
        `${DATA_KEYS.CUSTOMER}.${COMMON_DATA_KEYS.UUID}`,
        `${DATA_KEYS.LOCATION}.${LOCATION_DATA_KEYS.NAME}`,
        `${DATA_KEYS.CONTACT}.${LOCATION_DATA_KEYS.NAME}`,
        DATA_KEYS.NAME
    ]
};
