import * as check from 'check-types';
import moment from 'moment';
import { showErrorModal } from '../utils/modalInterface';

import * as api from '../api';
import { entities } from '../constants';
import { CUSTOM_FIELD_PREFIX } from '../utils/constants';
import * as ASSET from './asset.formatter';
import * as CONTACT from './contact.formatter';
import * as CUSTOMER from './customer.formatter';
import * as ITEM from './item.formatter';
import * as JOB from './job.formatter';
import * as HISTORICAL_JOB from './historicalJob.formatter';
import * as JOB_TYPE from './job_type.formatter';
import * as LOCATION from './location.formatter';
import * as QUOTE from './quote.formatter';
import * as TASK from './task.formatter';
import * as USER from './user.formatter';

/**
 * - Expects a string and outputs a boolean
 * - Will cast non-strings to strings (return value will be false)
 * - Handles null and undefined as special cases
 *
 * Example I/O:
 *      'Yes'       -> true
 *      'No'        -> false
 *      123         -> false
 *      null        -> null
 *      undefined   -> undefined
 *
 * @param {String} value
 *
 * @return {Boolean}
 */
export function yesNoToBoolean(value) {
    if (value === null || value === undefined) return value;
    const valueInLowerCase = String(value).toLowerCase();
    return ['yes', 'true'].includes(valueInLowerCase);
}

/**
 * - Expects a string and outputs a number
 * - Handles null and undefined as special cases
 *
 * Example I/O:
 *      '100'       -> 100
 *      '0'         -> 0
 *      0           -> 0
 *      'foo'       -> NaN
 *      null        -> null
 *      undefined   -> undefined
 *
 * @param {String} value
 *
 * @return {Number}
 */
export function toNumber(value) {
    if (value === null || value === undefined) return value;
    return Number(value);
}

/**
 * - Perform .trim() on provided string value, with some handling of special cases
 *
 * Example I/O:
 *      'hello'     -> 'hello'
 *      ' hello '   -> 'hello'
 *      0           -> '0'
 *      null        -> null
 *      undefined   -> undefined
 *
 * @param {String} value
 *
 * @return {String}
 */
export function trimString(value) {
    if (value === null || value === undefined) return value;
    return String(value).trim();
}

export function setupToISODatetimeFormatter(timezone) {
    return value => {
        if (value === null || value === undefined || typeof value !== 'string') return '';
        const parsedValue = moment.tz(value, timezone);
        return parsedValue.toISOString() || '';
    };
}

/**
 * - Create a Date with the value, then export with the format YYYY-MM-DD
 *
 * Example I/O:
 *      '1975-08-19'     -> '1975-08-19'
 *      ' 1975-08-19 '   -> '1975-08-19'
 *      '1975/08/19'     -> '1975-08-19'
 *      0           -> ''
 *      ''          -> ''
 *      null        -> ''
 *      undefined   -> ''
 *
 * @param {String} value
 *
 * @return {String}
 */
export function toCanonicalDate(value) {
    if (!check.string(value)) return '';

    const valueAsDate = new Date(value);
    if (isNaN(valueAsDate)) return '';
    const year = valueAsDate.getFullYear();
    // Add the leading zero back to the day or month value when required, otherwise it is stripped and the wrong date is used
    const month = String(valueAsDate.getMonth() + 1).padStart(2, '0');
    const day = String(valueAsDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

/**
 * - Create a moment with the value, then export with the format HH:MM:SS
 *
 * Example I/O:
 *      '01:00:00'     -> '01:00:00'
 *      ' 01:00:00 '   -> '01:00:00'
 *      '01:00 AM'     -> '01:00:00'
 *      0           -> ''
 *      ''          -> ''
 *      null        -> ''
 *      undefined   -> ''
 *
 * @param {String} value
 *
 * @return {String}
 */
const VALID_TIME_FORMATS = ['h:m a', 'h:m:s a', 'H:m', 'H:m:s'];

export function toCanonicalTime(value) {
    if (!value || !check.string(value)) return '';

    const valueAsMoment = moment(value.toLowerCase(), VALID_TIME_FORMATS);
    if (!valueAsMoment._isValid) return '';

    return valueAsMoment.format('HH:mm:ss');
}

const CF_TYPE_FORMATTERS = {
    CheckBox: yesNoToBoolean,
    Text: trimString,
    Number: toNumber,
    Date: toCanonicalDate,
    Time: toCanonicalTime,
    Dropdown: trimString
};

export const entityFormatterGetters = {
    [entities.ASSETS]: ASSET.getFormatters,
    [entities.CONTACTS]: CONTACT.getFormatters,
    [entities.CUSTOMERS]: CUSTOMER.getFormatters,
    [entities.ITEMS]: ITEM.getFormatters,
    [entities.JOBS]: JOB.getFormatters,
    [entities.JOB_HISTORY]: HISTORICAL_JOB.getFormatters,
    [entities.JOB_TYPES]: JOB_TYPE.getFormatters,
    [entities.QUOTES]: QUOTE.getFormatters,
    [entities.LOCATIONS]: LOCATION.getFormatters,
    [entities.TASKS]: TASK.getFormatters,
    [entities.USERS]: USER.getFormatters
};

export const entityRequiredFieldsForUuidRequest = {
    [entities.ASSETS]: ASSET.REQUIRED_FIELDS_FOR_UUID_REQUEST,
    [entities.CONTACTS]: CONTACT.REQUIRED_FIELDS_FOR_UUID_REQUEST,
    [entities.CUSTOMERS]: CUSTOMER.REQUIRED_FIELDS_FOR_UUID_REQUEST,
    [entities.ITEMS]: ITEM.REQUIRED_FIELDS_FOR_UUID_REQUEST,
    [entities.JOBS]: [],
    [entities.JOB_HISTORY]: [],
    [entities.QUOTES]: [],
    [entities.JOB_TYPES]: JOB_TYPE.REQUIRED_FIELDS_FOR_UUID_REQUEST,
    [entities.LOCATIONS]: LOCATION.REQUIRED_FIELDS_FOR_UUID_REQUEST,
    [entities.TASKS]: TASK.REQUIRED_FIELDS_FOR_UUID_REQUEST,
    [entities.USERS]: USER.REQUIRED_FIELDS_FOR_UUID_REQUEST
};

export const entityOptionalFieldsForUuidRequest = {
    [entities.ASSETS]: ASSET.OPTIONAL_FIELDS_FOR_UUID_REQUEST,
    [entities.CONTACTS]: CONTACT.OPTIONAL_FIELDS_FOR_UUID_REQUEST,
    [entities.CUSTOMERS]: CUSTOMER.OPTIONAL_FIELDS_FOR_UUID_REQUEST,
    [entities.JOBS]: [],
    [entities.JOB_HISTORY]: [],
    [entities.JOB_TYPES]: [],
    [entities.QUOTES]: [],
    [entities.ITEMS]: [],
    [entities.LOCATIONS]: LOCATION.OPTIONAL_FIELDS_FOR_UUID_REQUEST,
    [entities.TASKS]: [],
    [entities.USERS]: []
};

async function getCustomFieldFormatters(url, token, entity, isWebapp) {
    const entityCustomFields = await api.getCustomFields({ url, token, entity, isWebapp });
    const entityCustomFieldsFormatters = entityCustomFields.reduce((cfFormatters, { uuid, type }) => {
        cfFormatters[`${CUSTOM_FIELD_PREFIX}${uuid}`] = CF_TYPE_FORMATTERS[type];
        return cfFormatters;
    }, {});
    return entityCustomFieldsFormatters;
}

export const getEntityFormatters = async (url, token, entity, isWebapp, timezone) => {
    const toISODatetimeFormatter = setupToISODatetimeFormatter(timezone);
    try {
        const baseFormatters = entityFormatterGetters[entity](toISODatetimeFormatter);
        let customFieldFormatters = {};
        if (entity !== entities.JOB_TYPES) {
            customFieldFormatters = await getCustomFieldFormatters(url, token, entity, isWebapp);
        }
        return Object.assign({}, baseFormatters, customFieldFormatters);
    } catch (error) {
        showErrorModal({
            title: 'Unable to parse CSV file',
            content: `No formatter found for ${entity}`
        });
        return null;
    }
};

// Return copies of each entityRequiredFieldsForUuidRequest[entity] as we don't want to modify the original schema definition
export const getRequiredFieldsForUuidRequest = entity => {
    return [...entityRequiredFieldsForUuidRequest[entity]];
};

export const getOptionalFieldsForUuidRequest = entity => {
    return [...entityOptionalFieldsForUuidRequest[entity]];
};
