import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { FASpinner } from 'FA_STORYBOOK';
import { getEntitySchema, entityLabels, isValidEntity } from '../../schemas';
import { showErrorModal } from '../../utils/modalInterface';

function ImportWorkflow({
    // State
    url,
    token,
    hasEntitySchema,
    isWebapp,
    isEnterprise,
    timezone,
    // Functions
    setSchema,
    // Route renders
    renderEntitySelection,
    renderUploadSchema,
    renderConfiguration,
    renderImportRunner
}) {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                {renderEntitySelection}
            </Route>
            <Route exact path={`${path}/:entity/upload`}>
                {hasEntitySchema ? (
                    renderUploadSchema
                ) : (
                    <SchemaGetter
                        url={url}
                        token={token}
                        setSchema={setSchema}
                        isWebapp={isWebapp}
                        isEnterprise={isEnterprise}
                        timezone={timezone}
                    />
                )}
            </Route>
            <Route path={`${path}/:entity/configuration`}>{renderConfiguration}</Route>
            <Route path={`${path}/:entity/runner`}>{renderImportRunner}</Route>
        </Switch>
    );
}

ImportWorkflow.propTypes = {
    // State
    hasCredentials: PropTypes.bool,
    hasEntitySchema: PropTypes.bool,
    token: PropTypes.string,
    url: PropTypes.string,
    isWebapp: PropTypes.bool,
    isEnterprise: PropTypes.bool,
    timezone: PropTypes.string,
    // Functions
    setSchema: PropTypes.func.isRequired,
    // Route renders
    renderEntitySelection: PropTypes.node,
    renderUploadSchema: PropTypes.node,
    renderConfiguration: PropTypes.node,
    renderImportRunner: PropTypes.node
};

function SchemaGetter({ url, token, setSchema, isWebapp, isEnterprise, timezone }) {
    const history = useHistory();
    const { entity } = useParams();

    function validateEntity() {
        if (url && (token || isWebapp) && isValidEntity(entity)) {
            const label = entityLabels[entity];
            getEntitySchema({ url, token, entity, isWebapp, isEnterprise, timezone })
                .then(schema => setSchema(schema, entity, label))
                .catch(error => {
                    showErrorModal({
                        title: `Could not get schema for ${label}`,
                        content: `${error.message} on ${error.config.method.toUpperCase()} ${error.config.url}`,
                        onOk: () => history.go(-1)
                    });
                });
        }
    }

    useEffect(validateEntity, []);

    return (
        <div
            style={{
                padding: '2em'
            }}
        >
            <FASpinner size="large" isGrey />
        </div>
    );
}

export default ImportWorkflow;
