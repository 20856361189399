import * as formatters from '.';
import { COMMON_DATA_KEYS, ACTIVITY_DATA_KEYS as DATA_KEYS } from '../schemas/dataKeys';

export const FORMATTERS = {
    [COMMON_DATA_KEYS.UUID]: formatters.trimString,
    [DATA_KEYS.DESCRIPTION]: formatters.trimString,
    [DATA_KEYS.ESTIMATED_DURATION]: formatters.toNumber,
    [DATA_KEYS.IS_FIELD_QUOTE]: formatters.yesNoToBoolean,
    [`${DATA_KEYS.LOCATION}.${COMMON_DATA_KEYS.UUID}`]: formatters.trimString,
    [`${DATA_KEYS.CONTACT}.${COMMON_DATA_KEYS.UUID}`]: formatters.trimString,
    [`${DATA_KEYS.ASSET}.${COMMON_DATA_KEYS.UUID}`]: formatters.trimString,
    [`${DATA_KEYS.JOB_LEAD}.${COMMON_DATA_KEYS.UUID}`]: formatters.trimString,
    [`${DATA_KEYS.TASK_GROUP}.${COMMON_DATA_KEYS.UUID}`]: formatters.trimString
};

export function getFormatters(toISODatetimeFormatter) {
    FORMATTERS[DATA_KEYS.SCHEDULED_ON] = toISODatetimeFormatter;
    return FORMATTERS;
}
