import validators from '../validators';
import { MANDATORY_STATUS } from '../constants';
import {
    COMMON_DATA_KEYS,
    ACTIVITY_DATA_KEYS as DATA_KEYS,
    ASSET_DATA_KEYS as ASSET_KEYS,
    CONTACT_DATA_KEYS as CONTACT_KEYS,
    CUSTOMER_DATA_KEYS as CUSTOMER_KEYS,
    JOB_TYPE_DATA_KEYS as JOB_TYPE_KEYS,
    LOCATION_DATA_KEYS as LOCATION_KEYS,
    USER_DATA_KEYS as USER_KEYS
} from './dataKeys';

/**
 * Defines the schema structure and validator functions for jobs.
 *
 * Nested definitions are flattened for ease of lookup @see schemas/getFlatSchemaFromSchemaDefinition
 */
export const SCHEMA = {
    [COMMON_DATA_KEYS.UUID]: validators.optionalUuid,
    [DATA_KEYS.DESCRIPTION]: validators.string4096,
    [DATA_KEYS.SCHEDULED_ON]: validators.isoDate,
    [DATA_KEYS.ESTIMATED_DURATION]: validators.positiveNumber,
    [DATA_KEYS.LOCATION]: {
        [COMMON_DATA_KEYS.UUID]: validators.mandatoryUuidOnCreation
    },
    [DATA_KEYS.CONTACT]: {
        [COMMON_DATA_KEYS.UUID]: validators.optionalUuid
    },
    [DATA_KEYS.ASSET]: {
        [COMMON_DATA_KEYS.UUID]: validators.optionalUuid
    },
    [DATA_KEYS.JOB_LEAD]: {
        [COMMON_DATA_KEYS.UUID]: validators.optionalUuid
    },
    [DATA_KEYS.TIME_WINDOW]: {
        [DATA_KEYS.START_AFTER]: validators.isoDateStartAfter,
        [DATA_KEYS.DEADLINE]: validators.optionalIsoDate
    },
    [DATA_KEYS.JOB_TYPE]: {
        [COMMON_DATA_KEYS.UUID]: validators.optionalUuid
    },
    [DATA_KEYS.TASKS]: [
        {
            [DATA_KEYS.TASK_GROUP]: {
                uuid: validators.optionalUuidOnCreation
            }
        }
    ]
};

export const DEFAULT_HEADER_MAPPING = {
    [COMMON_DATA_KEYS.UUID]: 'UUID',
    [DATA_KEYS.DESCRIPTION]: 'Description',
    [DATA_KEYS.SCHEDULED_ON]: 'Scheduled On',
    [DATA_KEYS.ESTIMATED_DURATION]: 'Estimated Duration',
    [`${DATA_KEYS.LOCATION}.${COMMON_DATA_KEYS.UUID}`]: 'Location UUID',
    [`${DATA_KEYS.CONTACT}.${COMMON_DATA_KEYS.UUID}`]: 'Contact UUID',
    [`${DATA_KEYS.ASSET}.${COMMON_DATA_KEYS.UUID}`]: 'Asset UUID',
    [`${DATA_KEYS.JOB_LEAD}.${COMMON_DATA_KEYS.UUID}`]: 'Job Lead UUID',
    [`${DATA_KEYS.TIME_WINDOW}.${DATA_KEYS.START_AFTER}`]: 'Start After',
    [`${DATA_KEYS.TIME_WINDOW}.${DATA_KEYS.DEADLINE}`]: 'Deadline',
    [`${DATA_KEYS.JOB_TYPE}.${COMMON_DATA_KEYS.UUID}`]: 'Job Type UUID',
    [`${DATA_KEYS.TASKS}.0.${DATA_KEYS.TASK_GROUP}.uuid`]: 'Task Group UUID'
};

export const NON_SCHEMA_ATTRIBUTES = {
    [`${DATA_KEYS.CUSTOMER}.${CUSTOMER_KEYS.NAME}`]: validators.string128,
    [`${DATA_KEYS.LOCATION}.${LOCATION_KEYS.NAME}`]: validators.string128,
    [`${DATA_KEYS.LOCATION}.${LOCATION_KEYS.STREET_NAME}`]: validators.mandatoryStringOnCreationIfNotUseLatLng128,
    [`${DATA_KEYS.LOCATION}.${LOCATION_KEYS.CITY}`]: validators.mandatoryStringOnCreationIfNotUseLatLng128,
    [`${DATA_KEYS.LOCATION}.${LOCATION_KEYS.STATE}`]: validators.string128,
    [`${DATA_KEYS.LOCATION}.${LOCATION_KEYS.ZIP_CODE}`]: validators.string128,
    [`${DATA_KEYS.LOCATION}.${LOCATION_KEYS.COUNTRY}`]: validators.string128,
    [`${DATA_KEYS.CONTACT}.${CONTACT_KEYS.NAME}`]: validators.string128,
    [`${DATA_KEYS.CONTACT}.${CONTACT_KEYS.SURNAME}`]: validators.string128,
    [`${DATA_KEYS.ASSET}.${ASSET_KEYS.NAME}`]: validators.string128,
    [`${DATA_KEYS.ASSET}.${ASSET_KEYS.SERIAL_NUMBER}`]: validators.string128,
    [`${DATA_KEYS.JOB_TYPE}.${JOB_TYPE_KEYS.NAME}`]: validators.string128,
    [`${DATA_KEYS.JOB_LEAD}.${USER_KEYS.EMAIL_ADDRESS}`]: validators.emailAddress,
    [`${DATA_KEYS.JOB_LEAD}.${USER_KEYS.NAME}`]: validators.string128,
    [`${DATA_KEYS.JOB_LEAD}.${USER_KEYS.SURNAME}`]: validators.string128
};

export const MINIMUM_ATTRS_TO_MAP = {
    [MANDATORY_STATUS.ON_CREATION]: [DATA_KEYS.SCHEDULED_ON, `${DATA_KEYS.LOCATION}.${COMMON_DATA_KEYS.UUID}`],
    [MANDATORY_STATUS.ON_UPDATE]: [COMMON_DATA_KEYS.UUID],
    [MANDATORY_STATUS.ALTERNATIVE_ID]: [
        `${DATA_KEYS.CUSTOMER}.${COMMON_DATA_KEYS.UUID}`,
        `${DATA_KEYS.LOCATION}.${LOCATION_KEYS.NAME}`,
        `${DATA_KEYS.ASSET}.${ASSET_KEYS.NAME}`
    ]
};
