export const COMMON_DATA_KEYS = {
    UUID: 'uuid',
    NAME: 'name'
};

// Common keys used in taxable entities like tasks and items
const TAX_DATA_KEYS = {
    GL_ACCOUNT: 'glAccount',
    TAXABLE: 'taxable',
    TAX_1: 'tax1',
    TAX_1_NAME: 'tax1Name',
    TAX_1_GROUP: 'tax1Group',
    TAX_2: 'tax2',
    TAX_2_NAME: 'tax2Name',
    TAX_2_GROUP: 'tax2Group'
};

const PRICING_DATA_KEYS = {
    UNIT_COST: 'unitCost',
    UNIT_PRICE: 'unitPrice',
    MARKUP_TYPE: 'markupType',
    MARKUP_VALUE: 'markupValue'
};

export const ITEM_DATA_KEYS = {
    ...COMMON_DATA_KEYS,
    DESCRIPTION: 'description',
    PART_NUMBER: 'partNumber',
    TRACK_ON_VAN: 'trackOnVan',
    ARCHIVED: 'archived',
    ...PRICING_DATA_KEYS,
    ...TAX_DATA_KEYS
};

export const TASK_DATA_KEYS = {
    ...COMMON_DATA_KEYS,
    DESCRIPTION: 'description',
    MANDATORY: 'mandatory',
    ESTIMATED_DURATION: 'estDuration',
    ...PRICING_DATA_KEYS,
    ...TAX_DATA_KEYS
};

export const LOCATION_DATA_KEYS = {
    ...COMMON_DATA_KEYS,
    CUSTOMER: 'customer',
    USE_LAT_LNG: 'useLatLng',
    STREET_NAME: 'streetName',
    CITY: 'locality',
    STATE: 'region',
    ZIP_CODE: 'postcode',
    COUNTRY: 'country',
    LATITUDE: 'lat',
    LONGITUDE: 'lng',
    TYPE: 'type',
    TAX: 'tax',
    IS_BILLING: 'isBilling'
};

// to be used in user, contact etc.
const PERSON_DATA_KEYS = {
    NAME: 'firstName',
    SURNAME: 'lastName',
    PHONE_NUMBER: 'phone',
    EMAIL_ADDRESS: 'email'
};

export const CONTACT_DATA_KEYS = {
    UUID: COMMON_DATA_KEYS.UUID,
    CUSTOMER: 'customer',
    ...PERSON_DATA_KEYS
};

export const ENTITY_DATA_KEYS = {
    ASSET: 'asset',
    CUSTOMER: 'customer',
    CONTACT: 'contact',
    JOB_TYPE: 'jobType',
    LOCATION: 'location',
    // jobLead = user in the context of jobs
    JOB_LEAD: 'jobLead'
};

export const ASSET_DATA_KEYS = {
    ...COMMON_DATA_KEYS,
    DESCRIPTION: 'description',
    MAKE: 'make',
    MODEL: 'model',
    SERIAL_NUMBER: 'serialNumber',
    MANUFACTURER: 'manufacturer',
    MAINTENANCE_NOTES: 'maintenanceNotes',
    MANUFACTURER_NOTES: 'manufacturerNotes',
    AREA: 'area',
    LOCATION: 'location',
    CONTACT: 'contact',
    CUSTOMER: 'customer'
};

export const CUSTOMER_DATA_KEYS = {
    ...COMMON_DATA_KEYS,
    CUSTOMER_ID: 'customerId',
    PHONE_NUMBER: 'phone',
    EMAIL_ADDRESS: 'email',
    FAX: 'fax',
    WEBSITE: 'website',
    NOTES: 'notes',
    ACCOUNT_REF: 'account',
    DISCOUNT: 'discount',
    GL_ACCOUNT: 'glAccount',
    TAXABLE: 'taxable',
    ON_HOLD: 'onHold',
    LOCATION_NAME: 'location.name',
    LOCATION_USE_LAT_LNG: 'location.useLatLng',
    LOCATION_STREET_NAME: 'location.streetName',
    LOCATION_CITY: 'location.locality',
    LOCATION_STATE: 'location.region',
    LOCATION_ZIP_CODE: 'location.postcode',
    LOCATION_COUNTRY: 'location.country',
    LOCATION_LATITUDE: 'location.lat',
    LOCATION_LONGITUDE: 'location.lng',
    LOCATION_TYPE: 'location.type',
    LOCATION_TAX: 'location.tax',
    LOCATION_IS_BILLING: 'location.isBilling'
};

export const USER_DATA_KEYS = {
    ...PERSON_DATA_KEYS,
    LOCALE: 'locale',
    PLATFORM: 'platform',
    ROLE: 'role',
    TIMEZONE: 'timezone',
    CAN_BE_SMART_SCHEDULED: 'canBeSmartScheduled',
    ARCHIVED: 'archived',
    PASSWORD: 'password'
};

export const ACTIVITY_DATA_KEYS = {
    CUSTOMER: 'customer',
    LOCATION: 'location',
    CONTACT: 'contact',
    ASSET: 'asset',
    DESCRIPTION: 'description',
    SCHEDULED_ON: 'scheduledOn',
    ESTIMATED_DURATION: 'estDuration',
    JOB_LEAD: 'jobLead',
    TASK_GROUP: 'taskGroup',
    TASKS: 'tasks',
    JOB_TYPE: 'jobType',
    IS_FIELD_QUOTE: 'isFieldQuote',
    TIME_WINDOW: 'timeWindow',
    START_AFTER: 'startAfter',
    DEADLINE: 'deadline',
    USER: 'user'
};

export const HISTORICAL_JOB_DATA_KEYS = {
    STARTED_DATETIME: 'startedDatetime',
    COMPLETED_DATETIME: 'completedDatetime'
};

// branch/group related keys
export const GROUP_DATA_KEYS = {
    USER_PRIMARY_GROUP: 'primaryGroup',
    GROUP: 'group'
};

export const JOB_TYPE_DATA_KEYS = {
    ...COMMON_DATA_KEYS
};
