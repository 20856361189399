import React, { Component } from 'react';
import { Alert } from 'antd';
import { HashRouter as Router, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import * as check from 'check-types';
import * as Papa from 'papaparse';

import { FAButton } from 'FA_STORYBOOK';
import { MAX_IMPORT_SIZE } from './constants';
import { getWebappSettings } from './api';
import {
    createSchemaKeyToColumnNameMap,
    parseTotalValidationMetrics,
    recalculateMetrics,
    isWebappDomain
} from './utils';
import { CSV_MODAL_TAB_KEYS, MODIFIERS } from './utils/constants';
import * as cookieInterface from './utils/cookieInterface';
import * as localStorageInterface from './utils/localStorageInterface';
import { showConfirmModal, showErrorModal } from './utils/modalInterface';
import initState, { initImportMetrics, initValidationMetrics } from './state/init';
import { bindUnloadPrompt } from './events';
// State Updaters
import * as importHistoryLocalStorage from './state/importHistoryLocalStorage';
import * as interruptHandlerState from './state/interruptHandler';
import * as importRunnerState from './state/importRunner';

// Pages
import Authentication from './pages/Authentication';
import EntitySelection from './pages/EntitySelection';
import UploadSchema from './pages/UploadSchema';
import Configuration from './pages/Configuration';
import ImportRunner from './pages/ImportRunner';
import ImportWorkflow from './pages/ImportWorkflow';
import ExportRunner from './pages/ExportRunner';
import TemplateGenerator from './pages/TemplateGenerator';
import Help from './pages/Help';
import Loading from './pages/Loading';
import NotFound from './pages/NotFound';
import ImportHistory from './pages/ImportHistory';
// Components
import Progress from './components/Progress';
import NavBar from './components/NavBar';
import ModalContainer from './components/ModalContainer';

import { IMPORT_STATUS } from './pages/ImportRunner/constants';

import 'antd/lib/alert/style/index.css';
import './App.sass';

const NavBarWithRouter = withRouter(NavBar);

class App extends Component {
    state = initState;

    async componentDidMount() {
        const isWebapp = this.setIsWebapp();
        if (isWebapp) await this.getWebappSettingsForImportTool();
        this.lookForLocalStorage();
    }

    componentDidUpdate(_, prevState) {
        const { email, importStatus, importHistory, interruptCount, importMetrics, isRebrand } = this.state;

        // Prevent page closing when running / paused
        if (prevState.importStatus !== importStatus) {
            bindUnloadPrompt(importStatus);
            // When stopped or complete, remove the stored app state from the cache
            if ([IMPORT_STATUS.COMPLETE, IMPORT_STATUS.STOPPED].includes(importStatus)) {
                interruptHandlerState.clearCachedImportStateAndMetrics(email);
                importRunnerState.onFinish.call(this, prevState.importStatus, importStatus);
            }
            // Resume from pause
            if (
                [IMPORT_STATUS.PAUSED, IMPORT_STATUS.INTERRUPTED].includes(prevState.importStatus) &&
                importStatus === IMPORT_STATUS.RUNNING
            ) {
                importRunnerState.startImport.call(this, importMetrics.totalRequests);
            }
        }

        // Store the import history when a new item is added
        const sizeChanged = prevState.importHistory.length < importHistory.length;
        const headChanged =
            prevState.importHistory.length > 0 &&
            prevState.importHistory.length === importHistory.length &&
            prevState.importHistory[0].uuid !== importHistory[0].uuid;
        if (sizeChanged || headChanged) {
            importHistoryLocalStorage.set(email, importHistory);
        }

        // Show the interrupt modal if there are unimported records from the previous import run
        if (email && prevState.interruptCount < interruptCount) {
            interruptHandlerState.showModal.call(this, interruptCount);
        }

        // Doing this to be able to rebrand modals created with showModalConfirm
        if (isRebrand !== prevState.isRebrand) {
            window.document.body.classList.toggle(MODIFIERS.IS_REBRAND, isRebrand);
        }
    }

    lookForLocalStorage() {
        const cookie = cookieInterface.get('credentials');
        const credentials = cookie && JSON.parse(cookie);

        if (credentials && credentials.url && credentials.token) {
            this.storeCredentials(credentials, false);
        } else {
            return;
        }

        this.lookForUserDefinedKeyMap(credentials.email);
        this.lookForImportHistory(credentials.email);
    }

    lookForImportHistory(email) {
        const importHistory = importHistoryLocalStorage.get(email);
        const remainingRequestCount = interruptHandlerState.getRemainingRequestsFromCachedImport(email);

        this.setState(state => ({
            ...state,
            importHistory,
            interruptCount: Math.max(remainingRequestCount, 0)
        }));
    }

    lookForUserDefinedKeyMap(email) {
        const userDefinedKeyMap = localStorageInterface.read(email);
        if (userDefinedKeyMap && check.nonEmptyObject(userDefinedKeyMap)) {
            this.resetUserDefinedKeyMap(userDefinedKeyMap, false);
        }
    }

    storeCredentials(
        { url, token, email, business, isEnterprise = false, isRebrand = false, isUuidLookupEnabled = false, timezone },
        _writeToStorage = true
    ) {
        this.setState(state => ({
            ...state,
            url,
            token,
            email,
            business,
            timezone,
            isEnterprise,
            isRebrand,
            isUuidLookupEnabled
        }));
        if (_writeToStorage) {
            cookieInterface.set(
                'credentials',
                JSON.stringify({ url, token, email, business, timezone, isEnterprise, isUuidLookupEnabled, isRebrand })
            );
            this.lookForUserDefinedKeyMap(email);
            this.lookForImportHistory(email);
        }
    }

    setSchema(schema, entity, entityLabel) {
        this.setState(state => ({
            ...state,
            schema,
            entity,
            entityLabel
        }));
    }

    setSchemaModalIsVisible(value) {
        this.setState(state => ({
            ...state,
            schemaModalIsVisible: value
        }));
    }

    onFileRead(fileName, reader) {
        const parsedCsvContents = Papa.parse(reader, { header: true, skipEmptyLines: true });
        // Get the key/value pairs from Papa parse, then flatten to value arrays for custom mapping use
        const csvColumnHeaders = parsedCsvContents.meta?.fields;

        if (!csvColumnHeaders || csvColumnHeaders.length === 0) {
            showErrorModal({
                title: 'Error with File',
                content: 'The file uploaded is empty or is not a valid CSV'
            });
            return;
        }

        const keyValueLines = parsedCsvContents.data;
        const csvLinesAsArray = keyValueLines.map(line => Object.values(line));

        if (csvLinesAsArray.length > MAX_IMPORT_SIZE) {
            const modal = showConfirmModal({
                width: 500,
                title: `Upload Large CSV File? [${csvLinesAsArray.length} records]`,
                content: (
                    <div>
                        <p>
                            The CSV file <i>{fileName}</i> is larger than the recommended size of {MAX_IMPORT_SIZE}{' '}
                            records.
                        </p>
                        <p>You can import the file with the Import Tool, but the application may be slow.</p>
                        <p>
                            Use <strong>OK</strong> to continue with the import, or <strong>Cancel</strong> to go back
                            and upload a different CSV file
                        </p>
                    </div>
                ),
                onCancel: () => {
                    window.location.hash = `#/import/${this.state.entity}/upload`;
                    modal.destroy();
                }
            });
        }

        this.setState(state => {
            const { keyMap, mappedKeys } = createSchemaKeyToColumnNameMap({
                entity: state.entity,
                schema: state.schema,
                csvColumnHeaders,
                userDefinedKeyMap: state.userDefinedKeyMap[state.entity]
            });

            return {
                ...state,
                fileName,
                csvColumnHeaders,
                keyMap,
                mappedKeys,
                csvLines: csvLinesAsArray,
                csvFilePreview: keyValueLines
            };
        });
    }

    resetFileState() {
        this.setState(state => ({
            ...state,
            fileName: '',
            csvColumnHeaders: [],
            importData: [],
            csvLines: [],
            csvFilePreview: [],
            keyMap: {}
        }));
    }

    setCsvModalActiveKey(value) {
        this.setState(state => ({
            ...state,
            csvModalActiveTabKey: value
        }));
    }

    setCsvModalIsVisible(value, csvModalActiveTabKey = CSV_MODAL_TAB_KEYS.RAW_CSV) {
        this.setState(state => ({
            ...state,
            csvModalIsVisible: value,
            csvModalActiveTabKey
        }));
    }

    onParsingComplete(importData, recalculateMetricsFromImportData = false) {
        // Handle an error in parsing without crashing the app
        if (!importData) return;
        const importDataWithUpdatedMetrics = recalculateMetricsFromImportData
            ? recalculateMetrics(importData)
            : [...importData];
        const validationMetrics = parseTotalValidationMetrics(importDataWithUpdatedMetrics);
        const targetRequests = validationMetrics.validLines;

        this.setState(state => ({
            ...state,
            importData: importDataWithUpdatedMetrics,
            validationMetrics,
            importMetrics: {
                ...state.importMetrics,
                targetRequests
            }
        }));
    }

    /**
     * This method corresponds with going back to the mapping step (Configuration)
     *
     * Preserve the current mapping so the user can make changes and validate again with different column mapping
     */
    resetMappedColumns() {
        this.setState(state => ({
            ...state,
            importData: [],
            importDone: false,
            validationMetrics: { ...initValidationMetrics },
            importMetrics: { ...initImportMetrics }
        }));
    }

    // User-defined mapping of a schema key to a column name
    onColumnSelect(key, value) {
        this.setUserDefinedKeyMap(key, value);
    }

    onUnmapClick(key) {
        this.setUserDefinedKeyMap(key, '');
    }

    setUserDefinedKeyMap(key, value, _writeToStorage = true) {
        this.setState(state => {
            const entity = state.entity;
            const nextUserDefinedKeyMap = {
                ...state.userDefinedKeyMap,
                ...{
                    [entity]: {
                        ...(state.userDefinedKeyMap[entity] || {}),
                        ...{ [key]: value }
                    }
                }
            };

            const nextKeyMap = {
                ...state.keyMap,
                ...nextUserDefinedKeyMap[entity]
            };

            const nextMappedKeys = Object.keys(nextKeyMap).filter(key => Boolean(nextKeyMap[key]));

            const nextState = {
                ...state,
                userDefinedKeyMap: nextUserDefinedKeyMap,
                keyMap: nextKeyMap,
                mappedKeys: nextMappedKeys
            };

            if (_writeToStorage) localStorageInterface.write(state.email, nextState.userDefinedKeyMap);
            return nextState;
        });
    }

    resetUserDefinedKeyMap(userDefinedKeyMap, _writeToStorage = true) {
        this.setState(state => {
            const nextState = {
                ...state,
                userDefinedKeyMap,
                mappedKeys: []
            };
            if (_writeToStorage) localStorageInterface.write(state.email, nextState.userDefinedKeyMap);
            return nextState;
        });
    }

    addKeyToMappedKeys(key) {
        this.setState(state => {
            const nextMappedKeys = [...state.mappedKeys];
            if (!nextMappedKeys.includes(key)) {
                nextMappedKeys.push(key);
            }

            return {
                ...state,
                ...{
                    mappedKeys: nextMappedKeys
                }
            };
        });
    }

    setShowImportResult(showImportResult) {
        this.setState(state => ({
            ...state,
            showImportResult
        }));
    }

    resetState(deleteStorage = false, key) {
        const { isWebapp, isEnterprise, isRebrand, isUuidLookupEnabled, timezone, url } = this.state;
        const nextResetState = isWebapp
            ? {
                  ...initState,
                  isEnterprise,
                  isRebrand,
                  isUuidLookupEnabled,
                  timezone,
                  url
              }
            : {
                  ...initState,
                  isWebapp: false
              };
        this.setState(() => nextResetState);

        if (deleteStorage) {
            localStorageInterface.remove(key);
            cookieInterface.remove(key);
        }
        this.lookForLocalStorage();
    }

    getStoredImportHistory(email) {
        return localStorageInterface.read(`${email}_import_history`) || [];
    }

    setIsWebapp() {
        const isWebapp = isWebappDomain();
        this.setState(state => ({
            ...state,
            isWebapp
        }));
        return isWebapp;
    }

    /**
     * Fetches from the hosting webapp instance the API url the import tool
     * should point to. A prerequisite to call this method is that we've detected
     * the import tool is actually loaded from one webapp instance.
     *
     * A successful call should result in the state storing the API url.
     */
    async getWebappSettingsForImportTool() {
        getWebappSettings()
            .then(response => {
                // if the user is not logged in the webapp, we see a redirection to an html page instead of json in the response
                const hasWebappCredentials = response.headers['content-type'].includes('application/json');
                const apiUrl = hasWebappCredentials ? response.data.apiUrl : '';
                const isEnterprise = hasWebappCredentials ? response.data.isEnterprise : false;
                const isRebrand = hasWebappCredentials ? response.data.isRebrand : false;
                const isItemArchivalEnabled = hasWebappCredentials ? response.data.isItemArchivalEnabled : false;
                const isUuidLookupEnabled = hasWebappCredentials ? response.data.uuidLookup : false;
                const timezone = hasWebappCredentials ? response.data.timezone : '';
                this.setState(state => ({
                    ...state,
                    url: apiUrl,
                    timezone,
                    isEnterprise,
                    isRebrand,
                    isItemArchivalEnabled,
                    isUuidLookupEnabled,
                    isWebappLoading: Boolean(apiUrl)
                }));
            })
            .catch(() => {
                this.setState(state => ({
                    ...state,
                    url: '',
                    timezone: null,
                    isEnterprise: false,
                    isRebrand: false,
                    isItemArchivalEnabled: false,
                    isUuidLookupEnabled: false,
                    isWebappLoading: false
                }));
            });
    }

    render() {
        const hasCredentials = Boolean((this.state.isWebapp && this.state.url) || (this.state.url && this.state.token));
        const hasEntitySchema = Boolean(this.state.entity && this.state.schema);
        const hasCsvData = Boolean(this.state.csvColumnHeaders.length && this.state.fileName);
        const isParsed = this.state.validationMetrics.isParsed;
        const hasDataToImport = isParsed && Boolean(this.state.importMetrics.targetRequests);

        const ROUTES_WITH_NO_PROGRESS_UI = ['/export', '/help', '/import_history', '/templates'];
        return (
            <Router>
                {hasCredentials ? (
                    <Redirect to="/import" />
                ) : (
                    <Redirect
                        to={{
                            pathname: this.state.isWebapp
                                ? this.state.isWebappLoading
                                    ? '/loading'
                                    : '/404'
                                : '/auth',
                            state: {
                                from: {
                                    pathname: window.location.hash.replace('#', '')
                                }
                            }
                        }}
                    />
                )}
                <NavBarWithRouter
                    {...this.state}
                    resetState={this.resetState.bind(this)}
                    setSchemaModalIsVisible={this.setSchemaModalIsVisible.bind(this)}
                    setCsvModalIsVisible={this.setCsvModalIsVisible.bind(this)}
                />
                <ModalContainer
                    {...this.state}
                    resetMappedColumns={this.resetMappedColumns.bind(this)}
                    setSchemaModalIsVisible={this.setSchemaModalIsVisible.bind(this)}
                    setCsvModalIsVisible={this.setCsvModalIsVisible.bind(this)}
                    setCsvModalActiveKey={this.setCsvModalActiveKey.bind(this)}
                    onParsingComplete={this.onParsingComplete.bind(this)}
                    onUuidFetched={this.addKeyToMappedKeys.bind(this)}
                    hasDataToImport={hasDataToImport}
                />
                <div className="fa-import-tool">
                    {hasCredentials && (
                        <Route
                            // don't show the Progress UI on these routes
                            render={({ location }) =>
                                ROUTES_WITH_NO_PROGRESS_UI.includes(location.pathname) ? null : (
                                    <Progress
                                        {...this.state}
                                        setSchemaModalIsVisible={this.setSchemaModalIsVisible.bind(this)}
                                        setCsvModalIsVisible={this.setCsvModalIsVisible.bind(this)}
                                        setShowImportResult={this.setShowImportResult.bind(this)}
                                    />
                                )
                            }
                        />
                    )}
                    <Switch>
                        <PrivateRoute hasCredentials={hasCredentials} {...this.state} path="/import">
                            <ImportWorkflow
                                {...this.state}
                                hasEntitySchema={hasEntitySchema}
                                setSchema={(schema, entity, entityLabel) => this.setSchema(schema, entity, entityLabel)}
                                renderEntitySelection={
                                    <EntitySelection
                                        entity={this.state.entity}
                                        importHistory={this.state.importHistory}
                                        resetState={this.resetState.bind(this)}
                                        isRebrand={this.state.isRebrand}
                                    />
                                }
                                renderUploadSchema={
                                    <UploadSchema
                                        {...this.state}
                                        hasCsvData={hasCsvData}
                                        onFileRead={(fileName, reader) => this.onFileRead(fileName, reader)}
                                        resetFileState={this.resetFileState.bind(this)}
                                    />
                                }
                                renderConfiguration={
                                    <Configuration
                                        {...this.state}
                                        isParsed={isParsed}
                                        onColumnSelect={(key, value) => this.onColumnSelect(key, value)}
                                        onParsingComplete={this.onParsingComplete.bind(this)}
                                        onUnmapClick={key => this.onUnmapClick(key)}
                                        resetMappedColumns={this.resetMappedColumns.bind(this)}
                                    />
                                }
                                renderImportRunner={
                                    hasDataToImport ? (
                                        <ImportRunner
                                            {...this.state}
                                            onStart={() => importRunnerState.initialiseImport.call(this)}
                                            onPause={() => importRunnerState.pauseImport.call(this)}
                                            onResume={() => importRunnerState.resumeImport.call(this)}
                                            onStop={() =>
                                                importRunnerState.stopImport.call(this, IMPORT_STATUS.STOPPED)
                                            }
                                            onResponse={response => this.addResponse(response)}
                                            onError={error => this.addError(error)}
                                            resetState={this.resetState.bind(this)}
                                            setShowImportResult={this.setShowImportResult.bind(this)}
                                            setCsvModalIsVisible={this.setCsvModalIsVisible.bind(this)}
                                            isEnterprise={this.state.isEnterprise}
                                            isUuidLookupEnabled={this.state.isUuidLookupEnabled}
                                        />
                                    ) : (
                                        <Alert
                                            showIcon
                                            type="error"
                                            message="Nothing to Import"
                                            description={
                                                <p>
                                                    Based on the mapping of schema keys to CSV columns, all lines in the
                                                    uploaded CSV file have validation errors and so there are no lines
                                                    to import.
                                                    <br />
                                                    <br />
                                                    <FAButton
                                                        ghost
                                                        block
                                                        type="danger"
                                                        onClick={this.resetMappedColumns.bind(this)}
                                                    >
                                                        Go back to CSV Column Mapping
                                                    </FAButton>
                                                    {!isParsed && (
                                                        <Redirect
                                                            push
                                                            to={`/import/${this.state.entity}/configuration`}
                                                        />
                                                    )}
                                                </p>
                                            }
                                        />
                                    )
                                }
                            />
                        </PrivateRoute>
                        <PrivateRoute hasCredentials={hasCredentials} path="/export">
                            <ExportRunner {...this.state} />
                        </PrivateRoute>
                        <PrivateRoute hasCredentials={hasCredentials} path="/templates">
                            <TemplateGenerator {...this.state} />
                        </PrivateRoute>
                        <PrivateRoute hasCredentials={hasCredentials} path="/import_history">
                            <ImportHistory
                                items={this.state.importHistory}
                                clearImportHistory={() => {
                                    this.setState(state => ({
                                        ...state,
                                        importHistory: []
                                    }));
                                    importHistoryLocalStorage.clear(this.state.email);
                                }}
                            />
                        </PrivateRoute>
                        <Route path="/help">
                            <Help
                                hasCredentials={hasCredentials}
                                isWebapp={this.state.isWebapp}
                                isRebrand={this.state.isRebrand}
                            />
                        </Route>
                        <Route path="/loading">
                            <Loading />
                        </Route>
                        <Route path="/404">
                            <NotFound isRebrand={this.state.isRebrand} />
                        </Route>
                        {/* Home route must come last */}
                        <Route path="/auth">
                            <Authentication {...this.state} onAuthSuccess={data => this.storeCredentials(data)} />
                        </Route>
                        <PrivateRoute hasCredentials={hasCredentials} {...this.state} path="*">
                            404
                        </PrivateRoute>
                    </Switch>
                </div>
            </Router>
        );
    }
}

function PrivateRoute({ children, hasCredentials, isWebapp, isWebappLoading, ...rest }) {
    return (
        <Route
            {...rest}
            render={() =>
                hasCredentials ? (
                    children
                ) : isWebapp ? (
                    <Redirect
                        to={{
                            pathname: isWebappLoading ? '/404' : '/loading'
                        }}
                    />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/auth'
                        }}
                    />
                )
            }
        />
    );
}

export default App;
