import validators from '../validators';
import { MANDATORY_STATUS } from '../constants';
import {
    COMMON_DATA_KEYS,
    ACTIVITY_DATA_KEYS as DATA_KEYS,
    ASSET_DATA_KEYS as ASSET_KEYS,
    LOCATION_DATA_KEYS as LOCATION_KEYS
} from './dataKeys';

/**
 * Defines the schema structure and validator functions for jobs.
 *
 * Nested definitions are flattened for ease of lookup @see schemas/getFlatSchemaFromSchemaDefinition
 */
export const SCHEMA = {
    [COMMON_DATA_KEYS.UUID]: validators.optionalUuid,
    [DATA_KEYS.DESCRIPTION]: validators.string4096,
    [DATA_KEYS.SCHEDULED_ON]: validators.optionalIsoDate,
    [DATA_KEYS.ESTIMATED_DURATION]: validators.positiveNumber,
    [DATA_KEYS.LOCATION]: {
        uuid: validators.mandatoryUuidOnCreation
    },
    [DATA_KEYS.CONTACT]: {
        uuid: validators.optionalUuid
    },
    [DATA_KEYS.ASSET]: {
        uuid: validators.optionalUuid
    },
    [DATA_KEYS.JOB_LEAD]: {
        uuid: validators.optionalUuid
    },
    [DATA_KEYS.IS_FIELD_QUOTE]: validators.isFieldQuote,
    [DATA_KEYS.TIME_WINDOW]: {
        [DATA_KEYS.START_AFTER]: validators.isoDateStartAfter,
        [DATA_KEYS.DEADLINE]: validators.optionalIsoDate
    },
    [DATA_KEYS.JOB_TYPE]: {
        uuid: validators.optionalUuid
    }
};

export const DEFAULT_HEADER_MAPPING = {
    [COMMON_DATA_KEYS.UUID]: 'UUID',
    [DATA_KEYS.DESCRIPTION]: 'Description',
    [DATA_KEYS.SCHEDULED_ON]: 'Scheduled On',
    [DATA_KEYS.ESTIMATED_DURATION]: 'Estimated Duration',
    [`${DATA_KEYS.LOCATION}.uuid`]: 'Location UUID',
    [`${DATA_KEYS.CONTACT}.uuid`]: 'Contact UUID',
    [`${DATA_KEYS.ASSET}.uuid`]: 'Asset UUID',
    [`${DATA_KEYS.JOB_LEAD}.uuid`]: 'Job Lead UUID',
    [`${DATA_KEYS.TIME_WINDOW}.${DATA_KEYS.START_AFTER}`]: 'Start After',
    [`${DATA_KEYS.TIME_WINDOW}.${DATA_KEYS.DEADLINE}`]: 'Deadline',
    [`${DATA_KEYS.JOB_TYPE}.uuid`]: 'Job Type UUID',
    [DATA_KEYS.IS_FIELD_QUOTE]: 'Is Field Quote?'
};

export const MINIMUM_ATTRS_TO_MAP = {
    // SCHEDULED_ON is required on field quotes, but not on web quotes
    [MANDATORY_STATUS.ON_CREATION]: [`${DATA_KEYS.LOCATION}.${COMMON_DATA_KEYS.UUID}`, DATA_KEYS.IS_FIELD_QUOTE],
    [MANDATORY_STATUS.ON_UPDATE]: [COMMON_DATA_KEYS.UUID, DATA_KEYS.IS_FIELD_QUOTE],
    [MANDATORY_STATUS.ALTERNATIVE_ID]: [
        `${DATA_KEYS.LOCATION}.${LOCATION_KEYS.NAME}`,
        `${DATA_KEYS.ASSET}.${ASSET_KEYS.NAME}`
    ]
};
