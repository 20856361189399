import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { FAButton } from 'FA_STORYBOOK';

import { entities as ENTITIES, importsExcludedEntities } from '../../constants';
import { MODIFIERS } from '../../utils/constants';
import { entityLabels } from '../../schemas';

import 'antd/lib/divider/style/index.css';
import './EntitySelection.sass';

const CLASSES = {
    BASE: 'EntitySelection'
};

const validEntities = Object.values(ENTITIES);
const entityItems = validEntities
    .filter(entity => !importsExcludedEntities.includes(entity))
    .map(key => ({
        key,
        label: entityLabels[key]
    }));

function EntitySelection({ entity, importHistory, resetState, isRebrand }) {
    function resetImportState() {
        if (entity) resetState();
    }

    // reset the state when the component mounts so that the browser back button and other navigation works as expected
    useEffect(resetImportState, []);

    return (
        <div className={CLASSES.BASE}>
            {entityItems.map(({ key, label }) => (
                <Link key={key} to={`/import/${key}/upload`} tabIndex={-1}>
                    <FAButton isRebrand={isRebrand} block size="large" tabIndex={1}>
                        {label}
                    </FAButton>
                </Link>
            ))}
            {Boolean(importHistory.length) && (
                <section style={{ textAlign: 'right' }}>
                    <Divider />
                    {/**
                     * This markup is mimicking the same structure as
                     * @see withThemedStyle in fieldaware/frontend-storybook
                     */}
                    <span className={classNames({ [MODIFIERS.IS_REBRAND]: isRebrand })}>
                        <Link to="/import_history" className="fa-anchor">
                            Recent Imports ({importHistory.length})
                        </Link>
                    </span>
                </section>
            )}
        </div>
    );
}

EntitySelection.propTypes = {
    entity: PropTypes.string,
    importHistory: PropTypes.arrayOf(PropTypes.object),
    resetState: PropTypes.func.isRequired
};

export default EntitySelection;
