import React from 'react';
import PropTypes from 'prop-types';

import CsvMetric from '../CsvMetric';

function CsvFileSummaryMessage({ csvColumnHeaders, csvLines, fileName }) {
    return (
        <section>
            The uploaded CSV file <i>{fileName}</i> contains <CsvMetric count={csvLines.length} name="row" /> of data in{' '}
            <CsvMetric count={csvColumnHeaders.length} name="column" />.
        </section>
    );
}

CsvFileSummaryMessage.propTypes = {
    csvColumnHeaders: PropTypes.arrayOf(PropTypes.string),
    csvLines: PropTypes.arrayOf(PropTypes.array),
    fileName: PropTypes.string
};

export default CsvFileSummaryMessage;
