import React from 'react';
import { Alert } from 'antd';
import * as localforage from 'localforage';

import * as localStorageInterface from '../utils/localStorageInterface';
import { showConfirmModal } from '../utils/modalInterface';
import { IMPORT_STATUS } from '../constants';

import 'antd/lib/alert/style/index.css';

export function showModal(interruptCount) {
    const { email } = this.state;
    showConfirmModal({
        width: 600,
        closable: false,
        title: 'Resume Interrupted Import Run?',
        content: (
            <main>
                <p>The previous import run was interrupted with {interruptCount} records remaining.</p>
                <p>Would you like to continue importing these records?</p>
                <Alert
                    showIcon
                    type="warning"
                    description={
                        <p>
                            If you select <strong>No</strong>, you will lose the cached import data and will not be able
                            to continue the previous import run.
                        </p>
                    }
                />
            </main>
        ),
        okText: 'Yes',
        onOk: () => {
            getCachedImportStateFromIndexedDB(email).then(cachedImport => {
                if (cachedImport) {
                    // the cached state is the entire app state from when the previous interruped import file was parsed
                    const { entity, ...cachedState } = JSON.parse(cachedImport);
                    const cachedMetrics = JSON.parse(getCachedImportMetricsFromLocalStorage(email));
                    this.setState(state => ({
                        ...state,
                        entity,
                        ...cachedState,
                        importMetrics: cachedMetrics,
                        importStatus: IMPORT_STATUS.INTERRUPTED,
                        // preserve the current interrupt count
                        interruptCount: this.state.interruptCount,
                        iterationCounter: cachedMetrics.totalRequests
                    }));
                    // Navigate to the import view
                    window.location.hash = `#/import/${entity}/runner?resume=true`;
                }
            });
        },
        cancelText: 'No',
        onCancel: () => {
            // Clear cache
            clearCachedImportStateAndMetrics(email);
            this.setState(state => ({
                ...state,
                interruptCount: 0
            }));
        }
    });
}

/**
 * Asynchronously cache the entire app state in IndexedDB once before the import begins its run, using @module localforage
 *
 * In addition to this cache, we store the most recent importMetrics synchronously in localStorage after each iteration of the import.
 *
 * We use the cached metrics state and the pre-run state to recover where we left off after an interrupt.
 *
 * @see https://localforage.github.io/localForage/
 *
 * @prop {String} email - the logged in user email to key the item under in IndexedDB
 * @prop {Object} state - equivalent to this.state in @see App.jsx
 *
 * @return {Promise} - the promise returned by the call to the IndexedDB
 */
export function cacheImportStateWithIndexedDB(email, state) {
    const cachableData = JSON.stringify({
        ...state,
        importTimer: {
            ...state.importTimer,
            // set the start time so it is cached (as it will be 0 in the state arg)
            start: Date.now()
        }
    });
    return localforage.setItem(`${email}_cached_import`, cachableData);
}

export function getCachedImportStateFromIndexedDB(email) {
    return localforage.getItem(`${email}_cached_import`);
}

export function clearCachedImportStateAndMetrics(email) {
    localStorageInterface.remove(`${email}_cached_metrics`);
    localforage.removeItem(`${email}_cached_import`);
}

/**
 * Store the metrics in localStorage on every iteration @see cacheImportStateWithIndexedDB
 * @param {String} email    - the logged in user email to key the item under in localStorage
 * @param {Object} metrics  - equivalent to this.state.importMetrics in @see App.jsx
 */
export function cacheImportMetricsInLocalStorage(email, metrics) {
    localStorageInterface.write(`${email}_cached_metrics`, JSON.stringify(metrics));
}

export function getRemainingRequestsFromCachedImport(email) {
    const cachedMetrics = JSON.parse(getCachedImportMetricsFromLocalStorage(email));

    /**
     * Fix race-condition causing false positive interrupt on New Session
     *
     * When the user stops the import, the cached metrics can update while the Promise Pool is settling, so
     * check what the last cached status was and don't count as an interrupt if it was STOPPED
     */
    if (cachedMetrics && cachedMetrics.importStatus === IMPORT_STATUS.STOPPED) {
        clearCachedImportStateAndMetrics(email);
        return 0;
    }
    return cachedMetrics ? cachedMetrics.targetRequests - cachedMetrics.totalRequests : 0;
}

export function getCachedImportMetricsFromLocalStorage(email) {
    return localStorageInterface.read(`${email}_cached_metrics`);
}
