import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { Redirect } from 'react-router-dom';

import { FAButton } from 'FA_STORYBOOK';
import CsvFileSummaryMessage from '../CsvFileSummaryMessage';
import CsvMetric from '../CsvMetric';
import { ALERT_STATUS } from '../../constants';

import 'antd/lib/alert/style/index.css';
import './CsvFileSummary.sass';

const CLASSES = {
    BASE: 'fa-csv-file-summary'
};

function CsvFileSummary({
    // Data
    entity,
    entityLabel,
    fileName,
    csvColumnHeaders,
    keyMap,
    csvLines,
    validationMetrics,
    showValidation,
    // Functions
    resetMappedColumns,
    setCsvModalIsVisible
}) {
    const { isParsed, totalErrors, errorLines, validLines } = validationMetrics;
    const numberOfCsvColumns = csvColumnHeaders.length;
    const numberOfMappedColumns = Object.values(keyMap || {}).filter(value => value).length;
    const hasErrorLines = Boolean(totalErrors);
    const hasValidLines = Boolean(validLines);

    const validationStatus = hasErrorLines
        ? hasValidLines
            ? ALERT_STATUS.WARNING
            : ALERT_STATUS.ERROR
        : ALERT_STATUS.SUCCESS;

    return (
        <div className={CLASSES.BASE}>
            {!isParsed && <Redirect push to={`/import/${entity}/configuration`} />}
            <CsvFileSummaryMessage fileName={fileName} csvColumnHeaders={csvColumnHeaders} csvLines={csvLines} />
            {showValidation && (
                <main>
                    <Alert
                        message="Mapped Column Status"
                        description={
                            <p>
                                You have mapped <CsvMetric count={numberOfMappedColumns} /> of{' '}
                                <CsvMetric count={numberOfCsvColumns} name="column" /> to a schema key.
                            </p>
                        }
                        type={
                            numberOfMappedColumns === numberOfCsvColumns ? ALERT_STATUS.SUCCESS : ALERT_STATUS.WARNING
                        }
                        showIcon
                    />
                    <Alert
                        showIcon
                        type={validationStatus}
                        message="Validation Status"
                        description={
                            hasErrorLines ? (
                                <p>
                                    Based on the defined schema for {entityLabel}, <i>{fileName}</i> has{' '}
                                    <CsvMetric name="error" count={totalErrors} /> on{' '}
                                    <CsvMetric name="line" count={errorLines} />.{' '}
                                    {hasValidLines ? (
                                        'These lines will be skipped by the import runner.'
                                    ) : (
                                        <span>
                                            There are no lines to import{' '}
                                            <FAButton
                                                ghost
                                                type="danger"
                                                onClick={() => {
                                                    resetMappedColumns();
                                                    setCsvModalIsVisible(false);
                                                }}
                                            >
                                                Go back to CSV Column Mapping
                                            </FAButton>
                                        </span>
                                    )}
                                </p>
                            ) : (
                                <p>
                                    <i>{fileName}</i> has no errors based on the defined schema for {entityLabel}.
                                </p>
                            )
                        }
                    />
                </main>
            )}
        </div>
    );
}

CsvFileSummary.propTypes = {
    entity: PropTypes.string,
    entityLabel: PropTypes.string,
    fileName: PropTypes.string,
    csvColumnHeaders: PropTypes.arrayOf(PropTypes.string),
    keyMap: PropTypes.object,
    csvLines: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    validationMetrics: PropTypes.object.isRequired,
    showValidation: PropTypes.bool.isRequired,
    // Functions
    resetMappedColumns: PropTypes.func,
    setCsvModalIsVisible: PropTypes.func
};

CsvFileSummary.defaultProps = {
    showValidation: false
};

export default CsvFileSummary;
