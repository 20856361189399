import validators from '../validators';
import { MANDATORY_STATUS } from '../constants';
import { CUSTOMER_DATA_KEYS as DATA_KEYS } from './dataKeys';

/**
 * Defines the schema structure and validator functions for the customer entity.
 *
 * Nested definitions are flattened for ease of lookup @see schemas/getFlatSchemaFromSchemaDefinition
 */
export const SCHEMA = {
    [DATA_KEYS.UUID]: validators.optionalUuid,
    [DATA_KEYS.NAME]: validators.mandatoryStringOnCreation128,
    [DATA_KEYS.PHONE_NUMBER]: validators.string128,
    [DATA_KEYS.EMAIL_ADDRESS]: validators.emailAddress,
    [DATA_KEYS.FAX]: validators.string128,
    [DATA_KEYS.WEBSITE]: validators.string1024,
    [DATA_KEYS.NOTES]: validators.string1024,
    [DATA_KEYS.ACCOUNT_REF]: validators.string64,
    [DATA_KEYS.DISCOUNT]: validators.adaptFloatAndCheck,
    [DATA_KEYS.GL_ACCOUNT]: validators.string1024,
    [DATA_KEYS.TAXABLE]: validators.yesNo,
    [DATA_KEYS.ON_HOLD]: validators.yesNo,
    // there are two combinations that we need to validate:
    // if use_lat_lng is true, only name,lat,lng are required
    // if use_lat_lng is false, street_name, city are required
    [DATA_KEYS.LOCATION_NAME]: validators.string128,
    // use the validator on use_lat_lng to do the check
    [DATA_KEYS.LOCATION_USE_LAT_LNG]: validators.useLatLngCustomer,
    [DATA_KEYS.LOCATION_STREET_NAME]: validators.mandatoryStringOnCreationIfNotUseLatLng128,
    [DATA_KEYS.LOCATION_CITY]: validators.mandatoryStringOnCreationIfNotUseLatLng128,
    [DATA_KEYS.LOCATION_STATE]: validators.string128,
    [DATA_KEYS.LOCATION_ZIP_CODE]: validators.string128,
    [DATA_KEYS.LOCATION_COUNTRY]: validators.string128,
    [DATA_KEYS.LOCATION_LATITUDE]: validators.latitude,
    [DATA_KEYS.LOCATION_LONGITUDE]: validators.longitude,
    [DATA_KEYS.LOCATION_TYPE]: validators.locationType,
    [DATA_KEYS.LOCATION_TAX]: {
        // Note: the values here will be replaced with validator functions when the getTaxesValidators is called
        name: true,
        group: true
    },
    [DATA_KEYS.LOCATION_IS_BILLING]: validators.yesNo
};

export const NON_SCHEMA_ATTRIBUTES = {
    [DATA_KEYS.CUSTOMER_ID]: validators.positiveNumber
};

export const DEFAULT_HEADER_MAPPING = {
    [DATA_KEYS.UUID]: 'UUID',
    [DATA_KEYS.CUSTOMER_ID]: 'Customer ID',
    [DATA_KEYS.NAME]: 'Name',
    [DATA_KEYS.PHONE_NUMBER]: 'Phone',
    [DATA_KEYS.EMAIL_ADDRESS]: 'Email',
    [DATA_KEYS.FAX]: 'Fax',
    [DATA_KEYS.WEBSITE]: 'Website',
    [DATA_KEYS.NOTES]: 'Notes',
    [DATA_KEYS.ACCOUNT_REF]: 'Account',
    [DATA_KEYS.DISCOUNT]: 'Discount',
    [DATA_KEYS.GL_ACCOUNT]: 'GL Account',
    [DATA_KEYS.TAXABLE]: 'Taxable',
    [DATA_KEYS.ON_HOLD]: 'On Hold',
    // Location related attributes
    [DATA_KEYS.LOCATION_NAME]: 'Location Name',
    // use the validator on use_lat_lng to do the check
    [DATA_KEYS.LOCATION_USE_LAT_LNG]: 'Use Lat/Lng',
    [DATA_KEYS.LOCATION_STREET_NAME]: 'Street Name',
    [DATA_KEYS.LOCATION_CITY]: 'City',
    [DATA_KEYS.LOCATION_STATE]: 'State',
    [DATA_KEYS.LOCATION_ZIP_CODE]: 'Zip Code',
    [DATA_KEYS.LOCATION_COUNTRY]: 'Country',
    [DATA_KEYS.LOCATION_LATITUDE]: 'Latitude',
    [DATA_KEYS.LOCATION_LONGITUDE]: 'Longitude',
    [DATA_KEYS.LOCATION_TYPE]: 'Type',
    [`${DATA_KEYS.LOCATION_TAX}.name`]: 'Tax Name',
    [`${DATA_KEYS.LOCATION_TAX}.group`]: 'Tax Group',
    [DATA_KEYS.LOCATION_IS_BILLING]: 'Is Billing'
};

export const MINIMUM_ATTRS_TO_MAP = {
    [MANDATORY_STATUS.ON_CREATION]: [DATA_KEYS.NAME, DATA_KEYS.LOCATION_STREET_NAME, DATA_KEYS.LOCATION_CITY],
    [MANDATORY_STATUS.ON_UPDATE]: [DATA_KEYS.UUID],
    [MANDATORY_STATUS.ALTERNATIVE_ID]: [DATA_KEYS.CUSTOMER_ID, DATA_KEYS.NAME, DATA_KEYS.GL_ACCOUNT]
};
