export const entities = {
    ASSETS: 'assets',
    CUSTOMERS: 'customers',
    CONTACTS: 'contacts',
    ITEMS: 'items',
    JOBS: 'jobs',
    JOB_HISTORY: 'historicalJobs',
    JOB_TYPES: 'jobTypes',
    LOCATIONS: 'locations',
    QUOTES: 'quotes',
    FIELD_QUOTES: 'fieldQuotes',
    WEB_QUOTES: 'webQuotes',
    TASKS: 'tasks',
    USERS: 'users'
};

export const activityEntities = [
    entities.JOBS,
    entities.QUOTES,
    entities.FIELD_QUOTES,
    entities.WEB_QUOTES,
    entities.JOB_HISTORY
];

export const quoteEntities = [entities.FIELD_QUOTES, entities.WEB_QUOTES];

export const importsExcludedEntities = [...quoteEntities, entities.JOB_TYPES];

export const exportsExcludedEntities = [...activityEntities];

export const IMPORT_STATUS = {
    // This is the value from init until the import run is started
    PREPARING: 'PREPARING',
    // These are the possible values when the import run is in progress
    RUNNING: 'RUNNING',
    // PAUSING = The user has paused the import but there are API requests in flight
    PAUSING: 'PAUSING',
    PAUSED: 'PAUSED',
    INTERRUPTED: 'INTERRUPTED',
    // These are the possible values when the import is finished.
    STOPPED: 'STOPPED',
    COMPLETE: 'COMPLETE',
    // RETRYING = after completion, the runner is retrying the requests that failed with a network error
    RETRYING: 'RETRYING'
};

export const ALERT_STATUS = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error'
};

export const MANDATORY_STATUS = {
    NEVER: 'NEVER',
    ALWAYS: 'ALWAYS',
    ON_CREATION: 'ON_CREATION',
    ON_UPDATE: 'ON_UPDATE',
    ALTERNATIVE_ID: 'ALTERNATIVE_ID'
};

export const MAX_IMPORT_SIZE = 10000;

// Maximum size of Import History and Export History
export const MAX_HISTORY_SIZE = 10;

export const VALID_LOCALES = ['en_US', 'en_GB', 'fr_FR', 'de_DE', 'es_ES'];

export const TIME_CONSTANTS = {
    MILLISECONDS: {
        DAY: 86400000,
        HOUR: 3600000,
        MINUTE: 60000,
        SECOND: 1000
    },
    SECONDS: {
        // number of seconds in...
        QUARTER_HOUR: 900,
        HOUR: 3600,
        MINUTE: 60,
        DAY: 86400
    },
    MINUTES: {
        HOUR: 60
    },
    HOURS: {
        DAY: 24
    },
    DAYS: {
        // number of days in...
        DAY: 1,
        WEEK: 7,
        YEAR: 365
    }
};
export const TIME_WINDOW_MINIMUM_DURATION_IN_MILLISECONDS = 15 * TIME_CONSTANTS.MILLISECONDS.MINUTE;

export const WEBAPP_DOMAINS = ['localhost:8080', '.fieldaware.net', 'app.fieldaware.com', 'app-au.fieldaware.com'];
