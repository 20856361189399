import validators from '../validators';
import { MANDATORY_STATUS } from '../constants';
import { ITEM_DATA_KEYS as DATA_KEYS } from './dataKeys';

/**
 * Defines the schema structure and validator functions for the task entity.
 *
 * Nested definitions are flattened for ease of lookup @see schemas/getFlatSchemaFromSchemaDefinition
 */
export const SCHEMA = {
    [DATA_KEYS.UUID]: validators.optionalUuid,
    [DATA_KEYS.NAME]: validators.mandatoryStringOnCreation128,
    [DATA_KEYS.DESCRIPTION]: validators.string256,
    [DATA_KEYS.PART_NUMBER]: validators.string256,
    [DATA_KEYS.GL_ACCOUNT]: validators.string1024,
    [DATA_KEYS.UNIT_COST]: validators.adaptFloatAndCheck,
    [DATA_KEYS.UNIT_PRICE]: validators.adaptFloatAndCheck,
    [DATA_KEYS.MARKUP_TYPE]: validators.markupEnum,
    [DATA_KEYS.MARKUP_VALUE]: validators.adaptFloatAndCheck,
    [DATA_KEYS.ARCHIVED]: validators.yesNo,
    [DATA_KEYS.TAXABLE]: validators.yesNo,
    [DATA_KEYS.TRACK_ON_VAN]: validators.yesNo,
    [DATA_KEYS.TAX_1]: {
        // Note: the values here will be replaced with validator functions when the getTaxesValidators is called
        name: true,
        group: true
    },
    [DATA_KEYS.TAX_2]: {
        // Note: the values here will be replaced with validator functions when the getTaxesValidators is called
        name: true,
        group: true
    }
};

export const DEFAULT_HEADER_MAPPING = {
    [DATA_KEYS.UUID]: 'UUID',
    [DATA_KEYS.NAME]: 'Name',
    [DATA_KEYS.DESCRIPTION]: 'Description',
    [DATA_KEYS.PART_NUMBER]: 'Part Number',
    [DATA_KEYS.UNIT_COST]: 'Unit Cost',
    [DATA_KEYS.UNIT_PRICE]: 'Unit Price',
    [DATA_KEYS.MARKUP_TYPE]: 'Markup Type',
    [DATA_KEYS.MARKUP_VALUE]: 'Markup Value',
    [DATA_KEYS.ARCHIVED]: 'Archived',
    [DATA_KEYS.GL_ACCOUNT]: 'GL Account',
    [DATA_KEYS.TRACK_ON_VAN]: 'Track on Van',
    [DATA_KEYS.TAXABLE]: 'Taxable',
    [`${DATA_KEYS.TAX_1}.name`]: 'Tax Name1',
    [`${DATA_KEYS.TAX_1}.group`]: 'Tax Group1',
    [`${DATA_KEYS.TAX_2}.name`]: 'Tax Name2',
    [`${DATA_KEYS.TAX_2}.group`]: 'Tax Group2'
};

export const MINIMUM_ATTRS_TO_MAP = {
    [MANDATORY_STATUS.ON_CREATION]: [DATA_KEYS.NAME],
    [MANDATORY_STATUS.ON_UPDATE]: [DATA_KEYS.UUID],
    [MANDATORY_STATUS.ALTERNATIVE_ID]: [DATA_KEYS.NAME]
};

export const REQUIRED_FIELDS_FOR_UUID_REQUEST = [DATA_KEYS.NAME];
export const OPTIONAL_FIELDS_FOR_UUID_REQUEST = [];
