import validators from '../validators';
import { MANDATORY_STATUS } from '../constants';
import { CONTACT_DATA_KEYS as DATA_KEYS, CUSTOMER_DATA_KEYS } from './dataKeys';

/**
 * Defines the schema structure and validator functions for the contact entity.
 *
 * Nested definitions are flattened for ease of lookup @see schemas/getFlatSchemaFromSchemaDefinition
 */
export const SCHEMA = {
    [DATA_KEYS.UUID]: validators.optionalUuid,
    [DATA_KEYS.CUSTOMER]: {
        [DATA_KEYS.UUID]: validators.mandatoryUuidOnCreation
    },
    [DATA_KEYS.NAME]: validators.mandatoryStringOnCreation128,
    [DATA_KEYS.SURNAME]: validators.string128,
    [DATA_KEYS.EMAIL_ADDRESS]: validators.emailAddress,
    [DATA_KEYS.PHONE_NUMBER]: validators.string128
};

export const DEFAULT_HEADER_MAPPING = {
    [`${DATA_KEYS.CUSTOMER}.${DATA_KEYS.UUID}`]: 'Customer UUID',
    [DATA_KEYS.UUID]: 'Contact UUID',
    [DATA_KEYS.NAME]: 'Contact Name',
    [DATA_KEYS.SURNAME]: 'Contact Last Name',
    [DATA_KEYS.PHONE_NUMBER]: 'Contact Phone',
    [DATA_KEYS.EMAIL_ADDRESS]: 'Contact Email'
};

export const NON_SCHEMA_ATTRIBUTES = {
    [`${DATA_KEYS.CUSTOMER}.${CUSTOMER_DATA_KEYS.CUSTOMER_ID}`]: validators.positiveNumber,
    [`${DATA_KEYS.CUSTOMER}.${CUSTOMER_DATA_KEYS.NAME}`]: validators.string128
};

export const MINIMUM_ATTRS_TO_MAP = {
    [MANDATORY_STATUS.ON_CREATION]: [`${DATA_KEYS.CUSTOMER}.${DATA_KEYS.UUID}`, DATA_KEYS.NAME],
    [MANDATORY_STATUS.ON_UPDATE]: [DATA_KEYS.UUID],
    [MANDATORY_STATUS.ALTERNATIVE_ID]: [
        `${DATA_KEYS.CUSTOMER}.${CUSTOMER_DATA_KEYS.CUSTOMER_ID}`,
        `${DATA_KEYS.CUSTOMER}.${CUSTOMER_DATA_KEYS.NAME}`,
        DATA_KEYS.NAME
    ]
};
