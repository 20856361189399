import { constantCase } from 'change-case';
import * as PromisePool from 'es6-promise-pool';
import { postNewItem, putItemUpdate, postItemUpdate } from '../api';
import { entities as ENTITIES, IMPORT_STATUS, MAX_HISTORY_SIZE } from '../constants';
import { COMMON_DATA_KEYS } from '../schemas/dataKeys';
import { newUuid, pluralise, isObject } from '../utils';
import { showConfirmModal } from '../utils/modalInterface';

import * as interruptHandlerState from './interruptHandler';

const PROMISE_POOL_CONCURRENCY = {
    MAIN_IMPORT_PHASE: 10,
    RETRY_PHASE: 5
};

function getNextImportHistory(state) {
    const { importHistory, fileName, entityLabel, validationMetrics, importMetrics, importStatus, importTimer } = state;
    const newImportHistoryItem = {
        uuid: newUuid(),
        fileName,
        entityLabel,
        validationMetrics,
        importMetrics,
        importStatus,
        importTimer
    };

    return [newImportHistoryItem, ...importHistory.slice(0, MAX_HISTORY_SIZE - 1)];
}

const METRICS_KEYS = {
    SUCCESS_REQUESTS: 'successRequests',
    ERROR_REQUESTS: 'errorRequests'
};
function getNextImportState(state, metricsKey = METRICS_KEYS.SUCCESS_REQUESTS, isRetry = false) {
    const totalRequests = isRetry ? state.importMetrics.totalRequests : state.importMetrics.totalRequests + 1;
    const importDone = totalRequests === state.importMetrics.targetRequests;

    const nextImportStatus = importDone && !isRetry ? IMPORT_STATUS.COMPLETE : state.importStatus;
    const nextImportTimer = getNextImportTimer(state, importDone);

    /**
     * If @param isRetry is passed as true, then a previously received error has succeeded in the retry phase
     * so we deduct 1 from the error count and add 1 to the success count
     */
    const nextImportMetrics = isRetry
        ? {
              ...state.importMetrics,
              [METRICS_KEYS.SUCCESS_REQUESTS]: state.importMetrics[METRICS_KEYS.SUCCESS_REQUESTS] + 1,
              [METRICS_KEYS.ERROR_REQUESTS]: state.importMetrics[METRICS_KEYS.ERROR_REQUESTS] - 1
          }
        : {
              ...state.importMetrics,
              totalRequests,
              [metricsKey]: state.importMetrics[metricsKey] + 1
          };

    const nextRetryMetrics = isRetry
        ? {
              ...state.retryMetrics,
              resolvedErrors: state.retryMetrics.resolvedErrors + 1
          }
        : state.retryMetrics;

    const nextImportState = {
        importDone,
        importMetrics: nextImportMetrics,
        importStatus: nextImportStatus,
        importTimer: nextImportTimer,
        retryMetrics: nextRetryMetrics
    };

    return importDone
        ? {
              ...nextImportState,
              // create an import history item if the import is done
              importHistory: getNextImportHistory({ ...state, ...nextImportState })
          }
        : nextImportState;
}

function getNextImportTimer(state, importDone) {
    return importDone ? { ...state.importTimer, end: Date.now() } : state.importTimer;
}

/**
 * @this App
 *
 * Store the app state prior to import and kick off the import interval,
 * sending 1 API request for each payload in the importData queue.
 *
 * This function sets the importStatus to RUNNING
 */
export async function initialiseImport(fromIndex = 0) {
    // check current import status to avoid double runs
    if ([IMPORT_STATUS.RUNNING, IMPORT_STATUS.RETRYING].includes(this.state.importStatus)) {
        return null;
    }

    // cache the complete app state
    await interruptHandlerState.cacheImportStateWithIndexedDB(this.state.email, this.state);

    startImport.call(this, fromIndex);
}

export function startImport(fromIndex) {
    let iterator = fromIndex;
    const promiseProducer = () => {
        if ([IMPORT_STATUS.STOPPED, IMPORT_STATUS.PAUSED, IMPORT_STATUS.PAUSING].includes(this.state.importStatus))
            return null;
        if (iterator >= this.state.importData.length) {
            return null;
        }
        const currentIteration = iterator;
        const itemToImport = this.state.importData[currentIteration];
        iterator++;

        if (itemToImport.requestPayload) {
            const { uuid, ...requestData } = itemToImport.requestPayload;
            return prepareRequest(currentIteration, this.state, uuid, requestData);
        } else {
            /**
             * The Promise Producer must return a promise or else the pool will stop running
             *
             * For invalid records that should not be sent to the API, return a promise that resolves to an empty response
             */
            return new Promise(resolve =>
                resolve({
                    uuid: '',
                    requestIndex: currentIteration,
                    response: false,
                    error: false
                })
            );
        }
    };

    const promisePool = new PromisePool(promiseProducer, PROMISE_POOL_CONCURRENCY.MAIN_IMPORT_PHASE);
    startPromisePool.call(this, promisePool, {
        onFulfilled: ({ data }) => {
            const { result } = data;
            // If neither response nor error is provided, do nothing with the Promise
            if (!result.response && !result.error) return;
            if (result.response) {
                onSuccess.call(this, result.requestIndex, result.uuid, result.response);
            } else if (result.error) {
                onError.call(this, result.requestIndex, result.error);
            }
            onMetricsUpdate(this.state);
        },
        onSettled: () => {
            /**
             * When importStatus is set to PAUSING, the promiseProducer will return null after the pool settles
             * which triggers then() on the promisePool (because the pool has no more promises)
             *
             * In other words, set to PAUSED after all promises resolve after PAUSING
             */
            if (this.state.importStatus === IMPORT_STATUS.PAUSING) {
                this.setState(state => ({
                    ...state,
                    importStatus: IMPORT_STATUS.PAUSED
                }));
            }
        }
    });
}

function startPromisePool(promisePool, { onFulfilled, onSettled }) {
    promisePool.addEventListener('fulfilled', onFulfilled);
    promisePool.start().then(onSettled);
    this.setState(state => {
        const currentImportStatus = state.importStatus;
        const isPaused = [IMPORT_STATUS.INTERRUPTED, IMPORT_STATUS.PAUSED].includes(currentImportStatus);
        const nextImportTimer = isPaused
            ? state.importTimer
            : {
                  ...state.importTimer,
                  start: Date.now()
              };

        return {
            ...state,
            importStatus: currentImportStatus === IMPORT_STATUS.RETRYING ? currentImportStatus : IMPORT_STATUS.RUNNING,
            importTimer: nextImportTimer,
            iterationCounter: isPaused ? state.iterationCounter : 0
        };
    });
}

function onMetricsUpdate(state) {
    interruptHandlerState.cacheImportMetricsInLocalStorage(state.email, {
        ...state.importMetrics,
        importStatus: state.importStatus
    });
}

/**
 * @this App
 *
 * Handle the end of the import run, updating the importHistory with details of the current run.
 *
 * An import can be STOPPED while in progress, or stop with a COMPLETE status
 * when the total number of resolved requests is equal to the total number of requests in the queue
 *
 * Once stopped, the import run cannot resume
 *
 * @see IMPORT_STATUS for more
 *
 * @param {String} status - the end status to set for the import run (STOPPED or COMPLETE)
 */
export function stopImport(status = IMPORT_STATUS.COMPLETE) {
    const importDone = true;
    this.setState(state => {
        const nextImportStatus = status;
        const nextImportTimer = getNextImportTimer(state, importDone);

        return {
            ...state,
            timerInterval: null,
            importStatus: nextImportStatus,
            importTimer: nextImportTimer,
            importHistory: getNextImportHistory({
                ...state,
                importStatus: nextImportStatus,
                importTimer: nextImportTimer
            }),
            importDone
        };
    });
}

/**
 * @this App
 *
 * Pause the import run, preventing further API requests until the run is resumed
 *
 */
export function pauseImport() {
    this.setState(state => ({
        ...state,
        importStatus: IMPORT_STATUS.PAUSING
    }));
}

/**
 * @this App
 *
 * Resume the import run
 *
 */
export function resumeImport() {
    this.setState(state => ({
        ...state,
        importStatus: IMPORT_STATUS.RUNNING
    }));
}

export function removeExtraData(requestData) {
    if (!isObject(requestData)) return requestData;
    return Object.keys(requestData).reduce((cleanRequestData, key) => {
        // we leave non object values in the requestData
        if (!isObject(requestData[key])) {
            cleanRequestData[key] = requestData[key];
            // for object values, we remove the extra data (anything other than uuid)
            // if uuid is not included, we drop the attribute altogether
        } else {
            const objectAttributeValue = requestData[key];
            if (Object.keys(objectAttributeValue).length === 1 && objectAttributeValue.uuid) {
                cleanRequestData[key] = objectAttributeValue;
                // if there are more attributes than uuid, keep uuid and drop the others
            } else if (Object.keys(objectAttributeValue).length > 1 && COMMON_DATA_KEYS.UUID in objectAttributeValue) {
                cleanRequestData[key] = { [COMMON_DATA_KEYS.UUID]: objectAttributeValue[COMMON_DATA_KEYS.UUID] };
            } else {
                cleanRequestData[key] = objectAttributeValue;
            }
        }

        return cleanRequestData;
    }, {});
}

function prepareRequest(currentIteration, { entity, url, token, isWebapp }, uuid, requestData) {
    const isUpdate = Boolean(uuid);
    const cleanRequestData = removeExtraData(requestData);
    const apiMethod = isUpdate ? ([ENTITIES.JOBS, ENTITIES.QUOTES].includes(entity) ? postItemUpdate : putItemUpdate) : postNewItem;

    const updateProps = isUpdate ? { uuid } : {};

    function withRequestIdentifiers(requestIndex, uuid) {
        return apiPromise => {
            return apiPromise
                .then(response => ({ requestIndex, uuid, response }))
                .catch(error => ({ requestIndex, uuid, error }));
        };
    }

    return withRequestIdentifiers(
        currentIteration,
        uuid
    )(
        apiMethod({
            url,
            token,
            entity,
            isWebapp,
            data: cleanRequestData,
            ...updateProps
        })
    );
}

/**
 * @this App
 *
 */
function onSuccess(currentIteration, uuid, response, isRetry = false) {
    const { importData } = this.state;
    const nextImportData = [...importData];
    const responseData = response.data || {};
    const outputUuid = Boolean(uuid) ? uuid : responseData && responseData.uuid;

    nextImportData[currentIteration] = {
        ...nextImportData[currentIteration],
        response: {
            method: constantCase(response.config.method),
            url: response.config.url,
            data: responseData,
            status: response.status,
            id: outputUuid,
            uuid: outputUuid
        }
    };
    if (isRetry) nextImportData[currentIteration].requestError = null;

    this.setState(state => {
        const nextImportMetrics = getNextImportState(state, METRICS_KEYS.SUCCESS_REQUESTS, isRetry);
        return {
            ...state,
            ...nextImportMetrics,
            importData: nextImportData
        };
    });
}

/**
 * @this App
 *
 */
function onError(currentIteration, error, isRetry = false) {
    const { importData } = this.state;
    const nextImportData = [...importData];
    const errorResponse = error.response || {};
    const isNetworkError = !Boolean(errorResponse.status);
    const errorMessage = errorResponse.data?.error?.message || error.toJSON().message;

    if (isNetworkError && !isRetry) {
        retrySingleNetworkError.call(this, currentIteration);
        return;
    }

    nextImportData[currentIteration] = {
        ...nextImportData[currentIteration],
        response: {
            isNetworkError,
            method: constantCase(error.config.method),
            url: error.config.url,
            data: JSON.parse(error.config.data),
            status: isNetworkError ? error.toJSON().name : errorResponse.status,
            errorMessage,
            id: null,
            uuid: newUuid()
        },
        requestError: { errorMessage }
    };
    this.setState(state => {
        const nextImportMetrics = getNextImportState(state, METRICS_KEYS.ERROR_REQUESTS);

        return {
            ...state,
            ...nextImportMetrics,
            importData: nextImportData,
            hasNetworkErrors: state.hasNetworkErrors || isNetworkError
        };
    });
}

/**
 * @this App
 *
 * Send request again if it failed because of the network during import run
 *
 * @param {Number} currentIteration - the position of the item in importData
 */
async function retrySingleNetworkError(currentIteration) {
    const itemToImport = this.state.importData[currentIteration];

    const { uuid, ...requestData } = itemToImport.requestPayload;

    const result = await prepareRequest(currentIteration, this.state, uuid, requestData);
    if (result.response) {
        onSuccess.call(this, result.requestIndex, result.uuid, result.response);
    } else if (result.error) {
        onError.call(this, result.requestIndex, result.error, true);
    }
}

function retryManyNetworkErrors(errorsToRetry) {
    let iterator = 0;
    const promiseProducer = () => {
        if (iterator >= errorsToRetry.length) {
            return null;
        }
        const itemToImport = errorsToRetry[iterator];
        iterator++;

        const { uuid, ...requestData } = itemToImport.requestPayload;
        return prepareRequest(itemToImport.index, this.state, uuid, requestData);
    };

    // Reduce the Promise Pool size during retry to decrease chance of dropped request
    const promisePool = new PromisePool(promiseProducer, PROMISE_POOL_CONCURRENCY.RETRY_PHASE);
    startPromisePool.call(this, promisePool, {
        onFulfilled: ({ data }) => {
            const { result } = data;
            // Do nothing if there is an error -> retry has failed
            if (result.error) return;

            if (result.response) {
                onSuccess.call(this, result.requestIndex, result.uuid, result.response, true);
                onMetricsUpdate(this.state);
            }
        },
        onSettled: () => {
            this.setState(state => ({
                ...state,
                importStatus: IMPORT_STATUS.COMPLETE
            }));
        }
    });
}

/**
 * @this App
 *
 * Called when the import run is COMPLETE or STOPPED
 */
export function onFinish(prevImportStatus, importStatus) {
    if (
        this.state.hasNetworkErrors &&
        importStatus === IMPORT_STATUS.COMPLETE &&
        prevImportStatus !== IMPORT_STATUS.RETRYING
    ) {
        const errorsToRetry = this.state.importData.filter(item => item.response && item.response.isNetworkError);
        const numberOfErrorsToRetry = errorsToRetry.length;

        showConfirmModal({
            width: 500,
            closable: true,
            title: 'Retry Network Errors?',
            content: `${numberOfErrorsToRetry} ${pluralise(
                'API request',
                numberOfErrorsToRetry
            )} failed because of a network error. Would you like to send these requests again?`,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                this.setState(state => ({
                    ...state,
                    hasNetworkErrors: false,
                    importStatus: IMPORT_STATUS.RETRYING,
                    retryMetrics: {
                        ...state.retryMetrics,
                        errorsToRetry: numberOfErrorsToRetry
                    }
                }));
                retryManyNetworkErrors.call(this, errorsToRetry);
            },
            onCancel: () => {
                setTimeout(() => {
                    this.setShowImportResult(true);
                }, 1000);
            }
        });
    } else {
        this.setShowImportResult(true);
    }
}
