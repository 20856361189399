import * as localStorageInterface from '../utils/localStorageInterface';

export function set(email, importHistory) {
    localStorageInterface.write(`${email}_import_history`, importHistory);
}

export function get(email) {
    return localStorageInterface.read(`${email}_import_history`) || [];
}

export function clear(email) {
    set(email, []);
}
